import chunk from "lodash-es/chunk";
import { DateTime } from "luxon";
import { z } from "zod";

const quarterYearSchema = z.string().regex(/Q\d{1} \d{2}/);
const quarterSchema = quarterYearSchema
  .transform((v) => v.split(" ").at(0))
  .pipe(z.string());
const yearSchema = quarterYearSchema
  .transform((v) => v.split(" ").at(1))
  .pipe(z.string())
  .transform((v) => parseInt(v, 10))
  .pipe(z.number());
const quarterTransform = (v: string) => parseInt(v.replace("Q", ""), 10);

const getHarvestYearFromQuarter = (quarterWithYear: string) => {
  const thisYear = getThisYear();
  const quarter = quarterSchema.parse(quarterWithYear);
  let year = yearSchema.parse(quarterWithYear);

  if (quarter === "Q1" || quarter === "Q2") {
    year -= 1;
  }

  return parseInt(thisYear.toString().slice(0, 2) + year.toString(), 10);
};

const getThisYear = () => parseInt(DateTime.now().toFormat("yyyy"), 10);

const getHarvestYears = () =>
  Array.from({ length: 3 }).map((_, i) => getThisYear() + i);

const isNextQuarter = (current: string, next: string) => {
  const currentQuarter = quarterSchema
    .transform(quarterTransform)
    .parse(current);
  const currentYear = yearSchema.parse(current);
  const nextQuarter = quarterSchema.transform(quarterTransform).parse(next);
  const nextYear = yearSchema.parse(next);
  const sameYear = currentYear === nextYear;
  const quarterDeltaOne = nextQuarter - currentQuarter === 1;
  const yearDeltaOne = nextYear - currentYear === 1;
  const lastAndFirstQuarter = currentQuarter === 4 && nextQuarter === 1;

  return (sameYear && quarterDeltaOne) || (lastAndFirstQuarter && yearDeltaOne);
};

const getNextQuarter = (current: string) => {
  const currentQuarter = quarterSchema
    .transform(quarterTransform)
    .parse(current);
  const currentYear = yearSchema.parse(current);

  let nextQuarter;
  let nextYear = currentYear;

  if (currentQuarter === 4) {
    nextQuarter = 1;
    nextYear = currentYear + 1;
  } else {
    nextQuarter = currentQuarter + 1;
  }

  return `Q${nextQuarter} ${nextYear.toString()}`;
};

const getSlashedQuarterName = (quarterName: string) => {
  const validQuarterName = z
    .string()
    .regex(/Q[1,2,3,4] \d{2}/)
    .parse(quarterName);

  return validQuarterName.replace(" ", "/");
};

type QuarterPrice = {
  id: string;
  price: number;
  quarter: {
    name: string;
    id: string;
  };
};

type QuarterPricePlaceholder = Pick<QuarterPrice, "quarter"> & {
  type: "placeholder";
};

type QuarterPriceOutput = QuarterPrice & { type: "data" };

const getPricesView = (
  quarters: readonly QuarterPrice[],
): Array<Array<QuarterPriceOutput | null | QuarterPricePlaceholder>> => {
  const result: Array<QuarterPriceOutput | null | QuarterPricePlaceholder> = [];
  if (quarters.length === 0) {
    return [result];
  }

  const first = quarters.at(0)!;

  if (first.quarter.name.startsWith("Q1")) {
    result.push(...[null, null]);
  } else if (first.quarter.name.startsWith("Q2")) {
    result.push(...[null, null, null]);
  } else if (first.quarter.name.startsWith("Q3")) {
    // we don't need no placeholders for Q3
  } else {
    result.push(null);
  }

  result.push({ ...first, type: "data" });
  const withoutFirst = quarters.slice(1);

  // fill the potential gaps
  while (withoutFirst.length) {
    const current = () => result.at(-1);
    const next = withoutFirst.shift();

    if (!(current() && next)) {
      break;
    }

    while (!isNextQuarter(current()!.quarter.name, next.quarter.name)) {
      const generatedQuarterName = getNextQuarter(current()!.quarter.name);
      result.push({
        type: "placeholder",
        quarter: {
          name: generatedQuarterName,
          id: generatedQuarterName,
        },
      });
    }
    result.push({ ...next, type: "data" });
  }

  return chunk(result, 4);
};

export {
  getHarvestYearFromQuarter,
  getHarvestYears,
  getNextQuarter,
  getPricesView,
  getSlashedQuarterName,
  getThisYear,
  isNextQuarter,
};
