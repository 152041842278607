import Cross from "@icons/cross.svg?react";
import { useSuspenseQuery } from "@tanstack/react-query";
import React from "react";
import { Link } from "react-router-dom";

import { Metadata } from "@/common/components/metadata/metadata";
import { countryCodeToFlag } from "@/common/utils/unicode";

import { companyQuery } from "./company.api";
import { Detail } from "./components/detail";
import { SubscriptionForm } from "./components/subscription-form";
import { Subscriptions } from "./components/subscriptions";
import { Users } from "./components/users";
import { useCompanyId } from "./use-company-id";

const path = "company/:id";
const Company = ({ children }: React.PropsWithChildren) => {
  const id = useCompanyId();
  const company = useSuspenseQuery(companyQuery({ id: parseInt(id) }));

  return (
    <>
      <Metadata title={company.data.name} />
      <Link className="sm:hidden" to="..">
        <Cross />
      </Link>
      <h1 className="text-xl font-bold">
        {company.data.name} {countryCodeToFlag(company.data.country)}
      </h1>
      <hr />
      {children}
    </>
  );
};

export { Company, Detail, path, SubscriptionForm, Subscriptions, Users };
