import { queryOptions } from "@tanstack/react-query";
import { z } from "zod";

import { graphqlClient } from "@/api/graphql";
import { sortString } from "@/common/utils/array";
import { graphql } from "@/generated/digitalnisklady.cz";
import { TradeType } from "@/generated/digitalnisklady.cz/graphql";

// eslint-disable-next-line lingui/no-unlocalized-strings
const cropsQueryDocument = graphql(`
  query Crops($tradeType: TradeType) {
    crops(tradeType: $tradeType) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`);

const cropsSchema = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
  }),
);

const cropsQuery = () =>
  queryOptions({
    queryKey: ["crops"],
    queryFn: () =>
      graphqlClient.request(cropsQueryDocument, {
        tradeType: TradeType.FromStorage,
      }),
    staleTime: Infinity,
    select: (data) => {
      const result = data.crops?.edges?.map((edge) => edge?.node);
      const safeResult = cropsSchema.parse(result);

      safeResult.sort(sortString);

      return safeResult;
    },
  });

export { cropsQuery };
