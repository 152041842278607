import { queryOptions, useMutation } from "@tanstack/react-query";
import partition from "lodash-es/partition";

import { sortString } from "@/common/utils/array";
import {
  CountryTo,
  getCountries,
  update,
  UserUpdateRequest,
  validate,
} from "@/generated/api/users";

const countriesQuery = () =>
  queryOptions({
    queryKey: ["countries"],
    staleTime: Infinity,
    queryFn: ({ signal }) => getCountries({ signal }),
  });

const phoneCountryCodesQuery = ({ language }: { language: string }) =>
  queryOptions({
    ...countriesQuery(),
    select: (countries) => {
      const split = splitByLanguageMatch(countries, language);
      return sortCountriesBy((a) => a.phoneCountryCode, ...split);
    },
  });

const companyCountriesQuery = ({ language }: { language: string }) =>
  queryOptions({
    ...countriesQuery(),
    select: (countries) => {
      const available = countries.filter(
        (country) => country.hasCompanyValidator,
      );

      const split = splitByLanguageMatch(available, language);
      return sortCountriesBy((a) => a.localName, ...split);
    },
  });

const useCompanyNumberValidationMutation = () =>
  useMutation({
    mutationKey: ["company-number-validation"],
    mutationFn: ({
      companyNumber,
      countryCode,
    }: {
      countryCode: string;
      companyNumber: string;
    }) =>
      validate({
        companyNumber: companyNumber.replaceAll(" ", ""),
        countryCode,
      }),
  });

const useCompleteUserRegistrationMutation = () =>
  useMutation({
    mutationKey: ["user-registration"],
    mutationFn: (payload: UserUpdateRequest) =>
      update({
        phone: payload.phone,
        company: payload.company,
        name: payload.name,
        language: payload.language,
      }),
  });

const splitByLanguageMatch = (countries: CountryTo[], language: string) =>
  partition(countries, (country) => country.language == language);

const sortCountriesBy = (
  callback: (country: CountryTo) => string,
  ...countries: CountryTo[][]
) => {
  let result: CountryTo[] = [];

  countries.forEach((c) => {
    result = result.concat(...c.toSorted((a, b) => sortString(a, b, callback)));
  });

  return result;
};

export {
  companyCountriesQuery,
  phoneCountryCodesQuery,
  useCompanyNumberValidationMutation,
  useCompleteUserRegistrationMutation,
};
