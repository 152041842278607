import "./index.css";
import "@/common/services/logger";

import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";

import { CommitHash } from "@/common/components/commit-hash/commit-hash";
import { AuthProvider } from "@/common/providers/auth-provider";

import { Maintenance } from "./common/components/maintenance/maintenance";
import { VersionCheck } from "./common/components/version-check/version-check";
import { QueryProvider } from "./common/providers/query-provider";
import { getEnvironmentVariable } from "./common/services/env";
import { App, Setup } from "./routes/app";

/**
 * TODO this doesn't belong here, but loaded from a separate file, it leads to a white screen on mobile safari
 * when accessing localhost via public IP address.
 */
const GoogleAnalyticsProvider = () => {
  const googleAnalyticsId = getEnvironmentVariable("GOOGLE_ANALYTICS_ID");
  const appMode = getEnvironmentVariable("APP_MODE");

  if (appMode === "local") {
    return;
  }

  if (googleAnalyticsId) {
    ReactGA.initialize(googleAnalyticsId);
  }

  return null;
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Maintenance>
      <AuthProvider>
        <QueryProvider>
          <VersionCheck />
          <Setup>
            <App />
          </Setup>
          <GoogleAnalyticsProvider />
          <CommitHash />
        </QueryProvider>
      </AuthProvider>
    </Maintenance>
  </React.StrictMode>,
);
