import Cross from "@icons/cross.svg?react";
import { t } from "@lingui/core/macro";
import React from "react";

import { ClientError } from "@/api/errors";
import { Failed } from "@/common/components/info/info";
import { NotificationList } from "@/common/components/push-notifications/notification-list";
import { Spinner } from "@/common/components/spinner/spinner";
import { PaymentDetail } from "@/common/components/subscriptions/payment.detail";
import { PaymentBankTransfer } from "@/common/components/subscriptions/payment-bank-transfer";
import { Subscriptions } from "@/common/components/subscriptions/subscriptions";
import { UserSettings } from "@/common/components/user-settings/user-settings";
import { FullscreenModalLayout } from "@/routes/layout/layout.fullscreen";

import { useSidebar } from "./sidebar-provider";

const LazyPaymentCard = React.lazy(() =>
  import("@/common/components/subscriptions/payment-card").then((module) => ({
    default: module.PaymentCard,
  })),
);

const LazyPaymentCardSubmitButton = React.lazy(() =>
  import("@/common/components/subscriptions/payment-card").then((module) => ({
    default: module.SubmitButton,
  })),
);

const SidebarScreen = () => {
  const { state: sidebar, actions } = useSidebar();

  if (!sidebar.screen) {
    return null;
  }

  if (sidebar.screen === "notifications") {
    return <NotificationList />;
  }

  if (sidebar.screen.startsWith("subscriptions")) {
    return <Subscriptions />;
  }

  if (sidebar.screen === "payment-bank-transfer") {
    return <PaymentBankTransfer />;
  }

  if (sidebar.screen === "payment-detail") {
    return <PaymentDetail />;
  }

  if (sidebar.screen === "payment-card") {
    if (sidebar.clientSecret) {
      return (
        <LazyPaymentCard clientSecret={sidebar.clientSecret}>
          <LazyPaymentCardSubmitButton price={sidebar.priceWithVAT} />
        </LazyPaymentCard>
      );
    }

    if (sidebar.error) {
      return (
        <FullscreenModalLayout
          headerButton={
            <button onClick={actions.toggle}>
              <Cross />
            </button>
          }
        >
          <Failed
            error={
              sidebar.error instanceof ClientError &&
              sidebar.error.getStatus() === 409
                ? new ClientError(t`Společnost má již nastavené předplatné.`)
                : sidebar.error
            }
          />
        </FullscreenModalLayout>
      );
    }
    return <Spinner />;
  }

  if (sidebar.screen.startsWith("user")) {
    return <UserSettings />;
  }
};

export { SidebarScreen };
