import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import intersection from "lodash-es/intersection";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { logger } from "@/common/services/logger";
import { Permission, permissions } from "@/routes/admin/permissions";

const AdminGuard = ({ children }: React.PropsWithChildren) => {
  const isAdmin = useHasAnyAdminPermission();
  const nonAdminJsx = children ? null : <Navigate to="/" />;
  const adminJsx = children ?? <Outlet />;

  if (isAdmin === false) {
    return nonAdminJsx;
  } else if (isAdmin === true) {
    return adminJsx;
  } else {
    return null;
  }
};

type Props = React.PropsWithChildren<{
  onDenied?: React.ReactNode;
}>;
type PermissionProps = Props & {
  permission: Permission;
  permissionCheckFn?: never;
};
type PermissionCheckFn = (permissions: Permission[]) => boolean;
type PermissionCheckFnProps = Props & {
  permission?: never;
  permissionCheckFn: PermissionCheckFn;
};

const PermissionGuard = ({
  children,
  onDenied,
  permission,
  permissionCheckFn,
}: PermissionCheckFnProps | PermissionProps) => {
  const hasPermission = useHasPermission(permission ?? permissionCheckFn);

  if (hasPermission) {
    return children;
  } else if (hasPermission === false) {
    return onDenied;
  }

  return null;
};

const useHasAnyAdminPermission = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [isAdmin, setIsAdmin] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    getAccessTokenSilently()
      .then((token) => {
        const decoded = jwtDecode(token);

        setIsAdmin(intersection(decoded.permissions, permissions).length > 0);
      })
      .catch(logger.error);
  }, [getAccessTokenSilently]);

  return isAdmin;
};

const useHasPermission = (permission: Permission | PermissionCheckFn) => {
  const { getAccessTokenSilently } = useAuth0();
  const [isAble, setIsAble] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    getAccessTokenSilently()
      .then((token) => {
        const decoded = jwtDecode(token);

        if (typeof permission === "function") {
          setIsAble(permission(decoded.permissions));
        } else {
          setIsAble(decoded.permissions.includes(permission));
        }
      })
      .catch(logger.error);
  }, [getAccessTokenSilently, permission]);

  return isAble;
};

const countryPermissionCheckFn: PermissionCheckFn = (permissions) =>
  permissions.some((permission) => permission.startsWith("country:"));

export {
  AdminGuard,
  countryPermissionCheckFn,
  PermissionGuard,
  useHasPermission,
};
