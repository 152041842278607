import { Trans } from "@lingui/react/macro";
import { Editor } from "@tiptap/react";

const MenuBar = ({ editor }: { editor: Editor }) => {
  const className = (active?: boolean) =>
    // eslint-disable-next-line lingui/no-unlocalized-strings
    `bg-white border border-can-silver-cloud px-2 rounded ${
      active
        ? // eslint-disable-next-line lingui/no-unlocalized-strings
          "bg-linear-to-r from-can-tranquil-azure to-can-mystic-aqua border-white"
        : ""
    }`;

  if (!editor) {
    return null;
  }

  return (
    <div className="border-y-can-sky-blue flex flex-wrap justify-between gap-2 border-y px-2 py-2">
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={className(editor.isActive("bold"))}
      >
        {/* eslint-disable-next-line lingui/no-unlocalized-strings */}
        <b>B</b>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={className(editor.isActive("italic"))}
      >
        {/* eslint-disable-next-line lingui/no-unlocalized-strings */}
        <i>I</i>
      </button>
      <button
        type="button"
        className={className()}
        onClick={() => editor.chain().focus().unsetAllMarks().run()}
      >
        <Trans>Zrušit formátování</Trans>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={className(editor.isActive("heading", { level: 1 }))}
      >
        <Trans>Nadpis 1</Trans>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={className(editor.isActive("heading", { level: 2 }))}
      >
        <Trans>Nadpis 2</Trans>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={className(editor.isActive("heading", { level: 3 }))}
      >
        <Trans>Nadpis 3</Trans>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={className(editor.isActive("heading", { level: 4 }))}
      >
        <Trans>Nadpis 4</Trans>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
        className={className(editor.isActive("heading", { level: 5 }))}
      >
        <Trans>Nadpis 5</Trans>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
        className={className(editor.isActive("heading", { level: 6 }))}
      >
        <Trans>Nadpis 6</Trans>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={className(editor.isActive("bulletList"))}
      >
        <Trans>Seznam</Trans>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={className(editor.isActive("orderedList"))}
      >
        <Trans>Číslovaný seznam</Trans>
      </button>
    </div>
  );
};

export { MenuBar };
