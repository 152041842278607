import { t } from "@lingui/core/macro";

import { PlanPeriodTo } from "@/generated/api/users";

const getLocalizedPeriods = (): Record<PlanPeriodTo["type"], string> => ({
  DAILY: t`Denní`,
  QUARTERLY: t`Čvrtletní`,
  MONTHLY: t`Měsíční`,
  YEARLY: t`Roční`,
});

export { getLocalizedPeriods };
