import { useMutation } from "@tanstack/react-query";

import { graphqlClient } from "@/api/graphql";
import { graphql } from "@/generated/digitalnisklady.cz";
import { Currency } from "@/generated/digitalnisklady.cz/graphql";

// eslint-disable-next-line lingui/no-unlocalized-strings
const addFastContractDocument = graphql(`
  mutation AddFastContract($fastContract: FastContractInput!) {
    addFastContract(fastContract: $fastContract) {
      id
    }
  }
`);

type ContractPayload = {
  amount: number;
  harvestYear: number;
  cropId: string;
  marketPriceId: string;
  gps: {
    latitude: number;
    longitude: number;
  };
  companyId: string;
  currency: Currency;
  note?: string;
  zip: string;
};

type OrderPayload = Omit<ContractPayload, "marketPriceId"> & {
  lowPrice: number | null;
  price: number;
  quarterId: string;
};

const useAddFastContractMutation = () =>
  useMutation({
    mutationFn: (payload: ContractPayload) =>
      graphqlClient.request(addFastContractDocument, {
        fastContract: {
          note: payload.note,
          currency: payload.currency,
          company: payload.companyId,
          marketPrice: payload.marketPriceId,
          gps: payload.gps,
          amount: payload.amount,
          crop: payload.cropId,
          yearHarvested: payload.harvestYear,
          zip: payload.zip,
        },
      }),
  });

// eslint-disable-next-line lingui/no-unlocalized-strings
const addFastOrderDocument = graphql(`
  mutation AddFastOrder($fastOrder: FastOrderInput!) {
    addFastOrder(fastOrder: $fastOrder) {
      id
      offer {
        id
      }
    }
  }
`);

const useAddFastOrderMutation = () =>
  useMutation({
    mutationFn: (payload: OrderPayload) =>
      graphqlClient.request(addFastOrderDocument, {
        fastOrder: {
          note: payload.note,
          quarter: payload.quarterId,
          lowPrice: payload.lowPrice,
          price: payload.price,
          currency: payload.currency,
          company: payload.companyId,
          gps: payload.gps,
          amount: payload.amount,
          crop: payload.cropId,
          yearHarvested: payload.harvestYear,
          zip: payload.zip,
        },
      }),
  });

export { useAddFastContractMutation, useAddFastOrderMutation };
