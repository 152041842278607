import { useMutation } from "@tanstack/react-query";

const useClipboard = () =>
  useMutation({
    mutationFn: async (text: string) => {
      await navigator.clipboard.writeText(text);
    },
  });

export { useClipboard };
