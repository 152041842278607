/* eslint-disable lingui/no-unlocalized-strings */

import React from "react";

const RELEASE_KEY = "release";

const Maintenance = ({ children }: React.PropsWithChildren) => {
  useForceRefresh();
  const override = window.localStorage.getItem(RELEASE_KEY);

  const Jsx = (
    <div className="flex h-full w-full flex-col items-center justify-center gap-y-4 text-center">
      <h1 className="text-2xl font-bold text-can-forest-teal">
        Probíhá plánovaná odstávka
      </h1>
      <p>
        Na této stránce v současné době probíhá údržba. Děkujeme za pochopení.
      </p>
      <p>This page is currently undergoing maintenance.</p>
    </div>
  );

  if (override === "true") {
    return Jsx;
  }

  if (override === "false") {
    return children;
  }

  return children;
};

const useForceRefresh = () => {
  const [state, setState] = React.useState(false);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setState((prev) => !prev);
    }, 30_000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return state;
};

export { Maintenance };
