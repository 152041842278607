import { Trans } from "@lingui/react/macro";

import { Button } from "@/common/components/button/button";

import ShopSvg from "./assets/shop.svg?react";

const IWantToTradeDialog = ({ onClick }: { onClick: () => void }) => {
  return (
    <aside className="shadow-can-light-box sticky bottom-4 flex flex-col gap-6 rounded-t-[24px] bg-white p-4 sm:flex-row sm:rounded-[24px] @max-sm:flex-col">
      <div className="bg-can-silver-gray self-center rounded-full p-8 sm:p-4">
        <ShopSvg className="h-12 w-12 sm:h-6 sm:w-6 @max-sm:h-12 @max-sm:w-12" />
      </div>
      <div className="flex flex-col items-center sm:grow sm:items-start @max-sm:items-center">
        <h3 className="text-can-midnight-steel font-bold">
          <Trans>Toto jsou závazné výkupní ceny.</Trans>
        </h3>
        <p className="text-center text-xs sm:text-left @max-sm:text-center">
          <Trans>
            Ceny garantované obchodníky, kteří vkládají cenu do systému. Pokud
            chcete obchodovat, klikněte na tlačítko níže.
          </Trans>
        </p>
      </div>
      <Button
        onClick={onClick}
        className="basis-full disabled:cursor-wait sm:shrink-0 sm:basis-[30%] sm:self-center @max-sm:shrink @max-sm:basis-full @max-sm:self-auto"
        variant="primary"
      >
        <Trans>Chci obchodovat</Trans>
      </Button>
    </aside>
  );
};

export { IWantToTradeDialog };
