import { queryOptions, useQuery } from "@tanstack/react-query";
import React from "react";

import { getQR } from "@/generated/api/users";

const useQRCode = (invoiceId?: number) => {
  const qrCode = useQuery({
    ...qrCodeQuery(invoiceId!),
    enabled: !!invoiceId,
  });
  const qrCodeRef = React.createRef<HTMLImageElement>();

  React.useEffect(() => {
    if (!qrCode.data) {
      return;
    }
    if (!qrCodeRef.current) {
      return;
    }
    const blob = new Blob([qrCode.data.blob], { type: "image/png" });
    const blobUrl = URL.createObjectURL(blob);
    qrCodeRef.current.src = blobUrl;
  }, [qrCode.data, qrCodeRef]);

  return qrCodeRef;
};

const qrCodeQuery = (invoiceId: number) =>
  queryOptions({
    queryKey: ["qr-code", invoiceId],
    queryFn: ({ signal }) =>
      getQR(invoiceId, { signal }) as Promise<unknown> as Promise<{
        blob: Blob;
        filename: string;
      }>,
  });

export { useQRCode };
