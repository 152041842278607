import { useMutation } from "@tanstack/react-query";

import { getInvoice } from "@/generated/api/users";

const useDownloadInvoiceMutation = () =>
  useMutation({
    mutationFn: async (params: Parameters<typeof getInvoice>[0]) => {
      const { blob, filename } = (await getInvoice(params)) as unknown as {
        blob: Blob;
        filename: string;
      };
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    },
  });

export { useDownloadInvoiceMutation };
