import CoinUpArrow from "@icons/coin-up-arrow.svg?react";
import { msg, t } from "@lingui/core/macro";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/react/macro";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

import { ClientError } from "@/api/errors";
import { Chart } from "@/common/components/chart/chart";
import { Failed, NoData, Pending } from "@/common/components/info/info";
import { Metadata } from "@/common/components/metadata/metadata";
import { PageTitle } from "@/common/components/page-title/page-title";
import { QuickFilters } from "@/common/components/quick-filters/quick-filters";
import { Spacer } from "@/common/components/spacer/spacer";
import { Spinner } from "@/common/components/spinner/spinner";
import { useSidebar } from "@/common/providers/sidebar-provider/sidebar-provider";

import { TwoColumnLayout } from "../layout/layout";
import {
  CommodityProvider,
  CurrencyProvider,
  Filters,
} from "./stock-exchange.chart";
import { Outlook } from "./stock-exchange.outlook";
import {
  initialParams,
  mapping,
  stockExchangeParamsSchema,
} from "./stock-exchange.utils";

const path = "stock-exchange";
const charts = [
  {
    commodity: "WHEAT",
    source: "MATIF",
    periods: ["CURRENT", "NEXT"],
    unit: "/t",
    currency: "EUR",
  },
  {
    commodity: "RAPESEED",
    source: "MATIF",
    periods: ["CURRENT", "NEXT"],
    unit: "/t",
    currency: "EUR",
  },
  {
    commodity: "CRUDE_OIL",
    source: "BRENT",
    periods: ["CURRENT"],
    unit: "/barel",
    currency: "USD",
  },
] as const;

const StockExchange = () => {
  const { _ } = useLingui();
  const sidebar = useSidebar();

  return (
    <TwoColumnLayout
      right={false}
      left={{
        header: (
          <PageTitle>
            <Trans>Burza</Trans>
          </PageTitle>
        ),
        content: (
          <div className="flex basis-full flex-col">
            <Metadata title={_(msg`Burza`)} />
            <Spacer />
            <div
              className={`flex flex-col gap-x-6 gap-y-8 md:mx-0 lg:grid ${sidebar.state.screen ? "lg:grid-cols-1 2xl:grid-cols-2" : "lg:grid-cols-2"}`}
            >
              {charts.map((chart) => {
                return (
                  <Filters commodityId={chart.commodity} key={chart.commodity}>
                    {(filters) => (
                      <Chart.Container>
                        <Chart.Title>
                          {mapping[chart.commodity].title}
                        </Chart.Title>
                        <QuickFilters {...filters} />
                        <Chart.ChartContainer>
                          <QueryErrorResetBoundary>
                            {({ reset }) => (
                              <ErrorBoundary
                                onReset={reset}
                                fallbackRender={({
                                  error,
                                  resetErrorBoundary,
                                }) => (
                                  <>
                                    <Failed error={error}>
                                      <button
                                        className="text-can-forest-teal text-xs underline"
                                        onClick={resetErrorBoundary}
                                      >
                                        <Trans>Zkusit znovu</Trans>
                                      </button>
                                    </Failed>
                                  </>
                                )}
                              >
                                <Suspense fallback={<Spinner />}>
                                  <CommodityProvider
                                    currency={chart.currency}
                                    periods={chart.periods}
                                    key={chart.commodity}
                                    commodityId={chart.commodity}
                                  >
                                    {({ data, status }) => (
                                      <>
                                        {status === "success" ? (
                                          <>
                                            <div className="mr-6 flex flex-wrap justify-between gap-y-1">
                                              <Chart.Price>
                                                {data[0].current}
                                                {chart.unit}
                                              </Chart.Price>
                                              {data[0].trend ? (
                                                <Chart.Trend
                                                  currency={chart.currency}
                                                >
                                                  {data[0].trend}
                                                </Chart.Trend>
                                              ) : null}
                                              <Chart.Source>
                                                <Trans>Zdroj</Trans>:{" "}
                                                {chart.source}
                                              </Chart.Source>
                                            </div>
                                            <Chart
                                              data-testid={`chart-${chart.commodity.toLowerCase()}`}
                                              tooltip={
                                                data[1]
                                                  ? (label, datasetIndex) => {
                                                      if (datasetIndex === 0) {
                                                        return (
                                                          label +
                                                          // eslint-disable-next-line lingui/no-unlocalized-strings
                                                          "<span class='pl-2 font-normal'>(" +
                                                          t`letošní sklizeň` +
                                                          ")</span>"
                                                        );
                                                      }

                                                      return (
                                                        label +
                                                        // eslint-disable-next-line lingui/no-unlocalized-strings
                                                        "<span class='pl-2 font-normal'>(" +
                                                        t`budoucí sklizeň` +
                                                        ")</span>"
                                                      );
                                                    }
                                                  : undefined
                                              }
                                              currency={chart.currency}
                                              data={{
                                                current: data[0].rates,
                                                next: data[1]
                                                  ? data[1].rates
                                                  : undefined,
                                              }}
                                            />
                                          </>
                                        ) : (
                                          <NoData>
                                            <Trans>
                                              Burza ještě neotevřela
                                            </Trans>
                                          </NoData>
                                        )}
                                        {chart.commodity === "WHEAT" ||
                                        chart.commodity === "RAPESEED" ? (
                                          <Outlook
                                            commodityId={chart.commodity}
                                          />
                                        ) : null}
                                      </>
                                    )}
                                  </CommodityProvider>
                                </Suspense>
                              </ErrorBoundary>
                            )}
                          </QueryErrorResetBoundary>
                        </Chart.ChartContainer>
                      </Chart.Container>
                    )}
                  </Filters>
                );
              })}

              <CurrencyProvider>
                {({ currency, data, filters, status }) => (
                  <Chart.Container>
                    <Chart.Title>
                      <CoinUpArrow /> {data?.title}
                    </Chart.Title>
                    <QuickFilters {...filters} />
                    <Chart.ChartContainer>
                      {status === "success" ? (
                        <>
                          <div className="mr-6 flex flex-wrap justify-between gap-y-1">
                            <Chart.Price>{data.current}</Chart.Price>
                            <Chart.Trend currency={currency}>
                              {data.trend}
                            </Chart.Trend>
                            <Chart.Source>
                              <Trans>Zdroj</Trans>:{" "}
                              <Trans context="European central bank">ECB</Trans>
                            </Chart.Source>
                          </div>
                          {data.rates.length > 0 ? (
                            <Chart
                              currency={currency}
                              data={{
                                current: data.rates,
                              }}
                            />
                          ) : (
                            <NoData />
                          )}
                        </>
                      ) : status === "pending" ? (
                        <Pending />
                      ) : status === "empty" ? (
                        <NoData />
                      ) : (
                        <Failed error={new ClientError()} />
                      )}
                    </Chart.ChartContainer>
                  </Chart.Container>
                )}
              </CurrencyProvider>
            </div>
            <Spacer />
          </div>
        ),
      }}
    ></TwoColumnLayout>
  );
};

const StockExchangeParams = ({ children }: React.PropsWithChildren) => {
  const searchParams = useStockExchangeParams();
  const { pathname } = useLocation();

  if (!searchParams) {
    return <Navigate to={`${pathname}?${initialParams}`} />;
  }

  return <>{children}</>;
};

const useStockExchangeParams = () => {
  const [searchParams] = useSearchParams();
  const parsedParams = stockExchangeParamsSchema.safeParse(
    Object.fromEntries(searchParams),
  );

  if (parsedParams.success) {
    return parsedParams.data;
  }
};

export { path, StockExchange, StockExchangeParams, useStockExchangeParams };
