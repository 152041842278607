import { Trans } from "@lingui/react/macro";
import { RouteObject } from "react-router-dom";

import {
  ActionGuard,
  contractSignGuard,
} from "@/common/acl/action-guard/action-guard";
import { primary } from "@/common/components/button/button";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { FeatureMode } from "@/domain/plan/feature-mode.service";

import * as PurchasePrices from "./purchase-prices/purchase-prices";
import * as QuickTrading from "./quick-trading/quick-trading";
import * as QuickTradingDetail from "./quick-trading/quick-trading.detail";
import * as QuickTradingOrder from "./quick-trading/quick-trading.detail.order";
import * as Failed from "./shared/routes/failed/failed";
import { ContractLink, OrderLink } from "./shared/routes/success/order-link";
import * as Success from "./shared/routes/success/success";
import { routes as storagesRoutes } from "./storages/storages.routes";
import * as Trades from "./trades/trades";
import * as TradeDetail from "./trades/trades.detail";
import * as TradesDetailOrder from "./trades/trades.detail.order";
import * as TradesDetailSell from "./trades/trades.detail.sell";
import * as TransactionsAndOrders from "./transactions-and-orders/transactions-and-orders";
import * as ContractDetail from "./transactions-and-orders/transactions-and-orders.contract";
import * as OrderDetail from "./transactions-and-orders/transactions-and-orders.order";
import * as Signature from "./transactions-and-orders/transactions-and-orders.signature";

const quickTradingRoutes: RouteObject[] = [
  {
    path: QuickTrading.path,
    element: (
      <QuickTrading.Restoration>
        {(params) => (
          <QuickTrading.QuickTradingParams params={params}>
            <QuickTrading.QuickTrading />
          </QuickTrading.QuickTradingParams>
        )}
      </QuickTrading.Restoration>
    ),
    children: [
      {
        path: QuickTradingDetail.path,
        element: (
          <ActionGuard guard={contractSignGuard} grant="CONTRACT_SIGN">
            <QuickTradingDetail.QuickTradingDetail />
          </ActionGuard>
        ),
        children: [
          {
            path: QuickTradingOrder.path,
            element: <QuickTradingOrder.Order />,
            children: [
              {
                element: (
                  <Success.Success
                    action={<OrderLink />}
                    content={
                      <Success.Success.Content>
                        <Trans>
                          <b>Váš příkaz byl úspěšně nastaven.</b> Jakmile bude
                          aktivován, budeme vás neprodleně informovat.
                        </Trans>
                      </Success.Success.Content>
                    }
                    title={
                      <Success.Success.Title>
                        <Trans>Příkaz úspěšně nastaven</Trans>
                      </Success.Success.Title>
                    }
                  />
                ),
                path: `:orderId/offer/:offerId/${Success.path}`,
              },
              {
                element: (
                  <Failed.Failed
                    title={<Trans>Příkaz se nepodařilo nastavit</Trans>}
                    content={
                      <Trans>
                        Bohužel někde nastala chyba a příkaz k prodeji se
                        nepodařilo nastavit. Zkuste to později.
                      </Trans>
                    }
                    action={
                      <SearchParamsLink to=".." replace className={primary}>
                        <Trans>Přejít na detail příkazu</Trans>
                      </SearchParamsLink>
                    }
                  />
                ),
                path: Failed.path,
              },
            ],
          },
          {
            path: `sell/${Failed.path}`,
            element: (
              <Failed.Failed
                title={<Trans>Prodej se nepodařilo uskutečnit</Trans>}
                action={null}
                content={
                  <Trans>
                    Bohužel někde nastala chyba a prodej se nepodařilo
                    uskutečnit. Zkuste to později.
                  </Trans>
                }
              />
            ),
          },
          {
            path: `sell/${Success.path}/:contractId`,
            element: (
              <Success.Success
                content={
                  <Success.Success.Content>
                    <Trans>
                      <b>Transakce byla odeslána k potvrzení.</b> Jakmile bude
                      potvrzena, obratem vám pošleme upozornění a smlouvu k
                      podpisu.
                    </Trans>
                  </Success.Success.Content>
                }
                action={<ContractLink />}
                title={
                  <Success.Success.Title>
                    <Trans>Odesláno k potvrzení</Trans>
                  </Success.Success.Title>
                }
              />
            ),
          },
        ],
      },
    ],
  },
];

const getPurchasePricesRoutes = (mode: FeatureMode): RouteObject[] => [
  {
    path: PurchasePrices.path,
    element: (
      <PurchasePrices.Restoration>
        {(params) => (
          <PurchasePrices.PurchasePricesParams params={params}>
            <PurchasePrices.PurchasePrices mode={mode} />
          </PurchasePrices.PurchasePricesParams>
        )}
      </PurchasePrices.Restoration>
    ),
  },
];

const tradingRoutes: RouteObject[] = [
  {
    path: Trades.path,
    element: (
      <Trades.TradesParams>
        <Trades.Trades />
      </Trades.TradesParams>
    ),
    children: [
      {
        path: TradeDetail.path,
        element: (
          <TradeDetail.Params>
            <TradeDetail.TradeDetail />
          </TradeDetail.Params>
        ),
        children: [
          {
            path: TradesDetailSell.path,
            element: (
              <ActionGuard guard={contractSignGuard} grant="CONTRACT_SIGN">
                <TradesDetailSell.TradesDetailSell />
              </ActionGuard>
            ),
            children: [
              {
                path: TradesDetailOrder.path,
                element: <TradesDetailOrder.TradesDetailOrder />,
              },
            ],
          },
          {
            path: Success.path + "/contract/:contractId",
            element: (
              <Success.Success
                content={
                  <Success.Success.Content>
                    <Trans>
                      <b>Transakce byla odeslána k potvrzení.</b> Jakmile bude
                      potvrzena, obratem vám pošleme upozornění a smlouvu k
                      podpisu.
                    </Trans>
                  </Success.Success.Content>
                }
                action={<TradesDetailSell.TransactionLink />}
                title={
                  <Success.Success.Title>
                    <Trans>Odesláno k potvrzení</Trans>
                  </Success.Success.Title>
                }
              />
            ),
          },
          {
            element: (
              <Success.Success
                action={<OrderLink />}
                content={
                  <Success.Success.Content>
                    <Trans>
                      <b>Váš příkaz byl úspěšně nastaven.</b> Jakmile bude
                      aktivován, budeme vás neprodleně informovat.
                    </Trans>
                  </Success.Success.Content>
                }
                title={
                  <Success.Success.Title>
                    <Trans>Příkaz úspěšně nastaven</Trans>
                  </Success.Success.Title>
                }
              />
            ),
            path: Success.path + "/order/:orderId",
          },
          {
            path: Failed.path,
            element: (
              <Failed.Failed
                title={<Trans>Příkaz se nepodařilo nastavit</Trans>}
                content={
                  <Trans>
                    Bohužel někde nastala chyba a příkaz k prodeji se nepodařilo
                    nastavit. Zkuste to později.
                  </Trans>
                }
                action={
                  <SearchParamsLink to=".." replace className={primary}>
                    <Trans>Přejít na detail příkazu</Trans>
                  </SearchParamsLink>
                }
              />
            ),
          },
        ],
      },
    ],
  },
];

const transactionsAndOrdersRoutes: RouteObject[] = [
  {
    path: TransactionsAndOrders.path,
    element: (
      <TransactionsAndOrders.Params>
        <TransactionsAndOrders.TransactionsAndOrders />
      </TransactionsAndOrders.Params>
    ),
    children: [
      {
        element: <ContractDetail.Contract />,
        path: ContractDetail.path,
        children: [
          {
            path: Success.path,
            element: (
              <Success.Success
                action={<ContractLink />}
                content={
                  <Success.Success.Content>
                    <Trans>
                      <b>
                        Smlouva byla úspěšně podepsána a prodej je potvrzen.
                      </b>{" "}
                      Detail transakce včetně dokumentů naleznete v detailu
                      transakce.
                    </Trans>
                  </Success.Success.Content>
                }
                title={
                  <Success.Success.Title>
                    <Trans>Prodej dokončen!</Trans>
                  </Success.Success.Title>
                }
              />
            ),
          },
          {
            path: Signature.path,
            element: <Signature.Signature />,
          },
        ],
      },
      {
        element: (
          <ActionGuard guard={contractSignGuard} grant="CONTRACT_SIGN">
            <OrderDetail.Order />
          </ActionGuard>
        ),
        path: OrderDetail.path,
        children: [
          {
            element: (
              <Success.Success
                action={<OrderLink />}
                content={
                  <Success.Success.Content>
                    <Trans>
                      <b>Váš příkaz byl úspěšně nastaven.</b> Jakmile bude
                      aktivován, budeme vás neprodleně informovat.
                    </Trans>
                  </Success.Success.Content>
                }
                title={
                  <Success.Success.Title>
                    <Trans>Příkaz úspěšně nastaven</Trans>
                  </Success.Success.Title>
                }
              />
            ),
            path: Success.path,
          },
          {
            element: (
              <Failed.Failed
                title={<Trans>Příkaz se nepodařilo nastavit</Trans>}
                content={
                  <Trans>
                    Bohužel někde nastala chyba a příkaz k prodeji se nepodařilo
                    nastavit. Zkuste to později.
                  </Trans>
                }
                action={
                  <SearchParamsLink to=".." replace className={primary}>
                    <Trans>Přejít na detail příkazu</Trans>
                  </SearchParamsLink>
                }
              />
            ),
            path: Failed.path,
          },
        ],
      },
    ],
  },
];

export {
  getPurchasePricesRoutes,
  quickTradingRoutes,
  storagesRoutes,
  tradingRoutes,
  transactionsAndOrdersRoutes,
};
