import random from "lodash-es/random";

import { Strategy } from "../purchase-prices.flow";

class TrialStrategy implements Strategy {
  public getFavorites() {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    return ["Hrách", "Ječmen krmný"];
  }

  public isMasked(cropName: string) {
    return !this.getFavorites().includes(cropName);
  }

  public getMaskedPrice(cropName: string, currency: "CZK" | "EUR") {
    if (this.getFavorites().includes(cropName)) {
      return;
    }

    return {
      id: random(false).toString(),
      quarter:
        "Q" + random(1, 4, false).toString() + " " + random(10, 20).toString(),
      value: random(-10000, 0, false),
      currency,
    };
  }
}

export { TrialStrategy };
