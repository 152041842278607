import invariant from "tiny-invariant";

import { FullStrategy } from "./strategies/full.strategy";
import { TrialStrategy } from "./strategies/trial.strategy";

type Mode = "trial" | "full" | "not-available";

interface Strategy {
  getFavorites: () => string[];
  isMasked: (cropName: string) => boolean;
  getMaskedPrice: (
    cropName: string,
    currency: "CZK" | "EUR",
  ) =>
    | undefined
    | { id: string; quarter: string; value: number; currency: string };
}

/**
 * Purchase prices are blurred for users with trial version of the feature.
 */
class PurchasePricesFlow {
  private currentStrategy: Strategy | undefined;
  private strategies: Map<Mode, Strategy>;

  constructor() {
    this.strategies = new Map<Mode, Strategy>([
      ["trial", new TrialStrategy()],
      ["full", new FullStrategy()],
    ]);
  }

  public setStrategy(mode: Mode) {
    const strategy = this.strategies.get(mode);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    invariant(strategy, `Strategy for mode ${mode} does not exist`);
    this.currentStrategy = strategy;
  }

  public getFavorites(): string[] {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    invariant(this.currentStrategy, "No current strategy available");
    return this.currentStrategy.getFavorites();
  }

  public getMasked(cropName: string): boolean {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    invariant(this.currentStrategy, "No current strategy available");
    return this.currentStrategy.isMasked(cropName);
  }

  public getMaskedPrice(cropName: string, currency: "CZK" | "EUR") {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    invariant(this.currentStrategy, "No current strategy available");
    return this.currentStrategy.getMaskedPrice(cropName, currency);
  }
}

export { PurchasePricesFlow };
export type { Strategy };
