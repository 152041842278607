import { queryOptions, useMutation } from "@tanstack/react-query";

import { sortString } from "@/common/utils/array";
import { getDateFromISOString } from "@/common/utils/date";
import {
  createManual,
  getCompany,
  getCompanyUsers,
  SubscriptionCreateManualRequest,
} from "@/generated/api/users";

const selectCompany = (data: Awaited<ReturnType<typeof getCompany>>) => ({
  ...data,
  subscriptions: data.subscriptions.map((s) => ({
    ...s,
    expiresAt: getDateFromISOString(s.expiresAt),
    createdAt: getDateFromISOString(s.createdAt),
  })),
});

const companyQuery = ({ id }: { id: number }) =>
  queryOptions({
    queryKey: ["company", id],
    queryFn: ({ signal }) => getCompany(id, { signal }),
    select: selectCompany,
  });

const companyUsersQuery = ({ id }: { id: number }) =>
  queryOptions({
    queryKey: ["company", id, "users"],
    queryFn: ({ signal }) => getCompanyUsers(id, undefined, { signal }),
    select: (users) =>
      users.toSorted((a, b) =>
        sortString(a.userEmail ?? "", b.userEmail ?? ""),
      ),
  });

const useUpdateCompanySubscriptionPlanMutation = () =>
  useMutation({
    mutationFn: (payload: SubscriptionCreateManualRequest) =>
      createManual({
        companyId: payload.companyId,
        plan: payload.plan,
        expiresAt: payload.expiresAt,
      }),
  });

type CompanyUser = Awaited<ReturnType<typeof getCompanyUsers>>[number];

export {
  companyQuery,
  companyUsersQuery,
  useUpdateCompanySubscriptionPlanMutation,
};
export type { CompanyUser };
