import { t } from "@lingui/core/macro";

import {
  ClientError,
  ForbiddenError,
  NotFoundError,
  ServerError,
  UnauthorizedError,
} from "./errors";
import { token } from "./token";

type Args = {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  params?:
    | ConstructorParameters<typeof URLSearchParams>[0]
    | Record<string, unknown>;
  data?: unknown;
  headers?: Record<string, string>;
  responseType?: string;
  retries?: number;
  signal?: AbortSignal;
};

const fetchRetryJson = async <T>(args: Args): Promise<T> => {
  const result = await fetchRetry(args);

  if (result.headers.get("content-type")?.includes("application/json")) {
    return await result.json();
  } else if (result.headers.get("content-disposition")) {
    const contentDisposition = result.headers.get("content-disposition");
    const filename = contentDisposition
      ? contentDisposition.split("filename=")[1].replace(/["']/g, "")
      : "unknown-filename";

    // @ts-expect-error because of file download
    return {
      filename,
      blob: await result.blob(),
    };
  } else {
    // @ts-expect-error because of DELETE report that returns an empty response
    return null;
  }
};

const fetchRetry = async ({
  url,
  method,
  headers = {},
  data,
  retries = 1,
  signal,
}: Args): Promise<Response> => {
  const accessToken = await token.read({ forced: retries === 0 });
  const response = await fetch(
    url,
    // @ts-expect-error err
    {
      signal,
      method,
      headers: {
        ...headers,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        Authorization: `Bearer ${accessToken}`,
      },
      ...(data
        ? {
            body:
              // eslint-disable-next-line lingui/no-unlocalized-strings
              headers["Content-Type"] === "application/json"
                ? JSON.stringify(data)
                : data,
          }
        : {}),
    },
  );

  if (response.status === 401) {
    if (retries > 0) {
      return await fetchRetry({
        signal,
        url,
        method,
        headers,
        data,
        retries: 0,
      });
    }
  }

  if (!response.ok) {
    if (response.status === 401) {
      throw new UnauthorizedError();
    }

    if (response.status === 403) {
      throw new ForbiddenError();
    }

    if (response.status === 404) {
      throw new NotFoundError(undefined, { url, method, headers, data });
    }

    if (response.status >= 500) {
      throw new ServerError(t`Internal server error`);
    }

    if (response.status >= 400) {
      const json = await response.json();
      throw new ClientError(json.message, { status: response.status });
    }

    throw response;
  }

  return response;
};

export { fetchRetry, fetchRetryJson };
export type { Args };
