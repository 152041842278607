import Bell from "@icons/bell.svg?react";
import Cross from "@icons/cross.svg?react";
import Ellipse from "@icons/ellipse.svg?react";
import { Trans } from "@lingui/react/macro";
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";
import React from "react";
import { toast } from "react-toastify";
import { z } from "zod";

import { useSidebar } from "@/common/providers/sidebar-provider/sidebar-provider";
import { getEnvironmentVariable } from "@/common/services/env";
import { logger } from "@/common/services/logger";
import {
  create,
  PushCreateRequestPlatform,
} from "@/generated/api/notifications";

import { Button } from "../button/button";
import {
  Notification as NotificationComponent,
  Props as NotificationProps,
} from "./notification";
import {
  isNotificationSupported,
  useNotificationsState,
} from "./use-notification-state";

const firebaseConfig = {
  apiKey: getEnvironmentVariable("FIREBASE_API_KEY"),
  authDomain: getEnvironmentVariable("FIREBASE_AUTH_DOMAIN"),
  projectId: getEnvironmentVariable("FIREBASE_PROJECT_ID"),
  storageBucket: getEnvironmentVariable("FIREBASE_STORAGE_BUCKET"),
  messagingSenderId: getEnvironmentVariable("FIREBASE_MESSAGING_SENDER_ID"),
  appId: getEnvironmentVariable("FIREBASE_APP_ID"),
  measurementId: getEnvironmentVariable("FIREBASE_MEASUREMENT_ID"),
};

const app = initializeApp(firebaseConfig);
const messaging = (await isSupported()) && getMessaging(app);

if (navigator.serviceWorker) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .catch(logger.error);
}

const NotificationBell = () => {
  const sidebar = useSidebar();
  const notificationsState = useNotificationsState();
  const { messages } = { messages: [] };
  const { mutate: saveFirebaseToken } = useSaveFirebaseTokenMutation();
  const { refetch: refetchToken, data: token } = useQuery({
    ...useFirebaseTokenQuery(),
    enabled: isNotificationSupported(),
  });

  React.useEffect(() => {
    if (!token) {
      return;
    }

    saveFirebaseToken({
      token,
      platform: PushCreateRequestPlatform.IOS,
    });
  }, [saveFirebaseToken, token]);

  React.useEffect(() => {
    if (notificationsState === "granted") {
      refetchToken().catch(logger.error);
    }
  }, [notificationsState, refetchToken]);

  return (
    <button
      onClick={() =>
        sidebar.state.screen === "notifications"
          ? sidebar.actions.close()
          : sidebar.actions.openScreen("notifications")
      }
      className="hover:bg-can-silver-gray relative z-1 -mt-4 -mr-4 rounded-full p-4"
    >
      <Bell className="h-6 w-6" />
      {/* TODO make this dynamic */}
      {messages.length > 0 ? (
        <Ellipse className="absolute top-4 right-4" />
      ) : null}
    </button>
  );
};

const useSaveFirebaseTokenMutation = () =>
  useMutation({
    mutationFn: (payload: Parameters<typeof create>[0]) => create(payload),
  });

const useFirebaseTokenQuery = () => {
  const queryClient = useQueryClient();
  const notificationsState = useNotificationsState();

  return queryOptions({
    queryKey: ["firebase", "token", notificationsState],
    queryFn: async () => {
      await navigator.serviceWorker.ready;

      if (notificationsState !== "granted") {
        return null;
      }

      if (!messaging) {
        return null;
      }

      const token = await getToken(messaging, {
        vapidKey: getEnvironmentVariable("FIREBASE_VAPID_KEY"),
      });
      onMessage(messaging, (payload) => {
        const notification = z.object({
          title: z.string(),
          body: z.string(),
        });
        const safeNotification = notification.safeParse(payload.notification);
        if (safeNotification.data) {
          queryClient
            .invalidateQueries({ queryKey: ["notifications"] })
            .catch(logger.error);
          toast(
            // @ts-expect-error closeToast prop is (sadly) injected by toast function
            <Toast
              body={safeNotification.data.body}
              title={safeNotification.data.title}
            />,
          );
        }
      });

      return token;
    },
  });
};

// @ts-expect-error unused variable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NotificationState = ({
  state,
}: {
  state: typeof window.Notification.permission;
}) => {
  const handleNotificationsToggle = () => {
    if (!isNotificationSupported()) {
      return;
    }

    if (window.Notification.permission === "denied") {
      return;
    }

    if (window.Notification.permission === "granted") {
      return;
    }

    window.Notification.requestPermission().catch(logger.error);
  };
  switch (state) {
    case "granted":
      return (
        <p>
          <small>
            <Trans>Oznámení můžete zakázat v nastavení prohlížeče.</Trans>
          </small>
        </p>
      );
    case "denied":
      return (
        <p>
          <small>
            <Trans>Oznámení můžete povolit v nastavení prohlížeče.</Trans>
          </small>
        </p>
      );
    case "default":
      return (
        <Button variant="secondary" onClick={handleNotificationsToggle}>
          {" "}
          <Trans>Zapnout oznámení</Trans>
        </Button>
      );
  }
};

const Toast = ({
  title,
  body,
  closeToast,
}: {
  closeToast: () => void;
} & NotificationProps) => {
  return (
    <NotificationComponent>
      <NotificationComponent.Icon />
      <NotificationComponent.Content>
        <NotificationComponent.Title>{title}</NotificationComponent.Title>
        <NotificationComponent.Body>{body}</NotificationComponent.Body>
      </NotificationComponent.Content>
      <NotificationComponent.Button onClick={closeToast}>
        <Cross className="h-4 w-4" />
      </NotificationComponent.Button>
    </NotificationComponent>
  );
};

export { NotificationBell };
