import { GetTokenSilentlyOptions, useAuth0 } from "@auth0/auth0-react";
import React from "react";
import invariant from "tiny-invariant";
import { create } from "zustand";

type Fn = ReturnType<typeof useAuth0>["getAccessTokenSilently"];

type TokenRefresh = {
  pending: boolean;
  token: string | undefined;
  updateFn: ((options?: GetTokenSilentlyOptions) => Promise<void>) | undefined;
  setUpdateFn: (fn: Fn) => void;
};

const useTokenStore = create<TokenRefresh>((set) => ({
  pending: false,
  token: undefined,
  updateFn: undefined,
  setUpdateFn: (getFreshToken) =>
    set(() => ({
      updateFn: async (options) => {
        set({ pending: true });
        const token = await getFreshToken(options);
        set({
          token: token,
        });
        set({ pending: false });
      },
    })),
}));

const useTokenUpdate = () => {
  const updateFn = useTokenStore((state) => state.updateFn);
  const pending = useTokenStore((state) => state.pending);
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(updateFn, "Token store has not been initialized");

  const forceUpdate = React.useCallback(async () => {
    await updateFn({ cacheMode: "off" });
  }, [updateFn]);

  return [pending, forceUpdate] as const;
};

export { useTokenStore, useTokenUpdate };
