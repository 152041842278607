import CheckCircle from "@icons/check-circle.svg?react";
import Cross from "@icons/cross.svg?react";

import { Button } from "@/common/components/button/button";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { Spacer } from "@/common/components/spacer/spacer";
import { FullscreenModalLayout } from "@/routes/layout/layout.fullscreen";

type Props = {
  title: React.ReactNode;
  content: React.ReactNode;
  action: React.ReactNode;
  headerButton?: React.ReactNode;
};

const path = "success";
const Success = (props: Props) => {
  return (
    <FullscreenModalLayout
      headerButton={
        props.headerButton ?? (
          <SearchParamsLink to=".." replace>
            <Cross />
          </SearchParamsLink>
        )
      }
    >
      <Spacer className="h-6" />
      <div className="flex h-full flex-col items-center gap-y-6">
        {props.title}
        <Image />
        {props.content}
        <Button.Container className="mx-0 mt-auto w-full px-0">
          {props.action}
        </Button.Container>
      </div>
    </FullscreenModalLayout>
  );
};

const Title = (props: React.PropsWithChildren) => (
  <h1 className="text-2xl font-black text-can-forest-teal" {...props} />
);

const Content = (props: React.PropsWithChildren) => (
  <p className="text-center" {...props} />
);

const Image = (props: React.PropsWithChildren) => (
  <div className="rounded-full bg-can-silver-gray p-11" {...props}>
    <CheckCircle />
  </div>
);

Success.Content = Content;
Success.Title = Title;

export { path, Success };
