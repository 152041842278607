import { Trans } from "@lingui/react/macro";
import React from "react";

import { useRequiredFirstCompany } from "@/common/hooks/use-first-company";
import { Failed } from "@/routes/shared/routes/failed/failed";

const CanISignContractGuard = ({ children }: React.PropsWithChildren) => {
  const company = useRequiredFirstCompany();
  const canISign = company.grants.includes("CONTRACT_SIGN");

  if (canISign) {
    return <>{children}</>;
  }

  return (
    <Failed
      title={<Trans>Nemáte právo k podpisu smlouvy</Trans>}
      content={
        <Trans>Smlouvu může podepsat pouze uživatel s podpisovým právem</Trans>
      }
    />
  );
};

export { CanISignContractGuard };
