import { useMutation, useQueryClient } from "@tanstack/react-query";

import { graphqlClient } from "@/api/graphql";
import { graphql } from "@/generated/digitalnisklady.cz";

// eslint-disable-next-line lingui/no-unlocalized-strings
const archiveStorageDocument = graphql(`
  mutation ArchiveStorage($id: ID!) {
    archiveStorage(id: $id) {
      id
    }
  }
`);

const useArchiveStorage = () => {
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ["user"],
      });
    },
    mutationFn: ({ id }: { id: string }) =>
      graphqlClient.request(archiveStorageDocument, {
        id,
      }),
  });
};

export { useArchiveStorage };
