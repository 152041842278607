type Props = {
  properties: {
    label: React.ReactNode;
    value: React.ReactNode;
  }[];
};

const PropertiesList = ({ properties }: Props) => {
  return (
    <ul className="flex flex-col">
      {properties.map((row, i) => (
        <li
          key={i}
          className="flex flex-wrap justify-between border-b border-b-can-silver-cloud py-2 last-of-type:border-0"
        >
          <span>{row.label}</span>
          <b className="text-right">{row.value}</b>
        </li>
      ))}
    </ul>
  );
};

export { PropertiesList };
