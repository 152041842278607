import React from "react";
import { useLocation } from "react-router-dom";
import invariant from "tiny-invariant";

import { PlanPeriodToType } from "@/generated/api/users";

const SCREENS = {
  USER_PROFILE: "user-profile",
  USER_COMPANY: "user-company",
  USER_SUCCESS: "user-success",
  USER_FAILURE: "user-failure",
  NOTIFICATIONS: "notifications",
  SUBSCRIPTIONS: "subscriptions",
  SUBSCRIPTIONS_OVERVIEW: "subscriptions-overview",
  SUBSCRIPTIONS_DETAIL: "subscriptions-detail",
  SUBSCRIPTIONS_CANCEL: "subscriptions-cancel",
  SUBSCRIPTIONS_CANCEL_FAILURE: "subscriptions-cancel-failure",
  PAYMENT_CARD: "payment-card",
  PAYMENT_BANK_TRANSFER: "payment-bank-transfer",
  PAYMENT_DETAIL: "payment-detail",
} as const;

type UserScreen =
  | typeof SCREENS.USER_PROFILE
  | typeof SCREENS.USER_COMPANY
  | typeof SCREENS.USER_SUCCESS
  | typeof SCREENS.USER_FAILURE;
type PaymentBankTransferScreen = typeof SCREENS.PAYMENT_BANK_TRANSFER;
type PaymentCardScreen = typeof SCREENS.PAYMENT_CARD;
type PaymentDetailScreen = typeof SCREENS.PAYMENT_DETAIL;
type NotificationsScreen = typeof SCREENS.NOTIFICATIONS;
type SubscriptionsScreen = typeof SCREENS.SUBSCRIPTIONS;
type SubscriptionOverviewScreen = typeof SCREENS.SUBSCRIPTIONS_OVERVIEW;
type SubscriptionDetailScreen = typeof SCREENS.SUBSCRIPTIONS_DETAIL;
type SubscriptionCancelScreen = typeof SCREENS.SUBSCRIPTIONS_CANCEL;
type SubscriptionCancelFailureScreen =
  typeof SCREENS.SUBSCRIPTIONS_CANCEL_FAILURE;
type Screen =
  | NotificationsScreen
  | PaymentBankTransferScreen
  | PaymentCardScreen
  | SubscriptionCancelFailureScreen
  | SubscriptionCancelScreen
  | SubscriptionDetailScreen
  | SubscriptionOverviewScreen
  | SubscriptionsScreen
  | UserScreen
  | PaymentDetailScreen
  | undefined;

type BaseAction<T> = {
  type: T;
};

type OpenAction<S extends Screen> = BaseAction<"open"> & {
  screen: S;
};

type PaymentDetailOpenActionPayload = {
  paymentId: number;
};

type PaymentDetailOpenAction = OpenAction<PaymentDetailScreen> & {
  payload: PaymentDetailOpenActionPayload;
};

type PaymentBankTransferOpenActionSuccessPayload = {
  gatewayId: string;
  invoiceId: number;
  swift: string;
  iban: string;
  plan: string;
  price: string;
  priceWithVAT: string;
  variableSymbol: string;
  accountNumber: string;
  email: string;
  error?: never;
};

type PaymentBankTransferOpenActionErrorPayload = {
  gatewayId?: never;
  invoiceId?: never;
  swift?: never;
  iban?: never;
  plan?: never;
  price?: never;
  priceWithVAT?: never;
  variableSymbol?: never;
  accountNumber?: never;
  email?: never;
  error: Error;
};

type PaymentBankTransferOpenAction = OpenAction<PaymentBankTransferScreen> & {
  payload:
    | PaymentBankTransferOpenActionSuccessPayload
    | PaymentBankTransferOpenActionErrorPayload;
};

type PaymentCardOpenActionSuccessPayload = {
  price: string;
  priceWithVAT: string;
  publicKey: string;
  clientSecret: string;
  error?: never;
};

type PaymentCardOpenActionErrorPayload = {
  price?: never;
  priceWithVAT?: never;
  publicKey?: never;
  clientSecret?: never;
  error: Error;
};

type PaymentCardOpenAction = OpenAction<PaymentCardScreen> & {
  payload:
    | PaymentCardOpenActionSuccessPayload
    | PaymentCardOpenActionErrorPayload;
};

type SubscriptionOverviewActionPayload = {
  price: string;
  plan: string;
  period: PlanPeriodToType;
  periodId: number;
};

type SubscriptionOverviewOpenAction = OpenAction<SubscriptionOverviewScreen> & {
  payload: SubscriptionOverviewActionPayload;
};

type SubscriptionDetailPayload = {
  subscriptionId: number | null;
};

type SubscriptionDetailOpenAction = OpenAction<SubscriptionDetailScreen> & {
  payload: SubscriptionDetailPayload;
};

type SubscriptionCancelOpenAction = OpenAction<SubscriptionCancelScreen>;

type CommonAction =
  | OpenAction<Screen>
  | BaseAction<"toggle">
  | BaseAction<"noop">
  | BaseAction<"close">
  | BaseAction<"executeNext"> // execute next action based on current state
  | BaseAction<"executeClose">; // execute close action based on current state

type Action =
  | CommonAction
  | SubscriptionOverviewOpenAction
  | PaymentCardOpenAction
  | PaymentBankTransferOpenAction
  | PaymentDetailOpenAction
  | SubscriptionDetailOpenAction
  | SubscriptionCancelOpenAction;

type NavigationState =
  | {
      screen: Exclude<
        Screen,
        | SubscriptionOverviewScreen
        | SubscriptionDetailScreen
        | SubscriptionCancelScreen
        | PaymentBankTransferScreen
        | PaymentCardScreen
        | PaymentDetailScreen
      >;
      nextAction: Action;
      closeAction: Action;
    }
  | {
      screen: PaymentDetailScreen;
      nextAction: Action;
      closeAction: Action;
      paymentId: number;
    }
  | (PaymentBankTransferOpenActionSuccessPayload & {
      screen: PaymentBankTransferScreen;
      nextAction: Action;
      closeAction: Action;
    })
  | (PaymentBankTransferOpenActionErrorPayload & {
      screen: PaymentBankTransferScreen;
      nextAction: Action;
      closeAction: Action;
    })
  | (PaymentCardOpenActionSuccessPayload & {
      screen: PaymentCardScreen;
      nextAction: Action;
      closeAction: Action;
    })
  | (PaymentCardOpenActionErrorPayload & {
      screen: PaymentCardScreen;
      nextAction: Action;
      closeAction: Action;
    })
  | {
      screen: SubscriptionOverviewScreen;
      nextAction: Action;
      closeAction: Action;
      price: string;
      plan: string;
      period: PlanPeriodToType;
      periodId: number;
    }
  | {
      screen: SubscriptionDetailScreen;
      subscriptionId: number | null;
      nextAction: Action;
      closeAction: Action;
    }
  | {
      screen: SubscriptionCancelScreen;
      subscriptionId: number;
      nextAction: Action;
      closeAction: Action;
    };

type ScreenConfig = {
  nextAction: Action;
  closeAction: Action;
};

const screenConfigs: Record<Exclude<Screen, undefined>, ScreenConfig> = {
  [SCREENS.USER_SUCCESS]: {
    nextAction: { type: "open", screen: SCREENS.USER_PROFILE },
    closeAction: { type: "toggle" },
  },
  [SCREENS.USER_FAILURE]: {
    nextAction: { type: "open", screen: SCREENS.USER_PROFILE },
    closeAction: { type: "toggle" },
  },
  [SCREENS.USER_PROFILE]: {
    nextAction: { type: "open", screen: SCREENS.USER_COMPANY },
    closeAction: { type: "toggle" },
  },
  [SCREENS.USER_COMPANY]: {
    nextAction: { type: "noop" },
    closeAction: { type: "open", screen: SCREENS.USER_PROFILE },
  },
  [SCREENS.NOTIFICATIONS]: {
    nextAction: { type: "noop" },
    closeAction: { type: "toggle" },
  },
  [SCREENS.PAYMENT_CARD]: {
    nextAction: { type: "noop" },
    closeAction: { type: "toggle" },
  },
  [SCREENS.SUBSCRIPTIONS]: {
    nextAction: { type: "noop" },
    closeAction: { type: "toggle" },
  },
  [SCREENS.SUBSCRIPTIONS_DETAIL]: {
    nextAction: { type: "noop" },
    closeAction: { type: "open", screen: SCREENS.SUBSCRIPTIONS },
  },
  [SCREENS.SUBSCRIPTIONS_CANCEL]: {
    nextAction: { type: "noop" },
    closeAction: { type: "open", screen: SCREENS.SUBSCRIPTIONS },
  },
  [SCREENS.SUBSCRIPTIONS_CANCEL_FAILURE]: {
    nextAction: { type: "noop" },
    closeAction: { type: "close" },
  },

  [SCREENS.SUBSCRIPTIONS_OVERVIEW]: {
    nextAction: { type: "noop" },
    closeAction: { type: "close" },
  },
  [SCREENS.PAYMENT_BANK_TRANSFER]: {
    nextAction: { type: "noop" },
    closeAction: { type: "close" },
  },
  [SCREENS.PAYMENT_DETAIL]: {
    nextAction: { type: "noop" },
    closeAction: { type: "open", screen: SCREENS.SUBSCRIPTIONS },
  },
};

const initialState: NavigationState = {
  screen: undefined,
  nextAction: { type: "open", screen: SCREENS.USER_PROFILE },
  closeAction: { type: "toggle" },
};

// TODO check rather isAction instead
const isSubscriptionOverviewAction = (
  action: Action,
): action is SubscriptionOverviewOpenAction =>
  action.type === "open" && action.screen === SCREENS.SUBSCRIPTIONS_OVERVIEW;

const isPaymentCardAction = (action: Action): action is PaymentCardOpenAction =>
  action.type === "open" && action.screen === SCREENS.PAYMENT_CARD;

const isSubscriptionDetailAction = (
  action: Action,
): action is SubscriptionDetailOpenAction =>
  action.type === "open" && action.screen === SCREENS.SUBSCRIPTIONS_DETAIL;

const isSubscriptionCancelAction = (
  action: Action,
): action is SubscriptionCancelOpenAction =>
  action.type === "open" && action.screen === SCREENS.SUBSCRIPTIONS_CANCEL;

const isBankTransferAction = (
  action: Action,
): action is PaymentBankTransferOpenAction =>
  action.type === "open" && action.screen === SCREENS.PAYMENT_BANK_TRANSFER;

const isOpenAction = (
  action: Action,
): action is OpenAction<
  Exclude<
    Screen,
    | SubscriptionOverviewScreen
    | SubscriptionDetailScreen
    | SubscriptionCancelScreen
    | PaymentBankTransferScreen
    | PaymentCardScreen
    | PaymentDetailScreen
  >
> =>
  action.type === "open" &&
  action.screen !== SCREENS.PAYMENT_CARD &&
  action.screen !== SCREENS.PAYMENT_BANK_TRANSFER &&
  action.screen !== SCREENS.PAYMENT_DETAIL &&
  action.screen !== SCREENS.SUBSCRIPTIONS_DETAIL &&
  action.screen !== SCREENS.SUBSCRIPTIONS_OVERVIEW &&
  action.screen !== SCREENS.SUBSCRIPTIONS_CANCEL;

const isPaymentDetailOpenAction = (
  action: Action,
): action is PaymentDetailOpenAction =>
  action.type === "open" && action.screen === SCREENS.PAYMENT_DETAIL;

const reducer = (state: NavigationState, action: Action): NavigationState => {
  switch (action.type) {
    case "executeNext":
      return reducer(state, state.nextAction);
    case "executeClose":
      return reducer(state, state.closeAction);
    case "open": {
      if (!action.screen) return state;

      const config = screenConfigs[action.screen];

      if (isOpenAction(action)) {
        return {
          screen: action.screen,
          nextAction: config.nextAction,
          closeAction: config.closeAction,
        };
      }

      if (isPaymentDetailOpenAction(action)) {
        return {
          screen: action.screen,
          nextAction: config.nextAction,
          closeAction: config.closeAction,
          paymentId: action.payload.paymentId,
        };
      }

      if (isBankTransferAction(action)) {
        if (action.payload.error) {
          return {
            screen: action.screen,
            nextAction: config.nextAction,
            closeAction: config.closeAction,
            error: action.payload.error,
          };
        }

        return {
          screen: action.screen,
          nextAction: config.nextAction,
          closeAction: config.closeAction,
          ...action.payload,
        };
      }

      if (isPaymentCardAction(action)) {
        if (action.payload.error) {
          return {
            screen: action.screen,
            nextAction: config.nextAction,
            closeAction: config.closeAction,
            error: action.payload.error,
          };
        }
        return {
          screen: action.screen,
          nextAction: config.nextAction,
          closeAction: config.closeAction,
          ...action.payload,
        };
      }

      if (isSubscriptionOverviewAction(action)) {
        return {
          screen: action.screen,
          nextAction: config.nextAction,
          closeAction: config.closeAction,
          ...action.payload,
        };
      }

      if (isSubscriptionDetailAction(action)) {
        return {
          screen: action.screen,
          nextAction: config.nextAction,
          closeAction: config.closeAction,
          ...action.payload,
        };
      }

      if (isSubscriptionCancelAction(action)) {
        invariant(state.screen === SCREENS.SUBSCRIPTIONS_DETAIL);
        invariant(state.subscriptionId);
        return {
          screen: action.screen,
          nextAction: config.nextAction,
          closeAction: config.closeAction,
          subscriptionId: state.subscriptionId,
        };
      }
      throw new Error(
        // eslint-disable-next-line lingui/no-unlocalized-strings
        `You should have never got here for action: ${JSON.stringify(action)}`,
      );
    }

    case "toggle":
      return {
        screen: undefined,
        nextAction: { type: "open", screen: SCREENS.USER_PROFILE },
        closeAction: { type: "noop" },
      };

    case "close":
      return {
        screen: undefined,
        nextAction: { type: "noop" },
        closeAction: { type: "noop" },
      };

    case "noop":
      return state;

    default: {
      const exhaustiveCheck: never = action;
      throw new Error(
        // eslint-disable-next-line lingui/no-unlocalized-strings
        `Unhandled action type: ${JSON.stringify(exhaustiveCheck)}`,
      );
    }
  }
};

export const useNavigation = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const actions = React.useMemo(
    () => ({
      openScreen: (screen: Screen) => dispatch({ type: "open", screen }),
      openPaymentDetail: (payload: PaymentDetailOpenActionPayload) =>
        dispatch({ type: "open", screen: SCREENS.PAYMENT_DETAIL, payload }),
      openPaymentBankTransfer: (
        payload:
          | PaymentBankTransferOpenActionErrorPayload
          | PaymentBankTransferOpenActionSuccessPayload,
      ) =>
        dispatch({
          type: "open",
          screen: SCREENS.PAYMENT_BANK_TRANSFER,
          payload,
        }),
      openPaymentCard: (
        payload:
          | PaymentCardOpenActionErrorPayload
          | PaymentCardOpenActionSuccessPayload,
      ) =>
        dispatch({
          type: "open",
          screen: SCREENS.PAYMENT_CARD,
          payload,
        }),
      openSubscriptionOverview: (payload: SubscriptionOverviewActionPayload) =>
        dispatch({
          type: "open",
          screen: SCREENS.SUBSCRIPTIONS_OVERVIEW,
          payload,
        }),
      openCancelSubscription: () => {
        dispatch({
          type: "open",
          screen: SCREENS.SUBSCRIPTIONS_CANCEL,
        });
      },
      openSubscription: (payload: SubscriptionDetailPayload) =>
        dispatch({
          type: "open",
          screen: SCREENS.SUBSCRIPTIONS_DETAIL,
          payload,
        }),
      toggle: () => dispatch({ type: "toggle" }),
      close: () => dispatch({ type: "close" }),
      noop: () => dispatch({ type: "noop" }),
      executeNextAction: () => dispatch({ type: "executeNext" }),
      executeCloseAction: () => dispatch({ type: "executeClose" }),
    }),
    [],
  );

  return {
    state,
    actions,
  };
};

const Context = React.createContext<
  ReturnType<typeof useNavigation> | undefined
>(undefined);

const useSidebar = () => {
  const ctx = React.useContext(Context);
  invariant(
    ctx,
    // eslint-disable-next-line lingui/no-unlocalized-strings
    "sidebar context is only available within <SidebarProvider /> component",
  );

  return ctx;
};

const useAutoclosedSidebar = () => {
  const { actions } = useSidebar();
  const location = useLocation();

  React.useEffect(() => {
    actions.toggle();
  }, [location.key, actions]);
};

const Autoclose = ({ children }: React.PropsWithChildren) => {
  useAutoclosedSidebar();

  return <>{children}</>;
};

const SidebarProvider = ({ children }: React.PropsWithChildren) => {
  const navigation = useNavigation();

  return (
    <Context.Provider value={{ ...navigation }}>{children}</Context.Provider>
  );
};

export { Autoclose, SidebarProvider, useSidebar };
export type { Screen };
