import { useAuth0 } from "@auth0/auth0-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import invariant from "tiny-invariant";

import { getUser } from "@/common/services/user";
import { combineCallingCodeWithCountry } from "@/common/utils/phone";
import { phoneCountryCodesQuery } from "@/routes/incomplete-user/incomplete-user.api";

const useCountryCode = (language: string) => {
  const { user: _user } = useAuth0();
  invariant(_user);
  const user = getUser(_user);
  const phoneCountryCodes = useSuspenseQuery(
    phoneCountryCodesQuery({ language }),
  );
  const currentCountryCode = phoneCountryCodes.data.find(
    (country) =>
      country.phoneCountryCode === user.user_metadata.common.phone.countryCode,
  );
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(currentCountryCode, "current country code is expected");
  return {
    current: combineCallingCodeWithCountry(currentCountryCode),
    phoneCountryCodes,
  };
};

export { useCountryCode };
