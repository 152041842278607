import { ErrorMessage } from "@hookform/error-message";
import { Trans } from "@lingui/react/macro";
import Image from "@tiptap/extension-image";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Markdown } from "tiptap-markdown";

import { useEditorForm } from "./editor";
import { EditorErrorMessage } from "./editor.error-message";
import { MenuBar } from "./editor.menu-bar";
import { handleImageDrop } from "./editor.utils";

const Commodity = ({
  commodity,
  index,
}: {
  commodity: string;
  index: number;
}) => {
  const form = useEditorForm();

  return (
    <>
      <div className="flex flex-col">
        <span className="block h-4" />
        <label htmlFor={`headline-${commodity}`}>
          <Trans>Nadpis sekce</Trans>
        </label>
        <input
          {...form.register(`commodities.${index}.headline`)}
          autoFocus
          type="text"
          id={`${commodity}-headline`}
          className="border-can-silver-cloud h-10 w-full rounded-sm border px-2 font-sans"
        />
        <ErrorMessage
          name={`commodities.${index}.headline`}
          errors={form.formState.errors}
          render={EditorErrorMessage}
        />
        <span className="block h-4" />
        <details open>
          <summary>
            <Trans>Shrnutí</Trans>
          </summary>
          <Editor
            onUpdate={(value) =>
              form.setValue(`commodities.${index}.summary`, value)
            }
            content={form.getValues(`commodities.${index}.summary`)}
          />
        </details>
        <span className="block h-4" />
        <details open>
          <summary>
            <Trans>Doporučení</Trans>
          </summary>
          <Editor
            onUpdate={(value) =>
              form.setValue(`commodities.${index}.recommendationText`, value)
            }
            content={form.getValues(`commodities.${index}.recommendationText`)}
          />
        </details>
      </div>
      <div className="flex flex-col">
        <label htmlFor={`recommendation-${commodity}`}>
          <Trans>Výhled</Trans>
        </label>
        <select
          {...form.register(`commodities.${index}.recommendation`)}
          id={`recommendation-${commodity}`}
          className="border-can-silver-cloud min-w-48 self-start rounded-sm border bg-white px-2 py-3"
        >
          <option disabled value="">
            ---
          </option>
          <option value="NORMAL">
            <Trans>Neočekává se růst ani pokles cen</Trans>
          </option>
          <option value="BEARISH">
            <Trans>Očekává se pokles cen</Trans>
          </option>
          <option value="BULLISH">
            <Trans>Očekává se růst cen</Trans>
          </option>
        </select>
      </div>
    </>
  );
};

const Editor = ({
  content = "",
  onUpdate,
}: {
  content?: string;
  onUpdate: (markdown: string) => void;
}) => {
  const editor = useEditor({
    extensions,
    content: content,
    editorProps: {
      handleDrop: handleImageDrop,
      attributes: {
        // eslint-disable-next-line lingui/no-unlocalized-strings
        class: "h-[500px] can-auto-tailwindcss overflow-y-auto",
      },
    },
    onUpdate: ({ editor }) => {
      onUpdate(editor.storage.markdown.getMarkdown());
    },
  });

  if (!editor) {
    return null;
  }

  return (
    <>
      <MenuBar editor={editor} />
      <EditorContent
        className="border-can-silver-cloud min-h-[500px] rounded-sm border"
        editor={editor}
      />
    </>
  );
};

const extensions = [
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  Markdown,
  Image,
];

export { Commodity };
