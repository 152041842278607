import { useSyncExternalStore } from "react";

import { logger } from "@/common/services/logger";

const getSnapshot = (): NotificationPermission => {
  if (!isNotificationSupported()) {
    return "denied";
  }
  return window.Notification.permission;
};

const subscribe = (callback: () => void) => {
  if (!isNotificationSupported()) {
    return () => null;
  }
  navigator.permissions
    .query({ name: "notifications" })
    .then((p) => p.addEventListener("change", callback))
    .catch(logger.error);
  return () => {
    navigator.permissions
      .query({ name: "notifications" })
      .then((p) => p.removeEventListener("change", callback))
      .catch(logger.error);
  };
};

const useNotificationsState = () => {
  const state = useSyncExternalStore(subscribe, getSnapshot);
  return state;
};

const isNotificationSupported = () =>
  // just because we want to be really careful with the devices we support
  "Notification" in window && "query" in (navigator?.permissions ?? {});

export { isNotificationSupported, useNotificationsState };
