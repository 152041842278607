import invariant from "tiny-invariant";

import { useSidebar } from "@/common/providers/sidebar-provider/sidebar-provider";

const useSubscriptionScreen = () => {
  const { state } = useSidebar();
  invariant(
    state.screen === "subscriptions-detail",
    // eslint-disable-next-line lingui/no-unlocalized-strings
    `Unexpected screen: ${state.screen}`,
  );

  return {
    subscriptionId: state.subscriptionId,
  };
};

const useCancelSubscriptionScreen = () => {
  const { state } = useSidebar();
  invariant(
    state.screen === "subscriptions-cancel",
    // eslint-disable-next-line lingui/no-unlocalized-strings
    `Unexpected screen: ${state.screen}`,
  );

  return {
    subscriptionId: state.subscriptionId,
  };
};

export { useCancelSubscriptionScreen, useSubscriptionScreen };
