import { useAuth0 } from "@auth0/auth0-react";
import AngleLeft from "@icons/angle-left.svg?react";
import { Trans } from "@lingui/react/macro";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";
import invariant from "tiny-invariant";

import { useGuard } from "@/common/acl/action-guard/action-guard";
import { Button } from "@/common/components/button/button";
import {
  CompanyForm as CompanyFormComponent,
  Fields,
  getInitialValues,
} from "@/common/components/company-form/company-form";
import { Failed } from "@/common/components/info/info";
import { Spacer } from "@/common/components/spacer/spacer";
import { useSidebar } from "@/common/providers/sidebar-provider/sidebar-provider";
import { splitCallingCodeAndCountry } from "@/common/utils/phone";
import { FullscreenModalLayout } from "@/routes/layout/layout.fullscreen";

import { companyQuery, useUpdateCompanyMutation } from "./company-form.api";

const CompanyForm = () => {
  const sidebar = useSidebar();

  return (
    <FullscreenModalLayout
      headerButton={
        <button onClick={sidebar.actions.executeCloseAction}>
          <AngleLeft />
        </button>
      }
    >
      <ErrorBoundary fallbackRender={({ error }) => <Failed error={error} />}>
        <Form />
      </ErrorBoundary>
    </FullscreenModalLayout>
  );
};

const Form = () => {
  const queryClient = useQueryClient();
  const { user: _user } = useAuth0();
  invariant(_user);
  const sidebar = useSidebar();
  const {
    data: { company, disabledFields },
  } = useSuspenseQuery(companyQuery());
  const updateCompany = useUpdateCompanyMutation();
  const initialValues = getInitialValues(company);
  const handleSubmit = (data: Fields) => {
    updateCompany.mutate(
      {
        id: company.id,
        companyForm: data.legalForm,
        vat: data.vat,
        address: data.address,
        contactName: data.contact.name,
        contactPosition: data.contact.role,
        contactPhone: {
          phoneNumber: data.contact.phone.phoneNumber,
          countryCode: splitCallingCodeAndCountry(
            data.contact.phone.countryCode,
          ).at(0)!,
        },
        contactEmail: data.contact.email,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: ["company-info"],
          });
          sidebar.actions.openScreen("user-success");
        },
        onError: () => {
          sidebar.actions.openScreen("user-failure");
        },
      },
    );
  };
  const guardedSubmit = useGuard({ action: handleSubmit });

  return (
    <>
      <Spacer className="h-6" />
      <h1 className="text-2xl font-black text-can-forest-teal">
        <Trans>Informace o společnosti</Trans>
      </h1>
      <Spacer className="h-6" />
      <CompanyFormComponent
        disabledFields={disabledFields}
        initialValues={initialValues}
        onSubmit={guardedSubmit}
      />
      <Button.Container>
        <Button
          disabled={updateCompany.status === "pending"}
          type="submit"
          variant="primary"
          form="company-form"
        >
          <Trans>Uložit</Trans>
        </Button>
      </Button.Container>
    </>
  );
};

export { CompanyForm };
