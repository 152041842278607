/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * Clever Analytics News Public API
 * OpenAPI spec version: 1.0.0
 */
import { newsInstance } from '../../api/client';
import { imageUpload } from '../../api/image-upload';
export type CreateBody = {
  file: Blob;
};

export type GetReportsSortOrder = typeof GetReportsSortOrder[keyof typeof GetReportsSortOrder];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetReportsSortOrder = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;

export type GetReportsParams = {
'date-from'?: string;
'date-to'?: string;
'sort-order': GetReportsSortOrder;
};

export interface LanguageTo {
  id: string;
  name: string;
  localName: string;
}

export interface ErrorResponse {
  timestamp: number;
  status: string;
  message?: string;
  errors?: string[];
}

export type ReportListReportToStatus = typeof ReportListReportToStatus[keyof typeof ReportListReportToStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportListReportToStatus = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
} as const;

export interface ReportListReportTo {
  id: number;
  dateFrom: string;
  dateTo: string;
  headline: string;
  summary?: string;
  image?: ImageTo;
  language: string;
  status: ReportListReportToStatus;
}

export interface ReportListTo {
  first?: string;
  last?: string;
  reports: ReportListReportTo[];
}

export interface ReportPublicListTo {
  id: number;
  dateFrom: string;
  dateTo: string;
  headline: string;
  summary?: string;
  image?: ImageTo;
  language: string;
}

export type ReportPatchToStatus = typeof ReportPatchToStatus[keyof typeof ReportPatchToStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportPatchToStatus = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
} as const;

export interface ReportPatchTo {
  status?: ReportPatchToStatus;
}

export interface ReportE2EPatchTo {
  dateFrom?: string;
  dateTo?: string;
}

export interface ReportCreateTo {
  dateFrom: string;
  dateTo: string;
  headline: string;
  summary?: string;
  imageId?: string;
  language: string;
  commodities: ReportCreateCommodityTo[];
}

export type ReportToStatus = typeof ReportToStatus[keyof typeof ReportToStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportToStatus = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
} as const;

export type ReportCommodityToRecommendation = typeof ReportCommodityToRecommendation[keyof typeof ReportCommodityToRecommendation];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportCommodityToRecommendation = {
  BULLISH: 'BULLISH',
  BEARISH: 'BEARISH',
  NORMAL: 'NORMAL',
} as const;

export interface ReportCommodityTo {
  commodity: CommodityTo;
  headline: string;
  summary: string;
  recommendation: ReportCommodityToRecommendation;
  recommendationText: string;
}

export interface ImageTo {
  guid: string;
  url: string;
}

export interface ReportTo {
  id: number;
  dateFrom: string;
  dateTo: string;
  headline: string;
  summary?: string;
  image?: ImageTo;
  language: string;
  status: ReportToStatus;
  commodities: ReportCommodityTo[];
}

export interface CommodityTo {
  id: number;
  name: string;
  icon: string;
}

export type ReportCreateCommodityToRecommendation = typeof ReportCreateCommodityToRecommendation[keyof typeof ReportCreateCommodityToRecommendation];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportCreateCommodityToRecommendation = {
  BULLISH: 'BULLISH',
  BEARISH: 'BEARISH',
  NORMAL: 'NORMAL',
} as const;

export interface ReportCreateCommodityTo {
  commodityId: number;
  headline: string;
  summary: string;
  recommendation: ReportCreateCommodityToRecommendation;
  recommendationText: string;
}

export interface ReportUpdateTo {
  dateFrom: string;
  dateTo: string;
  headline: string;
  summary?: string;
  imageId?: string;
  language: string;
  commodities: ReportCreateCommodityTo[];
}




type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  /**
 * @summary Report detail
 */
export const getReport = (
    id: number,
 options?: SecondParameter<typeof newsInstance>,) => {
      return newsInstance<ReportTo>(
      {url: `/can-news/api/report/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Update report attributes [news:report:manage]
 */
export const putReport = (
    id: number,
    reportUpdateTo: ReportUpdateTo,
 options?: SecondParameter<typeof newsInstance>,) => {
      return newsInstance<ReportTo>(
      {url: `/can-news/api/report/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: reportUpdateTo
    },
      options);
    }
  
/**
 * @summary Delete report [news:report:manage]
 */
export const deleteReport = (
    id: number,
 options?: SecondParameter<typeof newsInstance>,) => {
      return newsInstance<ReportTo>(
      {url: `/can-news/api/report/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * @summary Set report attributes [news:report:manage]
 */
export const patchReport = (
    id: number,
    reportPatchTo: ReportPatchTo,
 options?: SecondParameter<typeof newsInstance>,) => {
      return newsInstance<ReportTo>(
      {url: `/can-news/api/report/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: reportPatchTo
    },
      options);
    }
  
/**
 * @summary List of all reports for provided date range
 */
export const getReports = (
    params: GetReportsParams,
 options?: SecondParameter<typeof newsInstance>,) => {
      return newsInstance<ReportListTo>(
      {url: `/can-news/api/report`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * @summary Create a new report [news:report:manage]
 */
export const createReport = (
    reportCreateTo: ReportCreateTo,
 options?: SecondParameter<typeof newsInstance>,) => {
      return newsInstance<ReportTo>(
      {url: `/can-news/api/report`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: reportCreateTo
    },
      options);
    }
  
/**
 * @summary Upload image [news:report:manage]
 */
export const create = (
    createBody: CreateBody,
 ) => {
      return imageUpload<ImageTo>(
      {url: `/can-news/api/image`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createBody
    },
      );
    }
  
/**
 * @summary Update report
 */
export const patchTestReport = (
    id: number,
    reportE2EPatchTo: ReportE2EPatchTo,
 options?: SecondParameter<typeof newsInstance>,) => {
      return newsInstance<void>(
      {url: `/can-news/e2e/api/report/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: reportE2EPatchTo
    },
      options);
    }
  
/**
 * @summary List of all available reports
 */
export const getPublicReports = (
    language: string,
 options?: SecondParameter<typeof newsInstance>,) => {
      return newsInstance<ReportPublicListTo[]>(
      {url: `/can-news/public/api/report/${language}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary List of all available languages
 */
export const getLanguages = (
    
 options?: SecondParameter<typeof newsInstance>,) => {
      return newsInstance<LanguageTo[]>(
      {url: `/can-news/api/language`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Language detail
 */
export const getLanguage = (
    id: string,
 options?: SecondParameter<typeof newsInstance>,) => {
      return newsInstance<LanguageTo>(
      {url: `/can-news/api/language/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Image detail
 */
export const getImage = (
    id: string,
 options?: SecondParameter<typeof newsInstance>,) => {
      return newsInstance<ImageTo>(
      {url: `/can-news/api/image/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary List of all available commodities
 */
export const getCommodities = (
    
 options?: SecondParameter<typeof newsInstance>,) => {
      return newsInstance<CommodityTo[]>(
      {url: `/can-news/api/commodity`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Commodity detail
 */
export const getCommodity = (
    id: number,
 options?: SecondParameter<typeof newsInstance>,) => {
      return newsInstance<CommodityTo>(
      {url: `/can-news/api/commodity/${id}`, method: 'GET'
    },
      options);
    }
  
export type GetReportResult = NonNullable<Awaited<ReturnType<typeof getReport>>>
export type PutReportResult = NonNullable<Awaited<ReturnType<typeof putReport>>>
export type DeleteReportResult = NonNullable<Awaited<ReturnType<typeof deleteReport>>>
export type PatchReportResult = NonNullable<Awaited<ReturnType<typeof patchReport>>>
export type GetReportsResult = NonNullable<Awaited<ReturnType<typeof getReports>>>
export type CreateReportResult = NonNullable<Awaited<ReturnType<typeof createReport>>>
export type CreateResult = NonNullable<Awaited<ReturnType<typeof create>>>
export type PatchTestReportResult = NonNullable<Awaited<ReturnType<typeof patchTestReport>>>
export type GetPublicReportsResult = NonNullable<Awaited<ReturnType<typeof getPublicReports>>>
export type GetLanguagesResult = NonNullable<Awaited<ReturnType<typeof getLanguages>>>
export type GetLanguageResult = NonNullable<Awaited<ReturnType<typeof getLanguage>>>
export type GetImageResult = NonNullable<Awaited<ReturnType<typeof getImage>>>
export type GetCommoditiesResult = NonNullable<Awaited<ReturnType<typeof getCommodities>>>
export type GetCommodityResult = NonNullable<Awaited<ReturnType<typeof getCommodity>>>
