import { queryOptions, useMutation } from "@tanstack/react-query";

import { getLanguages, patch, UserPatchRequest } from "@/generated/api/users";

const useUpdateUserSettingsMutation = () =>
  useMutation({
    mutationKey: ["user", "update"],
    mutationFn: (params: UserPatchRequest) => patch(params),
  });

const appLanguagesQuery = () =>
  queryOptions({
    queryKey: ["app-languages"],
    queryFn: ({ signal }) => getLanguages({ signal }),
  });

export { appLanguagesQuery, useUpdateUserSettingsMutation };
