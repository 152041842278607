import { currencyFormatter } from "@/common/services/formatter";
import { getSlashedQuarterName } from "@/common/utils/trading";

type Price = {
  id: string;
  value: number;
  currency: string;
  quarter: string;
};

type Props = {
  className?: string;
  prices: Array<Price | null>;
  renderPrice?: (price: Props["prices"][number]) => React.ReactNode;
};

const PriceList = ({ renderPrice, prices, className = "" }: Props) => (
  <ul
    className={`border-can-silver-cloud bg-can-silver-gray text-can-midnight-steel @container grid grid-cols-4 rounded-2xl border px-2 text-xs ${className}`}
  >
    {prices.map((price, i) => (
      <li
        key={i}
        className="border-can-silver-cloud flex flex-col flex-wrap gap-y-2 border-r nth-4:border-none"
      >
        {renderPrice ? renderPrice(price) : <PriceItem price={price} />}
      </li>
    ))}
  </ul>
);

const PriceItem = ({ price }: { price: Props["prices"][number] }) =>
  price ? (
    <>
      <b className="px-1 pt-2 @sm:px-2">
        {price.value
          ? currencyFormatter(price.currency, {
              maximumFractionDigits: price.currency === "CZK" ? 0 : 2,
            })
              .format(price.value)
              .replace(price.currency, "")
          : null}
      </b>
      <span className="px-2 pb-2">{getSlashedQuarterName(price.quarter)}</span>
    </>
  ) : null;

export { PriceItem, PriceList };
export type { Price };
