import AngleLeftSvg from "@icons/angle-left.svg?react";
import CrossSvg from "@icons/cross.svg?react";

import { useSidebar } from "@/common/providers/sidebar-provider/sidebar-provider";
import { FullscreenModalLayout } from "@/routes/layout/layout.fullscreen";

import { Overview } from "./overview";
import { Failure, SubscriptionCancel } from "./subscription.cancel";
import { SubscriptionDetail } from "./subscription.detail";
import { SubscriptionOverview } from "./subscription.overview";

const Subscriptions = () => {
  const sidebar = useSidebar();
  let Jsx: Record<"component" | "button", React.ReactNode> = {
    button: null,
    component: null,
  };

  if (sidebar.state.screen === "subscriptions") {
    Jsx = {
      component: <Overview />,
      button: <CrossSvg />,
    };
  } else if (sidebar.state.screen === "subscriptions-overview") {
    Jsx = {
      component: <SubscriptionOverview />,
      button: <CrossSvg />,
    };
  } else if (sidebar.state.screen === "subscriptions-detail") {
    Jsx = {
      component: <SubscriptionDetail />,
      button: <AngleLeftSvg />,
    };
  } else if (sidebar.state.screen === "subscriptions-cancel") {
    Jsx = {
      component: <SubscriptionCancel />,
      button: <AngleLeftSvg />,
    };
  } else if (sidebar.state.screen === "subscriptions-cancel-failure") {
    Jsx = {
      component: <Failure />,
      button: <CrossSvg />,
    };
  }

  return (
    <FullscreenModalLayout
      headerButton={
        <button onClick={sidebar.actions.executeCloseAction}>
          {Jsx.button}
        </button>
      }
    >
      {Jsx.component}
    </FullscreenModalLayout>
  );
};

export { Subscriptions };
