import { queryOptions } from "@tanstack/react-query";

import { oneHour } from "@/common/providers/query-provider";
import { getHTML } from "@/common/utils";
import { getReport } from "@/generated/api/news";

const reportQuery = (id: number) =>
  queryOptions({
    queryKey: ["report", id],
    staleTime: oneHour,
    queryFn: async ({ signal }) => {
      const report = await getReport(id, { signal });

      for (const commodity of report.commodities) {
        const summaryHTML = getHTML(commodity.summary);
        const recommendationTextHTML = getHTML(commodity.recommendationText);
        commodity.summary = summaryHTML.body.innerHTML;
        commodity.recommendationText = recommendationTextHTML.body.innerHTML;
      }

      return report;
    },
  });

export { reportQuery };
