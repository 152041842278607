import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { RouteObject } from "react-router-dom";

import { ErrorPage } from "@/common/components/error-page/error-page";
import { Failed } from "@/common/components/info/info";

import * as Report from "./report/report";
import { path as reportsPath, Reports, ReportsParams } from "./reports/reports";
import {
  path as stockExchangePath,
  StockExchange,
  StockExchangeParams,
} from "./stock-exchange/stock-exchange";

const routes = (): RouteObject[] => [
  {
    path: reportsPath,
    element: (
      <ReportsParams>
        <Reports />
      </ReportsParams>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: Report.path,
        element: (
          <ErrorBoundary
            fallbackRender={({ error }) => <Failed error={error} />}
          >
            <Suspense fallback={<Report.Skeleton />}>
              <Report.Report mode="full" />
            </Suspense>
          </ErrorBoundary>
        ),
      },
    ],
  },
  {
    path: stockExchangePath,
    element: (
      <StockExchangeParams>
        <StockExchange />
      </StockExchangeParams>
    ),
  },
];

export { routes };
