import { Trans } from "@lingui/react/macro";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

import { Button } from "@/common/components/button/button";
import {
  CompanyForm,
  Fields,
  getInitialValues,
} from "@/common/components/company-form/company-form";
import { Spacer } from "@/common/components/spacer/spacer";
import { useUpdateCompanyMutation } from "@/common/components/user-settings/components/company-form/company-form.api";
import { splitCallingCodeAndCountry } from "@/common/utils/phone";

import { companyQuery } from "../company.api";
import { useCompanyId } from "../use-company-id";

const Detail = () => {
  const queryClient = useQueryClient();
  const id = useCompanyId();
  const company = useSuspenseQuery(companyQuery({ id: parseInt(id) }));
  const updateCompany = useUpdateCompanyMutation();

  const handleSubmit = (data: Fields) => {
    updateCompany.mutate(
      {
        id: parseInt(id),
        address: data.address,
        vat: data.vat,
        companyForm: data.legalForm,
        contactName: data.contact.name,
        contactEmail: data.contact.email,
        contactPhone: {
          phoneNumber: data.contact.phone.phoneNumber,
          countryCode: splitCallingCodeAndCountry(
            data.contact.phone.countryCode,
          ).at(0)!,
        },
        contactPosition: data.contact.role,
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries();
        },
      },
    );
  };

  return (
    <details>
      <summary>
        <h2 className="inline text-lg font-bold">
          <Trans>Informace</Trans>
        </h2>
      </summary>
      <Spacer className="h-6" />
      <CompanyForm
        onSubmit={handleSubmit}
        disabledFields={[]}
        initialValues={getInitialValues(company.data)}
      />
      <Button.Container>
        <Button
          disabled={updateCompany.status === "pending"}
          type="submit"
          variant="primary"
          form="company-form"
        >
          <Trans>Uložit</Trans>
        </Button>
      </Button.Container>
    </details>
  );
};

export { Detail };
