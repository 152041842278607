import { useSyncExternalStore } from "react";

const getSnapshot = (query: string) => {
  return window.matchMedia(query).matches;
};

const subscribe = (callback: () => void) => {
  window.addEventListener("resize", callback);

  return () => {
    window.removeEventListener("resize", callback);
  };
};

const useMediaQuery = (query: string) => {
  const matches = useSyncExternalStore(subscribe, () => getSnapshot(query));

  return matches;
};

const useIsMobile = () => {
  // eslint-disable-next-line lingui/no-unlocalized-strings
  return useMediaQuery("(max-width: 639px)");
};

export { useIsMobile, useMediaQuery };
