import AdminUser from "@icons/admin-user.svg?react";
import ArrowAltToRight from "@icons/arrow-alt-to-right.svg?react";
import { Trans } from "@lingui/react/macro";
import { useFlag } from "@unleash/proxy-client-react";
import { Link, NavLink } from "react-router-dom";

import { AdminGuard } from "@/common/acl/guard/guard";
import { Logo } from "@/common/components/logo/logo";
import { useFirstCompany } from "@/common/hooks/use-first-company";
import { useToggle } from "@/common/hooks/use-toggle";
import { useFeatureProvider } from "@/common/providers/feature-provider";

import { getClassName, NavBar } from "../nav-bar/nav-bar";
import { NavBar as OldNavBar } from "../nav-bar/nav-bar.old";
import { Spacer } from "../spacer/spacer";

const SideBar = () => {
  const expanded = useToggle();
  const company = useFirstCompany();
  const hasPackages = useFlag("packages");
  const features = useFeatureProvider();

  return (
    <div
      className={`fixed top-0 hidden h-screen ${expanded.on ? "z-100 w-[249px] [&>a]:-ml-[177px]" : "w-[72px]"} flex-col items-center sm:flex`}
    >
      <Logo />
      <div className={`bg-can-forest-teal flex w-full grow flex-col`}>
        <Spacer className="h-0.5 w-full bg-white" />
        <Spacer className="h-8" />
        {hasPackages ? (
          <NavBar
            plan={{
              features,
              planId: company?.plan.planId ?? Object.values(features)[0].planId,
              type: company?.plan.type ?? "BASIC",
            }}
            renderItem={
              expanded.on
                ? (link, Wrapper) => (
                    <NavLink
                      key={link.to}
                      className={({ isActive }) =>
                        `relative justify-start! self-start ${itemClassName} [&_svg]:shrink-0 ${getClassName({ isActive })}`
                      }
                      to={link.to}
                    >
                      <Wrapper>{link.icon}</Wrapper>
                      {link.label}
                    </NavLink>
                  )
                : undefined
            }
          />
        ) : (
          <OldNavBar
            renderItem={
              expanded.on
                ? (link) => (
                    <NavLink
                      className={({ isActive }) =>
                        `justify-start! self-start ${itemClassName} [&_svg]:shrink-0 ${getClassName({ isActive })}`
                      }
                      to={link.to}
                    >
                      {link.icon}
                      {link.label}
                    </NavLink>
                  )
                : undefined
            }
          />
        )}
        <AdminGuard>
          <Link
            to="/admin"
            target="_blank"
            className={`${itemClassName} ${getClassName({ isActive: false })} ${expanded.on ? "justify-start!" : ""}`}
          >
            <AdminUser />
          </Link>
        </AdminGuard>
        <button
          onClick={expanded.toggle}
          className={`mb-8 w-full ${itemClassName} self-center font-bold text-[#85C4B3]`}
        >
          <ArrowAltToRight className={expanded.on ? "rotate-180" : ""} />
          {expanded.on ? (
            <span>
              <Trans>Zobrazit jen ikony</Trans>
            </span>
          ) : null}
        </button>
      </div>
    </div>
  );
};

// eslint-disable-next-line lingui/no-unlocalized-strings
const itemClassName = "gap-4 px-6 flex";

export { SideBar };
