import AngleLeftSvg from "@icons/angle-left.svg?react";
import CopySvg from "@icons/copy.svg?react";
import DocumentSvg from "@icons/document.svg?react";
import DownloadSvg from "@icons/download.svg?react";
import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import invariant from "tiny-invariant";

import { useClipboard } from "@/common/hooks/use-clipboard";
import { useSidebar } from "@/common/providers/sidebar-provider/sidebar-provider";
import { currencyFormatter, getPrice } from "@/common/services/formatter";
import { getDateFromISOString } from "@/common/utils/date";
import { getLocalizedPaymentStatus } from "@/common/utils/payment-status";
import { getLocalizedPeriods } from "@/common/utils/period";
import { getPayment } from "@/generated/api/users";
import { FullscreenModalLayout } from "@/routes/layout/layout.fullscreen";
import { useDownloadInvoiceMutation } from "@/routes/shared/api/get-invoice";

import { PropertiesList } from "../properties-list/properties-list";
import { Spacer } from "../spacer/spacer";
import { useQRCode } from "./use-qr-code";

const PaymentDetail = () => {
  const sidebar = useSidebar();
  invariant(sidebar.state.screen === "payment-detail");
  const { state } = sidebar;
  const payment = useSuspenseQuery(paymentDetailQuery(state.paymentId));
  const downloadInvoice = useDownloadInvoiceMutation();
  const handleDownloadInvoice = (id: number) => {
    downloadInvoice.mutate(id);
  };
  const copyToClipboard = useClipboard();

  return (
    <FullscreenModalLayout
      headerButton={
        <button onClick={sidebar.actions.executeCloseAction}>
          <AngleLeftSvg />
        </button>
      }
    >
      <FullscreenModalLayout.Title>
        <Trans>Detail platby</Trans>
      </FullscreenModalLayout.Title>
      <PropertiesList
        properties={[
          {
            label: <Trans>Datum platby</Trans>,
            value: payment.data.createdAt,
          },
          {
            label: <Trans>Způsob platby</Trans>,
            value: payment.data.paymentType,
          },
          {
            label: <Trans>Období</Trans>,
            value: payment.data.period,
          },
          {
            label: <Trans>Částka</Trans>,
            value: payment.data.price,
          },
          {
            label: <Trans>Stav</Trans>,
            value: payment.data.status,
          },
          {
            label: <Trans>Fakturační adresa</Trans>,
            value: payment.data.billing.address,
          },
          {
            label: <Trans>Fakturační e-mail</Trans>,
            value: payment.data.billing.email,
          },
        ]}
      />
      <Spacer className="h-4" />
      {payment.data.details ? (
        <>
          <h2 className="text-md font-bold text-can-forest-teal">
            <Trans>Platební údaje</Trans>
          </h2>
          <Spacer className="h-2" />
          <PropertiesList
            properties={[
              {
                label: t`Majitel účtu`,
                // eslint-disable-next-line lingui/no-unlocalized-strings
                value: "CleverFarm, a.s.",
              },
              {
                label: t`Banka`,
                // eslint-disable-next-line lingui/no-unlocalized-strings
                value: "Komerční banka, a.s.",
              },
              {
                label: t`IBAN`,
                value: (
                  <button
                    title={t`Kopírovat`}
                    onClick={() =>
                      copyToClipboard.mutate(payment.data.details!.iban)
                    }
                    className="flex gap-x-3"
                  >
                    <span>{payment.data.details.iban}</span>
                    <CopySvg />
                  </button>
                ),
              },
              {
                label: t`SWIFT`,
                value: (
                  <button
                    title={t`Kopírovat`}
                    onClick={() =>
                      copyToClipboard.mutate(payment.data.details!.swift)
                    }
                    className="flex gap-x-3"
                  >
                    <span>{payment.data.details.swift}</span>
                    <CopySvg />
                  </button>
                ),
              },
              {
                label: t`Bankovní účet`,
                value: (
                  <button
                    title={t`Kopírovat`}
                    onClick={() =>
                      copyToClipboard.mutate(payment.data.details!.account)
                    }
                    className="flex gap-x-3"
                  >
                    <span>{payment.data.details.account}</span>
                    <CopySvg />
                  </button>
                ),
              },
              {
                label: t`Variabilní symbol`,
                value: (
                  <button
                    title={t`Kopírovat`}
                    onClick={() =>
                      copyToClipboard.mutate(
                        payment.data.details!.variableSymbol,
                      )
                    }
                    className="flex gap-x-3"
                  >
                    <span>{payment.data.details.variableSymbol}</span>
                    <CopySvg />
                  </button>
                ),
              },
              {
                label: <Trans>Částka</Trans>,
                value: (
                  <button
                    title={t`Kopírovat`}
                    onClick={() => copyToClipboard.mutate(payment.data.price)}
                    className="flex gap-x-3"
                  >
                    <span>{payment.data.price}</span>
                    <CopySvg />
                  </button>
                ),
              },
            ]}
          />
          <Spacer className="h-4" />
        </>
      ) : null}
      <div className="flex flex-col gap-y-4">
        {payment.data.invoices.map((invoice) => (
          <>
            <button
              onClick={() => handleDownloadInvoice(invoice.id)}
              className="flex items-center gap-2 rounded-2xl bg-can-silver-gray p-3 text-left font-bold text-can-forest-teal"
              key={invoice.id}
            >
              <DocumentSvg className="h-4 w-4 shrink-0" />
              {invoice.type === "PAYMENT_REQUEST" ? (
                <Trans>Výzva k úhradě</Trans>
              ) : (
                <Trans>Faktura</Trans>
              )}
              <DownloadSvg className="ml-auto shrink-0" />
            </button>
            {invoice.type === "PAYMENT_REQUEST" && payment.data.details ? (
              <QRCode invoiceId={invoice.id} />
            ) : null}
          </>
        ))}
      </div>
    </FullscreenModalLayout>
  );
};

const QRCode = ({ invoiceId }: { invoiceId: number }) => {
  const qrCodeRef = useQRCode(invoiceId);

  return <img className="mx-auto h-[250px] w-[250px]" ref={qrCodeRef} />;
};

const paymentDetailQuery = (paymentId: number) =>
  queryOptions({
    queryKey: ["payment", "detail", paymentId],
    queryFn: ({ signal }) => getPayment(paymentId, { signal }),
    select: (data) => {
      return {
        billing: {
          email: data.billing.email,
          address: data.billing.address,
        },
        paymentType: data.gateway === "BANK" ? t`Bankovní převod` : t`Karta`,
        status: getLocalizedPaymentStatus()[data.status],
        createdAt: getDateFromISOString(data.createdAt)?.toLocaleString(
          DateTime.DATETIME_SHORT,
        ),
        period: data.subscription.planPeriod
          ? getLocalizedPeriods()[data.subscription.planPeriod]
          : "",
        price: currencyFormatter(data.currency.type).format(
          getPrice(data.amountTotal, data.currency.precision),
        ),
        invoices: data.invoice,
        details: data.details?.bank,
      };
    },
  });

export { PaymentDetail };
