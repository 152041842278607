import Trash from "@icons/trash.svg?url";
import React from "react";

type Props = React.PropsWithChildren<
  {
    className?: string;
    disabled?: boolean;
    variant: "primary" | "secondary";
  } & React.ButtonHTMLAttributes<HTMLButtonElement>
>;

type ButtonType = typeof Button & {
  Container: typeof Container;
  Delete: typeof Delete;
};

const Button = React.forwardRef<HTMLButtonElement, Props>(
  ({ className = "", variant, children, disabled, ...rest }: Props, ref) => {
    return (
      <button
        ref={ref}
        className={`${variants[variant]} ${className} ${disabled ? "animate-can-loading-gradient cursor-wait bg-[length:400%_400%]" : ""}`}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  },
);
const variants = {
  primary:
    // eslint-disable-next-line lingui/no-unlocalized-strings
    "rounded-2xl text-center bg-linear-to-r from-can-tranquil-azure to-can-mystic-aqua px-4 py-3 font-bold text-white shadow-can-light-box",
  secondary:
    // eslint-disable-next-line lingui/no-unlocalized-strings
    "rounded-2xl bg-white border-can-forest-teal border text-center px-4 py-3 font-bold text-can-forest-teal",
};

// helps keeping the buttons sticky to the bottom
const Container = ({
  children,
  className = "",
}: React.PropsWithChildren<{ className?: string }>) => (
  <div
    className={`sticky bottom-0 -mr-4 -ml-4 flex grow flex-col justify-end gap-y-4 bg-white px-4 pt-4 pb-8 ${className}`}
  >
    {children}
  </div>
);

const Delete = ({
  children,
  ...rest
}: React.PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement>>) => {
  return (
    <button
      style={{
        background: `url(${Trash}) left 16px center no-repeat`,
      }}
      className="border-can-forest-teal text-can-forest-teal flex items-center justify-center rounded-2xl border px-4 py-3 font-bold"
      {...rest}
    >
      {children}
    </button>
  );
};

// eslint-disable-next-line lingui/no-unlocalized-strings
Button.displayName = "Button";
(Button as ButtonType).Container = Container;
(Button as ButtonType).Delete = Delete;
const primary = variants.primary;
const secondary = variants.secondary;
const ExportedButton = Button as ButtonType;

export { ExportedButton as Button, primary, secondary };
