import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { Link } from "react-router-dom";

import { BackgroundContainer } from "@/common/components/background-container/background-container";
import { primary } from "@/common/components/button/button";
import { Metadata } from "@/common/components/metadata/metadata";

import UserSlash from "./assets/user-slash.svg?react";

const BlockedUser = () => {
  return (
    <BackgroundContainer>
      <BackgroundContainer.Box>
        <Metadata title={t`Profil je nedostupný`} />
        <div className="my-6 flex h-full flex-col gap-6 bg-white sm:h-auto">
          <h1 className="text-center text-2xl font-black text-can-forest-teal">
            <Trans>Profil je nedostupný</Trans>
          </h1>
          <div className="mx-auto rounded-full bg-can-silver-gray p-11">
            <UserSlash />
          </div>
          <p>
            <Trans>
              Váš profil je bohužel z nějakého důvodu nedostupný. Pro více
              informací nás prosím kontaktujte na adrese:
            </Trans>
          </p>
          <a
            className="rounded-2xl bg-can-silver-gray px-4 py-3 text-center"
            href="mailto:support@cleverfarm.ag"
          >
            {/* eslint-disable-next-line lingui/no-unlocalized-strings */}
            <b>support@cleverfarm.ag</b>
          </a>
          <Link to="/logout" className={primary}>
            <Trans>Přejít na přihlášení</Trans>
          </Link>
        </div>
      </BackgroundContainer.Box>
    </BackgroundContainer>
  );
};

export { BlockedUser };
