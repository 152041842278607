import Unsubscribed1Svg from "@icons/unsubscribed1.svg?react";
import Unsubscribed2Svg from "@icons/unsubscribed2.svg?react";
import { Trans } from "@lingui/react/macro";
import random from "lodash-es/random";
import React from "react";

import { Button } from "@/common/components/button/button";
import { Success } from "@/routes/shared/routes/success/success";

import { useGoBackToApp } from "../hooks/use-go-back-to-app";

const Unsubscribed = () => {
  const { isUpdatingToken, handleRedirectClick } = useGoBackToApp();

  return (
    <div className="mx-auto mt-[120px] flex flex-col gap-y-4 text-center">
      <Success.Title>
        <Trans>Mrzí nás to</Trans>
        <>&hellip;</>
      </Success.Title>
      <div className="self-center rounded-full bg-can-silver-gray p-11">
        <RandomImage />
      </div>
      <p>
        <Trans>
          Je nám líto, že naše aplikace nenaplnila vaše očekávání. Věříme, že
          nám dáte šanci příště!
        </Trans>
      </p>
      <p>
        <Trans>
          Vaše předplatné jsme úspěšně zrušili. Změny se projeví, jakmile skončí
          vaše aktuální předplatné.
        </Trans>
      </p>
      <Success.Content>
        <Button
          disabled={isUpdatingToken}
          onClick={handleRedirectClick}
          variant="primary"
          className="mt-8 w-[342px]"
        >
          <Trans>Přejít do aplikace</Trans>
        </Button>
      </Success.Content>
    </div>
  );
};

const RandomImage = () => {
  const images = [Unsubscribed1Svg, Unsubscribed2Svg];
  const result = React.useRef(random(0, 1, false));
  const Image = images[result.current];

  return <Image />;
};

export { Unsubscribed };
