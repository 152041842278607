import { queryOptions } from "@tanstack/react-query";
import { DateTime } from "luxon";

import { itemsPerPage } from "@/common/components/pagination/pagination";
import {
  CompanyToPlanSource,
  getCompanies,
  GetCompaniesPlan,
  getCountries,
  PlanToType,
} from "@/generated/api/users";

type Company = {
  id: number;
  number: string;
  country: string;
  name: string;
  plan: {
    source: CompanyToPlanSource;
    type?: PlanToType;
    expiresAt: DateTime | null;
  };
};

const companiesQuery = ({
  country,
  page,
  plan,
  planSource,
  planValid,
  search,
}: {
  country: string;
  page: string;
  plan: GetCompaniesPlan;
  planSource: CompanyToPlanSource;
  planValid?: boolean;
  search: string;
}) =>
  queryOptions({
    queryKey: ["companies", search, plan, planSource, country, planValid, page],
    queryFn: ({ signal }) =>
      getCompanies(
        {
          size: itemsPerPage.oneHundred.toString(),
          page,
          country,
          planSource,
          // @ts-expect-error types are a bit off
          planValid: planValid ?? "",
          plan,
          search,
          sortCol: "NAME",
        },
        { signal },
      ),
    staleTime: Infinity,
  });

const authorizedCountriesQuery = () =>
  queryOptions({
    queryKey: ["authorized-countries"],
    queryFn: ({ signal }) => getCountries({ signal }),
    select: (countries) => countries.filter((country) => country.isAuthorized),
    staleTime: Infinity,
  });

export { authorizedCountriesQuery, companiesQuery };
export type { Company };
