import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { PermissionGuard, useHasPermission } from "@/common/acl/guard/guard";
import { Dialog } from "@/common/components/dialog/dialog";

import { useConfirmedAction, User } from "../components/user/user";
import {
  useDeleteCompanyRelationMutation,
  useResetUserMutation,
} from "../components/user/user.api";
import { userQuery } from "./pending-user.api";

const PendingUser = () => {
  const { id } = useParams();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(id, "user id is missing");
  const user = useSuspenseQuery(userQuery({ id: parseInt(id) }));

  return (
    <User
      user={{
        metadata: user.data.userMetadata,
        email: user.data.userEmail!,
        ssoUserId: user.data.userId,
        name: user.data.userName!,
        phone: user.data.userMetadata.common.phone
          ? {
              countryCode: user.data.userMetadata.common.phone.countryCode!,
              phoneNumber: user.data.userMetadata.common.phone.phoneNumber!,
            }
          : undefined,
      }}
    >
      {(_user) => <DangerZone company={user.data.companyName!} user={_user} />}
    </User>
  );
};

const DangerZone = ({ company, user }: { company: string; user: User }) => {
  const { id } = useParams();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(id, "user id is missing");
  const canDeleteCompanyRelation = useHasPermission("users:company:delete");
  const canResetUser = useHasPermission("users:user:reset");
  const deleteCompanyRelation = useDeleteCompanyRelationMutation();
  const resetUser = useResetUserMutation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { runWhenConfirmed, dialog, setDialog, rejectRef, resolveRef } =
    useConfirmedAction();

  const handleResetUser = () => {
    resetUser.mutate(
      {
        ssoUserId: user.ssoUserId,
      },
      {
        onSuccess: async () => {
          setDialog(undefined);
          await navigate("..");
          void queryClient.invalidateQueries();
        },
        onError: () => {
          alert(t`Uživatele se nepodařilo resetovat`);
        },
      },
    );
  };

  const handleDeleteCompanyRelation = () => {
    deleteCompanyRelation.mutate(
      { userId: parseInt(id) },
      {
        onSuccess: async () => {
          setDialog(undefined);
          await navigate("..");
          void queryClient.invalidateQueries();
        },
        onError: () => {
          alert(t`Uživatele se nepodařilo ze společnosti smazat`);
        },
      },
    );
  };

  return canDeleteCompanyRelation || canResetUser ? (
    <>
      <details className="text-red-500">
        <summary>
          <Trans>Nebezpečná zóna</Trans>
        </summary>
        <div className="mt-4 flex flex-col gap-y-4">
          <PermissionGuard permission="users:company:delete">
            <button
              onClick={runWhenConfirmed(
                <Trans>
                  Tímto zásahem smažete vazbu mezi uživatelem {user.name} a
                  společností {company}.<br />
                  Další společnosti, ke kterým uživatel může být přiřazen,
                  nebudou tímto zásahem dotčeny.
                </Trans>,
                handleDeleteCompanyRelation,
              )}
              className="rounded-lg border border-red-500 p-2 hover:bg-red-500 hover:text-white"
            >
              <Trans>Odebrat uživatele ze společnosti</Trans>
            </button>
          </PermissionGuard>
          <PermissionGuard permission="users:user:reset">
            <button
              onClick={runWhenConfirmed(
                <Trans>
                  Tímto zásahem smažete vazbu mezi uživatelem {user.name} a{" "}
                  <b>všemi společnostmi</b>, ke kterým je přiřazen.
                  <br />
                  Rovněž odstraníte všechna metadata, která o uživateli vedeme.
                </Trans>,
                handleResetUser,
              )}
              className="rounded-lg border border-red-500 p-2 hover:bg-red-500 hover:text-white"
            >
              <Trans>Odebrat uživatele ze všech společností</Trans>
            </button>
          </PermissionGuard>
        </div>
      </details>
      {dialog ? (
        <Dialog>
          <Dialog.Title>
            <Trans>Opravdu chcete provést akci?</Trans>
          </Dialog.Title>
          <p className="text-red-500">{dialog}</p>
          <Dialog.Actions>
            <Dialog.Confirm
              disabled={
                deleteCompanyRelation.status === "pending" ||
                resetUser.status === "pending"
              }
              onClick={resolveRef.current}
            >
              <Trans>Ano</Trans>
            </Dialog.Confirm>
            <Dialog.Cancel onClick={rejectRef.current}>
              <Trans>Ne</Trans>
            </Dialog.Cancel>
          </Dialog.Actions>
        </Dialog>
      ) : null}
    </>
  ) : null;
};

export { PendingUser };
