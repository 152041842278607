import { queryOptions, useMutation } from "@tanstack/react-query";
import sortBy from "lodash-es/sortBy";
import { z } from "zod";

import { graphqlClient } from "@/api/graphql";
import { itemsPerPage } from "@/common/components/pagination/pagination";
import { entitySchema } from "@/common/schema/entity";
import { currencyFormatter } from "@/common/services/formatter";
import { graphql } from "@/generated/digitalnisklady.cz";
import { OrderInput } from "@/generated/digitalnisklady.cz/graphql";

// eslint-disable-next-line lingui/no-unlocalized-strings
const editOrderDocument = graphql(`
  mutation EditOrder($id: ID!, $order: OrderInput!) {
    editOrder(id: $id, order: $order) {
      id
    }
  }
`);

const useEditOrderMutation = () =>
  useMutation({
    mutationKey: ["edit-order"],
    mutationFn: ({ id, order }: { id: string; order: OrderInput }) =>
      graphqlClient.request(editOrderDocument, {
        id,
        order,
      }),
  });

// eslint-disable-next-line lingui/no-unlocalized-strings
const offerOrdersDocument = graphql(`
  query OrderList($id: ID!) {
    offer(id: $id) {
      id
      crop {
        id
        name
      }
      storage {
        id
        label
        company {
          id
          name
        }
      }
      orders {
        edges {
          node {
            id
            note
            amount
            currency
            quarter {
              id
              name
            }
            price
            lowPrice
            currency
            customTransport
            transportNote
          }
        }
      }
    }
  }
`);

const orderDetailSchema = z.object({
  company: entitySchema,
  storage: entitySchema,
  quarter: entitySchema,
  order: z.object({
    customTransport: z.boolean(),
    customTransportNote: z
      .string()
      .nullish()
      .transform((v) => v ?? null),
    id: z.string(),
    totalPrice: z.string(),
    unitPrice: z.number(),
    unitLowPrice: z
      .number()
      .nullish()
      .transform((v) => v ?? null),
    amount: z.number(),
    note: z.string().optional(),
    currency: z.enum(["CZK", "EUR"]),
  }),
  crop: z.object({
    name: z.string(),
  }),
});

const orderDetailQuery = ({
  offerId,
  orderId,
}: {
  offerId: string;
  orderId: string;
}) =>
  queryOptions({
    queryKey: ["offer", offerId, "orders"],
    queryFn: () =>
      graphqlClient.request(offerOrdersDocument, {
        id: offerId,
      }),
    select: (data) => {
      const result: Record<string, unknown> = {};
      const order = data.offer?.orders?.edges?.find(
        (edge) => edge?.node?.id === orderId,
      );
      result.company = data.offer?.storage?.company;
      result.storage = {
        id: data.offer?.storage?.id,
        name: data.offer?.storage?.label,
      };
      result.quarter = order?.node?.quarter;
      result.crop = data.offer?.crop;
      result.order = {
        amount: order?.node?.amount,
        currency: order?.node?.currency,
        note: order?.node?.note,
        id: order?.node?.id,
        // @ts-expect-error graphql types
        totalPrice: currencyFormatter(order?.node?.currency).format(
          // @ts-expect-error graphql types
          order.node?.amount * order?.node?.price,
        ),
        unitPrice: order?.node?.price,
        unitLowPrice: order?.node?.lowPrice,
        customTransport: order?.node?.customTransport,
        customTransportNote: order?.node?.transportNote,
      };

      return orderDetailSchema.parse(result);
    },
  });

// eslint-disable-next-line lingui/no-unlocalized-strings
const ordersDocument = graphql(`
  query Orders($archived: Boolean) {
    user {
      companies {
        edges {
          node {
            storages(archived: $archived) {
              edges {
                node {
                  label
                  offers {
                    edges {
                      node {
                        id
                        crop {
                          id
                          name
                        }
                        orders {
                          edges {
                            node {
                              id
                              active
                              amount
                              currency
                              price
                              quarter {
                                id
                                name
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

const ordersQuery = () =>
  queryOptions({
    queryKey: ["orders"],
    queryFn: () =>
      graphqlClient.request(ordersDocument, {
        archived: false,
      }),
  });

const cropsInOrdersQuery = () =>
  queryOptions({
    ...ordersQuery(),
    select: (data) => {
      const crops: { id: string; name: string }[] = [];
      data.user?.companies?.edges?.forEach((edge) =>
        edge?.node?.storages?.edges?.forEach((storage) =>
          storage?.node?.offers?.edges?.forEach((offer) => {
            if (crops.find((item) => item.id === offer?.node?.crop?.id)) {
              return;
            }
            // @ts-expect-error graphql types are crazy
            crops.push(offer?.node?.crop);
          }),
        ),
      );

      const sortedCrops = sortBy(crops, (c) => c.name);
      return z.array(entitySchema).parse(sortedCrops);
    },
  });

const orderSchema = z.object({
  storage: z.object({
    name: z.string(),
  }),
  crop: entitySchema,
  order: z.object({
    id: z.string(),
    amount: z.number(),
    quarter: entitySchema,
    currency: z.enum(["CZK", "EUR"]),
    price: z.number(),
  }),
  offer: z.object({
    id: z.string(),
  }),
});

const orderListQuery = ({
  cropIds,
  page,
}: {
  cropIds: string[];
  page: number;
}) =>
  queryOptions({
    ...ordersQuery(),
    queryKey: [
      ...ordersQuery().queryKey,
      cropIds.toSorted().join(","),
      page.toString(),
    ],
    select: (data) => {
      const arraySchema = z.array(orderSchema);
      const orders: z.infer<typeof arraySchema> = [];
      data.user?.companies?.edges?.forEach((company) =>
        company?.node?.storages?.edges?.forEach((storage) =>
          storage?.node?.offers?.edges
            ?.filter((offer) =>
              cropIds.length === 0
                ? true
                : // @ts-expect-error insane graphql types
                  cropIds.includes(offer?.node?.crop?.id),
            )
            .forEach((offer) => {
              offer?.node?.orders?.edges
                ?.filter((order) => order?.node?.active)
                .forEach((order) => {
                  orders.push({
                    storage: {
                      // @ts-expect-error insane graphql types
                      name: storage.node?.label,
                    },
                    crop: {
                      // @ts-expect-error insane graphql types
                      id: offer.node?.crop?.id,
                      // @ts-expect-error insane graphql types
                      name: offer.node?.crop?.name,
                    },
                    order: {
                      // @ts-expect-error insane graphql types
                      id: order?.node?.id,
                      // @ts-expect-error insane graphql types
                      amount: order?.node?.amount,
                      // @ts-expect-error insane graphql types
                      quarter: order?.node?.quarter,
                      // @ts-expect-error insane graphql types
                      currency: order?.node?.currency,
                      // @ts-expect-error insane graphql types
                      price: order?.node?.price,
                    },
                    offer: {
                      // @ts-expect-error insane graphql types
                      id: offer.node?.id,
                    },
                  });
                });
            }),
        ),
      );

      const start = itemsPerPage.ten * (page - 1);
      const end = start + itemsPerPage.ten;

      return {
        orders: arraySchema.parse(orders).slice(start, end),
        total: orders.length,
      };
    },
  });

export {
  cropsInOrdersQuery,
  orderDetailQuery,
  orderListQuery,
  ordersQuery,
  useEditOrderMutation,
};
