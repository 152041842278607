import { useAuth0 } from "@auth0/auth0-react";
import { Trans } from "@lingui/react/macro";

import { BackgroundContainer } from "@/common/components/background-container/background-container";
import { Button } from "@/common/components/button/button";

const Anonymous = () => {
  const auth0 = useAuth0();

  return (
    <BackgroundContainer>
      <div className="flex gap-4">
        <Button
          variant="secondary"
          onClick={() =>
            auth0.loginWithRedirect({
              authorizationParams: {
                screen_hint: "signup",
              },
              appState: {
                returnTo: window.location.href,
              },
            })
          }
        >
          <Trans>Registrovat se</Trans>
        </Button>
        <Button
          variant="primary"
          onClick={() =>
            auth0.loginWithRedirect({
              appState: {
                returnTo: window.location.href,
              },
            })
          }
        >
          <Trans>Přihlásit se</Trans>
        </Button>
      </div>
    </BackgroundContainer>
  );
};

export { Anonymous };
