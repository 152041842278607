import { t } from "@lingui/core/macro";
import { queryOptions } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { z } from "zod";

import { oneHour } from "@/common/providers/query-provider";
import {
  deleteReport,
  getReports,
  patchReport,
  ReportListReportTo,
  ReportPatchTo,
} from "@/generated/api/news";

type Params = z.infer<ReturnType<typeof getReportsQueryParamsSchema>>;

const getReportsQueryParamsSchema = () =>
  z
    .object({
      enforcePlans: z.coerce
        .string()
        .nullish()
        .transform((v) => v ?? undefined),
      "sort-order": z.enum(["ASC", "DESC"]),
      "date-from": z
        .string()
        .regex(/\d{4}-\d{2}-\d{2}/)
        .nullish()
        .transform((v) => v ?? undefined),
      "date-to": z
        .string()
        .regex(/\d{4}-\d{2}-\d{2}/)
        .refine((date) => DateTime.now() > DateTime.fromISO(date), {
          message: t`Datum ukončení musí nastat před dnešním dnem.`,
        }),
    })
    .refine(
      (data) => {
        if (!data["date-from"]) {
          return true;
        }
        return data["date-to"] > data["date-from"];
      },
      {
        message: t`Datum ukončení musí následovat po datu zahájení.`,
      },
    );

type ReportsQueryResult = Omit<ReportListReportTo, "id" | "language"> & {
  id: string;
};

const reportsQuery = (params: Params) =>
  queryOptions({
    staleTime: oneHour,
    queryKey: ["reports", params],
    queryFn: async ({ signal }) => {
      const safeParams = getReportsQueryParamsSchema().parse(params);

      return await getReports(safeParams, { signal });
    },
    select: (data) =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      data.reports.map(({ id, language, ...rest }) => ({
        ...rest,
        id: id.toString(),
      })),
  });

const reportsDateRangeQuery = (params: Params) =>
  queryOptions({
    // because we do not care about the reports data itself
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ["reports-date-range"],
    queryFn: async ({ signal }) => {
      const safeParams = getReportsQueryParamsSchema().parse(params);

      return await getReports(safeParams, { signal });
    },
    staleTime: Infinity,
    select: (data) => ({
      oldest: data.first ? DateTime.fromISO(data.first) : undefined,
      newest: data.last ? DateTime.fromISO(data.last) : undefined,
    }),
  });

const deleteReportMutation = () => {
  return {
    mutationFn: (id: number) => deleteReport(id),
  };
};

const changeReportStatusMutation = () => {
  return {
    mutationFn: ({ id, status }: { id: number } & ReportPatchTo) =>
      patchReport(id, { status }),
  };
};

export {
  changeReportStatusMutation,
  deleteReportMutation,
  getReportsQueryParamsSchema,
  reportsDateRangeQuery,
  reportsQuery,
};
export type { Params, ReportsQueryResult };
