import AngleLeft from "@icons/angle-left.svg?react";
import { msg, t } from "@lingui/core/macro";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/react/macro";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { Failed } from "@/common/components/info/info";
import { Metadata } from "@/common/components/metadata/metadata";
import { PageTitle } from "@/common/components/page-title/page-title";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { Spacer } from "@/common/components/spacer/spacer";
import { Spinner } from "@/common/components/spinner/spinner";

import { FullscreenModalLayout } from "../layout/layout.fullscreen";
import { offerQuery } from "../shared/api/get-offer";
import { useEditOffer } from "./api/edit-offer";
import { CropDetail } from "./components/crop-detail/crop-detail";

const path = ":id/offers/:offerId/edit" as const;

const OfferEdit = () => {
  const { _ } = useLingui();
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const { offerId } = useParams<"offerId">();
  invariant(offerId);
  const offer = useQuery(offerQuery({ id: offerId }));
  const editOffer = useEditOffer();

  const handleSubmit: React.ComponentProps<typeof CropDetail>["onSubmit"] = (
    tons,
  ) => {
    invariant(offer.data);
    editOffer.mutate(
      {
        id: offerId,
        offer: {
          yearHarvested: offer.data.harvestYear,
          crop: offer.data.crop.id,
          amount: tons,
          storage: offer.data.storage.id,
        },
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries();
          await navigate("..", {
            relative: "path",
          });
        },
      },
    );
  };

  return (
    <FullscreenModalLayout
      headerButton={
        <SearchParamsLink relative="path" to=".." replace>
          <AngleLeft />
        </SearchParamsLink>
      }
    >
      {offer.status === "error" ? <Failed error={offer.error} /> : null}
      {offer.status === "pending" ? (
        <div className="flex flex-1 items-center justify-center">
          <Spinner />
        </div>
      ) : null}
      {offer.status === "success" ? (
        <>
          <Metadata title={_(msg`Upravit množství`)} />
          <Spacer className="h-6" />
          <PageTitle>
            <Trans>Upravit množství</Trans>
          </PageTitle>
          <Spacer className="h-6" />
          {editOffer.error ? (
            <>
              <p role="alert" className="mt-4">
                <Trans>Změny se nepodařilo uložit.</Trans>
              </p>
              <Spacer className="h-6" />
            </>
          ) : null}
          <CropDetail
            onSubmit={handleSubmit}
            tons={offer.data.storage.offer.amount}
          >
            <ul className="flex flex-col">
              {[
                {
                  label: t`Adresa`,
                  value: offer.data.storage.address,
                },
                {
                  label: t`Komodita`,
                  value: offer.data.crop.name,
                },
                {
                  label: t`Rok sklizně`,
                  value: offer.data.harvestYear,
                },
                {
                  label: t`Farma`,
                  value: offer.data.storage.company.name,
                },
                {
                  label: t`Sklad`,
                  value: offer.data.storage.name,
                },
              ].map((row) => (
                <li
                  key={row.label}
                  className="flex flex-wrap justify-between border-b border-b-can-silver-cloud py-2 last-of-type:border-0"
                >
                  <span>{row.label}</span>
                  <b className="text-right">{row.value}</b>
                </li>
              ))}
            </ul>
          </CropDetail>
        </>
      ) : null}
    </FullscreenModalLayout>
  );
};

export { OfferEdit, path };
