import { useAuth0 } from "@auth0/auth0-react";
import UserPendingSvg from "@icons/user-pending.svg?react";
import { Trans } from "@lingui/react/macro";
import invariant from "tiny-invariant";

import { getMaybeUser } from "@/common/services/user";

const PendingUserBanner = () => {
  const isPending = useIsPendingUser();

  if (!isPending) {
    return null;
  }

  return (
    <div className="absolute left-0 right-0 top-[74px] flex items-center gap-4 bg-[#fff2db] px-6 py-3 sm:left-[72px] [&+.can-sm-layout>div]:mt-[140px] sm:[&+.can-sm-layout>div]:mt-[100px]">
      <UserPendingSvg className="shrink-0" />
      <p className="text-xs text-[#c28519]">
        <Trans>
          <strong>Ověřujeme váš profil.</strong> Některé funkce mohou být
          omezeny do doby, než váš profil plně ověříme. Pro více informací nás
          můžete kontaktovat na e-mailové adrese:{" "}
          <a className="text-[#C28519]" href="mailto:support@cleverfarm.ag">
            support@cleverfarm.ag
          </a>
          .
        </Trans>
      </p>
    </div>
  );
};

const useIsPendingUser = () => {
  const { user: _user } = useAuth0();
  invariant(_user);
  const user = getMaybeUser(_user);
  const firstCompany = user["can/app_metadata"]?.companies?.at(0);

  if (!firstCompany) {
    return false;
  }

  return firstCompany.approvalState === "WAITING";
};

export { PendingUserBanner };
