import React from "react";
import { useSearchParams } from "react-router-dom";

import { CommodityRatesToCommodity } from "@/generated/api/prices";

import { Filter, getFilters } from "./stock-exchange.utils";

type Chart = Lowercase<CommodityRatesToCommodity> | "currencyPair";

const useFilterClickHandler = (availableFilters: Array<Filter>) => {
  const [, setSearchParams] = useSearchParams();

  return React.useCallback(
    (chart: Chart, index: number) => {
      const filter = availableFilters[index];
      setSearchParams((cur) => {
        cur.set(chart, filter.value);

        return cur;
      });
    },
    [availableFilters, setSearchParams],
  );
};

const useActiveFilter = (chart: Chart) => {
  const filters = getFilters();
  const [searchParams] = useSearchParams();
  const value = searchParams.get(chart);

  return filters.find((f) => f.value === value) ?? filters[0];
};

const useFilters = ({
  commodityId,
}: {
  commodityId: CommodityRatesToCommodity;
}) => {
  const handleFilterClick = useFilterClickHandler(getFilters());
  const commodityIdParam = commodityId.toLowerCase() as Lowercase<
    typeof commodityId
  >;
  const activeFilter = useActiveFilter(commodityIdParam);

  return {
    onClick: (i: number) => handleFilterClick(commodityIdParam, i),
    activeFilter,
    filters: getFilters(),
  };
};

export { useActiveFilter, useFilterClickHandler, useFilters };
