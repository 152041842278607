import { User } from "@auth0/auth0-react";
import { z } from "zod";

import { logger } from "./logger";

type UserState = "complete" | "incomplete";

const getUserState = (user: User): UserState => {
  try {
    getUser(user);
    return "complete";
  } catch {
    return "incomplete";
  }
};

const approvalStatus = z.union([
  z.literal("WAITING"),
  z.literal("APPROVED"),
  z.literal("BLOCKED"),
]);

const role = z.union([z.literal("USER"), z.literal("STATUTORY")]);
const grants = [
  "COMPANY_DETAIL_EDIT",
  "CONTRACT_SIGN",
  "PAYMENT_CREATE",
] as const;

const phoneSchema = z.object({
  phoneNumber: z.string(),
  countryCode: z.string(),
});

const commonSchema = z.object({
  phone: phoneSchema,
});

const irishPhoneSchema = phoneSchema.extend({
  countryCode: z.literal("+353"),
});

const irishCommonSchema = z.object({
  phone: irishPhoneSchema,
});

const settingsSchema = z
  .object({
    language: z.string(),
    news: z
      .object({
        language: z.string(),
      })
      .or(z.undefined()),
  })
  .or(z.undefined());

const irishCANSchema = z.object({
  companies: z.null().or(
    z.array(
      z.object({
        companyNumber: z.string(),
        countryCode: z.string(),
      }),
    ),
  ),
  settings: settingsSchema,
});

const CANSchema = z.object({
  companies: z.array(
    z.object({
      companyNumber: z.string(),
      countryCode: z.string(),
    }),
  ),
  settings: settingsSchema,
});

const incompleteCANSchema = z.object({
  companies: z
    .array(
      z.object({
        companyNumber: z.string(),
        countryCode: z.string(),
      }),
    )
    .or(z.null()),
  settings: settingsSchema,
});

const userMetadataSchema = z.object({
  common: commonSchema,
  can: CANSchema,
});

const incompleteUserMetadataSchema = z.object({
  common: commonSchema,
  can: incompleteCANSchema,
});

const irishUserMetadataSchema = z.object({
  common: irishCommonSchema,
  can: irishCANSchema,
});

const planTypeSchema = z.union([
  z.literal("PLUS"),
  z.literal("BASIC"),
  z.literal("PREMIUM"),
]);

const planSchema = z.object({
  planId: z.number(),
  type: planTypeSchema,
});

const companySchema = z.object({
  companyId: z.number(),
  companyVat: z
    .string()
    .nullable()
    .transform((v) => v ?? undefined),
  countryCode: z
    .string()
    .nullable()
    .transform((v) => v ?? undefined),
  companyNumber: z
    .string()
    .nullable()
    .transform((v) => v ?? undefined),
  companyContactEmail: z
    .string()
    .nullable()
    .transform((v) => v ?? undefined),
  approvalState: approvalStatus,
  plan: planSchema,
  grants: z.array(z.enum(grants)),
  role,
  users: z.array(
    z.object({
      approvalState: approvalStatus,
      grants: z.array(z.enum(grants)),
      role,
      userEmail: z.string().email(),
      userId: z.string(),
    }),
  ),
});

const appMetadataSchema = z
  .object({
    events: z.object({
      // We effectively use this to tell whether the user has been reset. If they have been reset, `registeredAt` field has been reset as well
      // and thus the zod check will throw.
      registeredAt: z.number(),
    }),
    companies: z.array(companySchema).nullish(),
  })
  .nullish();

const incompleteAppMetadataSchema = z
  .object({
    events: z.object({
      // We effectively use this to tell whether the user has been reset. If they have been reset, `registeredAt` field has been reset as well
      // and thus the zod check will throw.
      registeredAt: z.number().nullish(),
    }),
    companies: z.array(companySchema).nullish(),
  })
  .nullish();

const irishAppMetadataSchema = z
  .object({
    events: z.object({
      // We effectively use this to tell whether the user has been reset. If they have been reset, `registeredAt` field has been reset as well
      // and thus the zod check will throw.
      registeredAt: z.number().nullish(),
    }),
    companies: z.array(companySchema).nullish(),
  })
  .nullish();

const userIdentitySchema = z.object({
  name: z.string(),
  email: z.string(),
});

const userSchema = z
  .object({
    user_metadata: userMetadataSchema,
    "can/app_metadata": appMetadataSchema,
  })
  .merge(userIdentitySchema);

const incompleteUserSchema = z
  .object({
    user_metadata: incompleteUserMetadataSchema,
    "can/app_metadata": incompleteAppMetadataSchema,
  })
  .deepPartial()
  .merge(userIdentitySchema);

const irishUserSchema = z
  .object({
    user_metadata: irishUserMetadataSchema,
    "can/app_metadata": irishAppMetadataSchema,
  })
  .merge(userIdentitySchema);

type UserSchema = z.infer<typeof userSchema | typeof irishUserSchema>;
type IncompleteUserSchema = z.infer<typeof incompleteUserSchema>;
type ApprovalStatus = z.infer<typeof approvalStatus>;
type Role = z.infer<typeof role>;
type PlanSchema = z.infer<typeof planSchema>;
type Grant = (typeof grants)[number];
type Company = Exclude<
  Exclude<UserSchema["can/app_metadata"], undefined | null>["companies"],
  undefined | null
>[number];

const getUser = (user: User): UserSchema => {
  if (isIrishUser(user)) {
    return irishUserSchema.parse(user);
  }
  return userSchema.parse(user);
};

const getMaybeUser = (user: User) => {
  if (isIrishUser(user)) {
    try {
      return irishUserSchema.parse(user);
    } catch (e) {
      logger.setContext(user);
      throw e;
    }
  }

  return incompleteUserSchema.parse(user);
};

/**
 * Irish users are the only ones in the world that are not required to be member of any company.
 */
const isIrishUser = (user: User) =>
  z
    .object({ user_metadata: irishUserMetadataSchema.omit({ can: true }) })
    .safeParse(user).success;

const isBlocked = (
  user:
    | z.infer<typeof userSchema>
    | z.infer<typeof incompleteUserSchema>
    | z.infer<typeof irishUserSchema>,
) => {
  const metadata = user["can/app_metadata"];
  if (!metadata?.companies) {
    return false;
  }

  if (metadata.companies.length === 0) {
    return false;
  }
  return metadata.companies.every(
    (company) => company.approvalState === "BLOCKED",
  );
};

export { getMaybeUser, getUser, getUserState, isBlocked, planTypeSchema };
export type {
  ApprovalStatus,
  Company,
  Grant,
  IncompleteUserSchema,
  PlanSchema,
  Role,
  UserSchema,
};
