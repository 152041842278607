import CrossSvg from "@icons/cross.svg?react";
import { msg } from "@lingui/core/macro";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/react/macro";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import {
  createSearchParams,
  Link,
  Navigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import invariant from "tiny-invariant";

import { useHasPermission } from "@/common/acl/guard/guard";
import { primary } from "@/common/components/button/button";
import { Failed } from "@/common/components/info/info";
import { Metadata } from "@/common/components/metadata/metadata";
import { PageTitle } from "@/common/components/page-title/page-title";
import { PreviewCard } from "@/common/components/preview-card/preview-card";
import {
  getFilterByDateRange,
  getFilterParams,
  getInitialDateFilter,
  QuickFilters,
} from "@/common/components/quick-filters/quick-filters";
import {
  Filter,
  getAvailableFilters,
  getNextFilter,
  getURLParams,
} from "@/common/components/quick-filters/quick-filters.utils";
import {
  MergeSearchParamsLink,
  SearchParamsLink,
} from "@/common/components/search-params-link/search-params-link";
import { Spacer } from "@/common/components/spacer/spacer";
import { useScrollbarToggle } from "@/common/hooks/use-scrollbar-toggle";
import { path as editorPath } from "@/routes/admin/editor/editor";

import { TwoColumnLayout } from "../layout/layout";
import { LockedScreen } from "../shared/components/locked-screen/locked-screen";
import {
  getReportsQueryParamsSchema,
  reportsDateRangeQuery,
  reportsQuery,
} from "./reports.api";
import { useEnforcePlans, useFilterClickHandler } from "./reports.hooks";
import { initialSortOrder, SortOrder } from "./reports.sort-order";
import { Toolbar } from "./reports.toolbar";

const path = "reports";
const getParams = () =>
  createSearchParams({
    ...initialSortOrder,
    ...getInitialDateFilter(),
  });

const Reports = () => {
  const { _ } = useLingui();
  const params = useParams();
  const isReportsManager = useHasPermission("news:report:manage");
  const previousReportsCount = React.useRef(8); // prevents layout shift on filter change
  const searchParams = useReportSearchParams();
  const { enforcePlans, handleClosePlans } = useEnforcePlans();
  const isDetailOpen = params.reportId !== undefined && !enforcePlans;
  useScrollbarToggle(isDetailOpen);
  invariant(searchParams);
  const reports = useQuery({
    ...reportsQuery(searchParams),
    enabled: !!searchParams,
  });
  const reportsDateRange = useQuery({
    ...reportsDateRangeQuery(searchParams),
    enabled: !!searchParams,
  });
  const availableFilters = getAvailableFilters(reportsDateRange.data?.oldest);
  const filter = getFilterParams(searchParams);
  const { activeFilter, nextFilter } = getActiveAndNextFilter(
    availableFilters,
    filter,
  );

  const handleFilterClick = useFilterClickHandler(
    availableFilters,
    activeFilter,
  );

  const NextButtonJsx = nextFilter ? (
    <MergeSearchParamsLink
      to={`?${new URLSearchParams(getURLParams(nextFilter))}`}
      className={primary}
    >
      <Trans>Načíst zprávy pro {nextFilter.label.toLowerCase()}</Trans>
    </MergeSearchParamsLink>
  ) : null;

  let Jsx: React.ReactNode = null;

  if (searchParams.enforcePlans) {
    return (
      <LockedScreen>
        <button onClick={handleClosePlans}>
          <CrossSvg />
        </button>
      </LockedScreen>
    );
  }

  if (reports.status === "pending") {
    Jsx = Array.from({ length: previousReportsCount.current }).map((_, i) => (
      <PreviewCard key={i} skeleton />
    ));
  }

  if (reports.status === "error") {
    Jsx = (
      <p className="col-start-1 col-end-[-1]">
        <Failed error={reports.error} />
      </p>
    );
  }

  if (reports.status === "success") {
    previousReportsCount.current = reports.data.length;

    Jsx =
      reports.data.length > 0 ? (
        reports.data.map((report) => (
          <figure
            key={report.id}
            className="flex w-full flex-col gap-y-2 justify-self-start sm:self-start"
          >
            <SearchParamsLink to={report.id}>
              <PreviewCard
                {...report}
                className={`${
                  params.reportId === report.id
                    ? "bg-can-silver-gray [&>h2]:text-can-forest-teal"
                    : ""
                } ${report.status === "DRAFT" ? "opacity-50 grayscale" : ""}`}
              />
            </SearchParamsLink>
            {isReportsManager ? (
              <Toolbar id={report.id} status={report.status} />
            ) : null}
          </figure>
        ))
      ) : (
        <p className="col-start-1 col-end-[-1]">
          <Trans>
            Bohužel vám nemáme co ukázat. Zkuste prosím jinou kombinaci filtrů.
          </Trans>
        </p>
      );
  }

  return (
    <TwoColumnLayout
      left={{
        content: (
          <>
            <Metadata title={_(msg`Analýza trhu`)} />
            {isReportsManager ? (
              <>
                <Link
                  className="text-can-forest-teal decoration-can-forest-teal self-start underline"
                  to={`/admin/${editorPath}`}
                  target="_blank"
                >
                  <Trans>+ Napsat nový článek</Trans>
                </Link>
                <Spacer className="h-6" />
              </>
            ) : null}
            <div className="w-full shrink-0 overflow-x-auto">
              <QuickFilters
                onClick={handleFilterClick}
                filters={availableFilters}
                activeFilter={activeFilter}
              />
            </div>
            <section
              className={`grid basis-full grid-cols-1 content-start gap-6 ${isDetailOpen ? "grid-cols-[repeat(auto-fill,minmax(250px,1fr))] xl:grid-cols-[repeat(auto-fill,minmax(350px,1fr))]" : "sm:grid-cols-[repeat(auto-fill,minmax(350px,1fr))]"}`}
            >
              {Jsx}
            </section>
            {nextFilter ? (
              <div className="center flex basis-auto justify-center pb-6">
                {NextButtonJsx}
              </div>
            ) : null}
          </>
        ),
        header: (
          <>
            <PageTitle>
              <Trans>Market news</Trans>
            </PageTitle>
            <SortOrder />
          </>
        ),
      }}
      right={isDetailOpen}
    />
  );
};

const ReportsParams = ({ children }: React.PropsWithChildren) => {
  const searchParams = useReportSearchParams();
  const { pathname } = useLocation();

  if (!searchParams) {
    return <Navigate to={`${pathname}?${getParams()}`} />;
  }

  return <>{children}</>;
};

const useReportSearchParams = () => {
  const [searchParams] = useSearchParams();
  const parsedParams = getReportsQueryParamsSchema().safeParse(
    Object.fromEntries(searchParams),
  );

  if (parsedParams.success) {
    return parsedParams.data;
  }
};

const getActiveAndNextFilter = (
  filters: Filter[],
  current: Omit<Filter, "label">,
) => {
  const activeFilter = getFilterByDateRange(filters, current);
  const nextFilter = getNextFilter(filters, current);

  return { activeFilter, nextFilter };
};

export { getParams, path, Reports, ReportsParams };
