import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
} from "@tanstack/react-query";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DateTime } from "luxon";
import React from "react";
import { Link, Outlet, useSearchParams } from "react-router-dom";

import { useHasPermission } from "@/common/acl/guard/guard";
import { Failed, NoData } from "@/common/components/info/info";
import { Metadata } from "@/common/components/metadata/metadata";
import {
  itemsPerPage,
  Pagination,
} from "@/common/components/pagination/pagination";
import { Spacer } from "@/common/components/spacer/spacer";
import { Spinner } from "@/common/components/spinner/spinner";
import { useToggle } from "@/common/hooks/use-toggle";
import { currencyFormatter, getPrice } from "@/common/services/formatter";
import {
  getPayments,
  GetPaymentsPaymentStatus,
  GetPaymentsPaymentType,
  PaymentToStatus,
} from "@/generated/api/users";
import { AutocompleteString } from "@/types";

import { Filter, SelectFilter } from "../components/table/filter/filter";
import { Table } from "../components/table/table";
import { Action } from "./components/action";
import { Invoice } from "./components/invoice";

const path = "payments";
const fallbackTable: ReturnType<typeof select>["data"] = [];

const Payments = () => {
  const { params, handleParamChange } = usePaymentsParams();
  const canEditPayment = useHasPermission("users:payment:confirm");
  const payments = useQuery({
    placeholderData: keepPreviousData,
    ...paymentsQuery(params),
  });
  const table = useReactTable({
    data: payments.data?.data ?? fallbackTable,
    columns: [
      ...columns,
      ...(canEditPayment ? [invoiceColumn, actionColumn] : []),
    ],
    getCoreRowModel: getCoreRowModel(),
    manualFiltering: true,
    manualPagination: true,
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });
  if (payments.status === "pending" && payments.isLoading) {
    return (
      <>
        <Metadata title={t`Správa uživatelů`} />
        <Spinner className="mt-4" />
      </>
    );
  }

  if (payments.status === "error") {
    return <Failed error={payments.error} />;
  }

  return (
    <>
      <Metadata title={t`Správa plateb`} />
      <Table>
        <Table.Head
          table={table}
          filters={{
            variableSymbol: (
              <Filter
                onFilterChange={handleParamChange}
                value={params.search}
                column={table.getColumn("variableSymbol")!}
              />
            ),
            type: (
              <SelectFilter
                column={table.getColumn("type")!}
                value={params.paymentType}
                values={[
                  { label: t`Platba kartou`, value: "CARD" },
                  { label: t`Bankovní převod`, value: "BANK_TRANSFER" },
                ]}
                onFilterChange={handleParamChange}
              />
            ),
            status: (
              <SelectFilter
                column={table.getColumn("status")!}
                value={params.paymentStatus}
                values={Object.entries(PaymentToStatus).map(([key, value]) => ({
                  label: key,
                  value,
                }))}
                onFilterChange={handleParamChange}
              />
            ),
          }}
        />
        {payments.status === "success" && payments.data.data.length > 0 ? (
          <Table.Body table={table} />
        ) : null}
      </Table>
      {payments.data ? (
        <>
          <Spacer className="h-5" />
          <Pagination
            getter={() => parseInt(params.page, 10)}
            setter={(page) => handleParamChange("page", page.toString())}
            pageSize={itemsPerPage.oneHundred}
            total={payments.data.totalSize}
          />
        </>
      ) : null}
      {payments.status === "success" &&
      payments.fetchStatus === "idle" &&
      payments.data.data.length === 0 ? (
        <div className="mt-4">
          <NoData>
            <Trans>Nebyly nalezeny žádné záznamy</Trans>
          </NoData>
        </div>
      ) : null}
      {payments.fetchStatus === "fetching" && !payments.isLoading ? (
        <Spinner withHint={false} />
      ) : null}
      <Outlet />
    </>
  );
};

const columnHelper =
  createColumnHelper<ReturnType<typeof select>["data"][number]>();
const columns = [
  columnHelper.accessor("id", {}),
  columnHelper.accessor("createdAt", {
    header: () => t`Datum platby`,
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATETIME_SHORT),
    meta: {
      label: () => t`Datum platby`,
    },
  }),
  columnHelper.accessor(
    (row) => ({
      name: row.company.name,
      id: row.company.id,
    }),
    {
      id: "company",
      header: () => t`Společnost`,
      cell: (info) => (
        <Link to={"company/" + info.getValue().id}>{info.getValue().name}</Link>
      ),
      meta: {
        label: () => t`Společnost`,
      },
    },
  ),
  columnHelper.accessor(
    (row) => ({
      period: row.subscription.planPeriod,
      plan: row.subscription.planType,
    }),
    {
      id: "paymentSubject",
      header: () => t`Předmět platby`,
      cell: (info) => info.getValue().plan + " - " + info.getValue().period,
      meta: {
        label: () => t`Předmět platby`,
      },
    },
  ),
  columnHelper.accessor(
    (row) => ({
      gateway: row.gateway,
      variableSymbol: row.invoice.at(0)?.variable,
      gatewayId: row.gatewayId,
    }),
    {
      id: "variableSymbol",
      header: () => t`Variabilní symbol`,
      cell: (info) => (
        <VariableSymbolCell gatewayId={info.getValue().gatewayId}>
          {info.getValue().variableSymbol}
        </VariableSymbolCell>
      ),
      meta: {
        filterKey: "search",
        label: () => t`Variabilní symbol`,
      },
    },
  ),
  columnHelper.accessor("type", {
    header: () => t`Typ`,
    cell: (info) =>
      info.getValue() === "CARD" ? t`Platba kartou` : t`Bankovní převod`,
    meta: {
      filterKey: "paymentType",
      label: () => t`Typ`,
    },
  }),
  columnHelper.accessor("status", {
    header: () => t`Stav`,
    cell: (info) => info.getValue(),
    meta: {
      filterKey: "paymentStatus",
      label: () => t`Stav`,
    },
  }),
  columnHelper.accessor("amount", {
    header: () => t`Částka`,
    cell: (info) => info.getValue(),
    meta: {
      label: () => t`Částka`,
    },
  }),
];

const VariableSymbolCell = ({
  gatewayId,
  children,
}: React.PropsWithChildren<{ gatewayId?: string }>) => {
  const toggle = useToggle();
  const handleClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e.ctrlKey) {
      toggle.toggle();
    }
  };

  return <span onClick={handleClick}>{toggle.on ? gatewayId : children}</span>;
};

const invoiceColumn = columnHelper.accessor("invoice", {
  header: () => t`Faktura`,
  cell: (info) => {
    return (
      <>
        {info.getValue().map((invoice, i, invoices) => (
          <React.Fragment key={invoice.id}>
            <Invoice id={invoice.id} key={invoice.id}>
              {invoice.type === "PAYMENT_REQUEST" ? (
                <Trans>Výzva k úhradě</Trans>
              ) : (
                <Trans>Faktura</Trans>
              )}
            </Invoice>
            {i === invoices.length - 1 ? "" : " | "}
          </React.Fragment>
        ))}
      </>
    );
  },
  meta: {
    label: () => t`Faktura`,
  },
});

const actionColumn = columnHelper.display({
  id: "action",
  header: () => "",
  cell: ({ row }) =>
    row.getValue("type") === "BANK_TRANSFER" &&
    row.getValue("status") === "WAITING" ? (
      <Action paymentId={row.getValue("id")} />
    ) : null,
});

const usePaymentsParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = {
    page: searchParams.get("page") ?? "1",
    search: searchParams.get("search") ?? "",
    paymentType: (searchParams.get("paymentType") ??
      "") as GetPaymentsPaymentType,
    paymentStatus: (searchParams.get("paymentStatus") ??
      "") as GetPaymentsPaymentStatus,
  };

  const handleParamChange = (
    key: AutocompleteString<"page" | "search" | "paymentType">,
    value: string,
  ) => {
    setSearchParams((c) => {
      c.set(key, value);
      return c;
    });
  };

  return { params, handleParamChange };
};

const select = (data: Awaited<ReturnType<typeof getPayments>>) => {
  return {
    ...data,
    data: data.data.map(({ currency, ...payment }) => ({
      ...payment,
      amount: currencyFormatter(currency.type, {
        maximumFractionDigits: 0,
      }).format(getPrice(payment.amount, currency.precision)),
    })),
  };
};

const paymentsQuery = (params: Parameters<typeof getPayments>[0]) =>
  queryOptions({
    queryKey: ["admin", "payments", params],
    queryFn: ({ signal }) => getPayments(params, { signal }),
    select,
  });

export { path, Payments };
