import { Trans } from "@lingui/react/macro";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, Outlet, RouteObject, useLocation } from "react-router-dom";

import {
  AdminGuard,
  countryPermissionCheckFn,
  PermissionGuard,
  useHasPermission,
} from "@/common/acl/guard/guard";
import { Failed } from "@/common/components/info/info";
import { Spinner } from "@/common/components/spinner/spinner";

import * as Companies from "./admin/companies/companies";
import * as Company from "./admin/company/company";
import { Layout as AdminLayout } from "./admin/components/layout/layout";
import { Loader } from "./admin/components/loader/loader";
import { path as userPath } from "./admin/components/user/user";
import { EditorProvider, path as editorPath } from "./admin/editor/editor";
import * as Payments from "./admin/payments/payments";
import { PendingUser } from "./admin/pending-users/pending-user";
import * as PendingUsers from "./admin/pending-users/pending-users";
import * as User from "./admin/users/user";
import * as Users from "./admin/users/users";

const routes = (): RouteObject[] => [
  {
    path: "admin",
    element: <RedirectToAvailableScreen />,
    children: [
      {
        element: <AdminGuard />,
        children: [
          {
            path: "",
            element: <AdminLayout />,
            children: [
              {
                path: editorPath,
                element: (
                  <PermissionGuard
                    permission="news:report:manage"
                    onDenied={<Navigate to=".." />}
                  >
                    <EditorProvider />
                  </PermissionGuard>
                ),
              },
              {
                path: `${editorPath}/:id`,
                element: (
                  <PermissionGuard
                    permission="news:report:manage"
                    onDenied={<Navigate to=".." />}
                  >
                    <EditorProvider />
                  </PermissionGuard>
                ),
              },
              {
                path: Payments.path,
                element: (
                  <PermissionGuard
                    permission="users:*:list"
                    onDenied={<Navigate to=".." />}
                  >
                    <PermissionGuard
                      onDenied={
                        <p className="mt-8 text-center">
                          <Trans>
                            Nemáte právo spravovat systém v žádné ze zemí, které
                            podporujeme. Obraťte se prosím na administrátora.
                          </Trans>
                        </p>
                      }
                      permissionCheckFn={countryPermissionCheckFn}
                    >
                      <ErrorBoundary
                        fallbackRender={({ error }) => <Failed error={error} />}
                      >
                        <Suspense fallback={<Spinner className="mt-4" />}>
                          <Payments.Payments />
                        </Suspense>
                      </ErrorBoundary>
                    </PermissionGuard>
                  </PermissionGuard>
                ),

                children: [
                  {
                    path: "company/:id",
                    element: (
                      <Loader>
                        <Company.Company>
                          <Company.Subscriptions />
                          <PermissionGuard permission="users:company:plan">
                            <Company.SubscriptionForm />
                          </PermissionGuard>
                          <Company.Detail />
                          <Company.Users />
                        </Company.Company>
                      </Loader>
                    ),
                  },
                ],
              },
              {
                path: Users.path,
                element: (
                  <PermissionGuard
                    permission="users:*:list"
                    onDenied={<Navigate to=".." />}
                  >
                    <PermissionGuard
                      onDenied={
                        <p className="mt-8 text-center">
                          <Trans>
                            Nemáte právo spravovat systém v žádné ze zemí, které
                            podporujeme. Obraťte se prosím na administrátora.
                          </Trans>
                        </p>
                      }
                      permissionCheckFn={countryPermissionCheckFn}
                    >
                      <ErrorBoundary
                        fallbackRender={({ error }) => <Failed error={error} />}
                      >
                        <Suspense fallback={<Spinner className="mt-4" />}>
                          <Users.Users />
                        </Suspense>
                      </ErrorBoundary>
                    </PermissionGuard>
                  </PermissionGuard>
                ),
                children: [
                  {
                    path: "company/:id",
                    element: (
                      <Loader>
                        <Company.Company>
                          <Company.Subscriptions />
                          <PermissionGuard permission="users:company:plan">
                            <Company.SubscriptionForm />
                          </PermissionGuard>
                          <Company.Detail />
                          <Company.Users />
                        </Company.Company>
                      </Loader>
                    ),
                  },
                  {
                    path: userPath,
                    element: (
                      <Loader>
                        <User.User />
                      </Loader>
                    ),
                  },
                ],
              },
              {
                path: Companies.path,
                element: (
                  <PermissionGuard
                    permission="users:*:list"
                    onDenied={<Navigate to=".." />}
                  >
                    <PermissionGuard
                      onDenied={
                        <p className="mt-8 text-center">
                          <Trans>
                            Nemáte právo spravovat systém v žádné ze zemí, které
                            podporujeme. Obraťte se prosím na administrátora.
                          </Trans>
                        </p>
                      }
                      permissionCheckFn={countryPermissionCheckFn}
                    >
                      <ErrorBoundary
                        fallbackRender={({ error }) => <Failed error={error} />}
                      >
                        <Suspense fallback={<Spinner className="mt-4" />}>
                          <Companies.Companies />
                        </Suspense>
                      </ErrorBoundary>
                    </PermissionGuard>
                  </PermissionGuard>
                ),
                children: [
                  {
                    path: ":id",
                    element: (
                      <Loader>
                        <Company.Company>
                          <Company.Subscriptions />
                          <PermissionGuard permission="users:company:plan">
                            <Company.SubscriptionForm />
                          </PermissionGuard>
                          <Company.Detail />
                          <Company.Users />
                        </Company.Company>
                      </Loader>
                    ),
                  },
                ],
              },
              {
                path: PendingUsers.path,
                element: (
                  <PermissionGuard
                    permission="users:*:list"
                    onDenied={<Navigate to=".." />}
                  >
                    <PermissionGuard
                      onDenied={
                        <p className="mt-8 text-center">
                          <Trans>
                            Nemáte právo spravovat systém v žádné ze zemí, které
                            podporujeme. Obraťte se prosím na administrátora.
                          </Trans>
                        </p>
                      }
                      permissionCheckFn={countryPermissionCheckFn}
                    >
                      <PendingUsers.Users />
                    </PermissionGuard>
                  </PermissionGuard>
                ),
                children: [
                  {
                    path: userPath,
                    element: (
                      <Loader>
                        <PendingUser />
                      </Loader>
                    ),
                  },
                  {
                    path: Company.path,
                    element: (
                      <Loader>
                        <Company.Company>
                          <PermissionGuard permission="users:company:plan">
                            <Company.SubscriptionForm />
                          </PermissionGuard>
                          <Company.Detail />
                          <Company.Users />
                        </Company.Company>
                      </Loader>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const RedirectToAvailableScreen = () => {
  const { pathname } = useLocation();
  const canEditNews = useHasPermission("news:report:manage");
  const canManageUsersOrCompanies = useHasPermission("users:*:list");

  if (
    [canEditNews, canManageUsersOrCompanies].some(
      (permission) => permission === null,
    )
  ) {
    return null;
  }

  if (pathname === "/admin" || pathname === "/admin/") {
    let target: string | undefined;

    if (canManageUsersOrCompanies) {
      target = PendingUsers.path;
    }

    if (canEditNews) {
      target = editorPath;
    }

    if (!target) {
      return <Navigate to="/" />;
    }

    return <Navigate to={target} replace />;
  }

  return <Outlet />;
};

export { routes };
