import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";

import { ReportToStatus } from "@/generated/api/news";

import { path as editorPath } from "../admin/editor/editor";
import {
  changeReportStatusMutation,
  deleteReportMutation,
} from "./reports.api";

const Toolbar = ({ id, status }: { id: string; status: ReportToStatus }) => {
  const queryClient = useQueryClient();
  const changeReportStatus = useMutation({
    ...changeReportStatusMutation(),
    onSuccess: () => {
      void queryClient.invalidateQueries();
    },
  });
  const deleteReport = useMutation({
    ...deleteReportMutation(),
    onSuccess: () => {
      void queryClient.invalidateQueries();
    },
    onError: ({ message }) => {
      window.alert(t`Článek se nepodařilo smazat kvůli chybě: ${message}`);
    },
  });
  const isPending =
    changeReportStatus.status === "pending" ||
    deleteReport.status === "pending";

  const handleStatusChange = (id: string, status: ReportToStatus) => {
    const ok = window.prompt(
      t`Pokud chcete změnit status článku, napište ok a potvrďte.`,
    );

    if (ok !== "ok") {
      return;
    }

    changeReportStatus.mutate({ id: parseInt(id), status });
  };

  const handleDelete = (id: string) => {
    const ok = window.prompt(
      t`Pokud chcete článek opravdu smazat, napište ok a potvrďte.`,
    );

    if (ok !== "ok") {
      return;
    }

    deleteReport.mutate(parseInt(id));
  };

  const actionProps = {
    className: `rounded bg-linear-to-r from-can-tranquil-azure to-can-mystic-aqua px-2 py-1 text-sm text-white ${
      isPending ? "cursor-progress" : ""
    }`,
    disabled: isPending,
  };

  return (
    <nav className="w-full px-6 pt-2">
      <ul className="flex flex-wrap justify-center gap-2">
        {status === "DRAFT" ? (
          <li>
            <Trans>draft</Trans>
          </li>
        ) : null}
        <li className={actionProps.className}>
          <button
            disabled={actionProps.disabled}
            onClick={() => handleDelete(id)}
          >
            <Trans>smazat</Trans>
          </button>
        </li>
        <li className={actionProps.className}>
          <Link className="text-white" to={`/admin/${editorPath}/${id}`}>
            <Trans>editovat</Trans>
          </Link>
        </li>
        <li className={actionProps.className}>
          <button
            disabled={actionProps.disabled}
            onClick={() =>
              handleStatusChange(id, status === "DRAFT" ? "PUBLISHED" : "DRAFT")
            }
          >
            {status === "DRAFT" ? (
              <Trans>publikovat</Trans>
            ) : (
              <Trans>skrýt</Trans>
            )}
          </button>
        </li>
      </ul>
    </nav>
  );
};

export { Toolbar };
