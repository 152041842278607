import { queryOptions, useMutation } from "@tanstack/react-query";

import { exportUsers, getUsers } from "@/generated/api/users";

const usersQuery = ({ page, search }: { page: string; search: string }) =>
  queryOptions({
    queryKey: ["users", page, search],
    queryFn: ({ signal }) =>
      getUsers(
        {
          page,
          size: "100",
          search,
          sortCol: "NAME",
        },
        { signal },
      ),
  });

const useExportUsersMutation = () =>
  useMutation({
    mutationFn: async (params: Parameters<typeof exportUsers>[0]) => {
      const { blob, filename } = (await exportUsers({
        search: params?.search,
      })) as { blob: Blob; filename: string };
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    },
  });

export { useExportUsersMutation, usersQuery };
