import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

import { logger } from "@/common/services/logger";

const Logout = () => {
  const logout = useLogout();

  React.useEffect(() => {
    logout().catch(logger.error);
  }, [logout]);

  return null;
};

const useLogout = () => {
  const { logout } = useAuth0();

  return () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
};

export { Logout, useLogout };
