import { queryOptions } from "@tanstack/react-query";

import { getCompanyPlans } from "@/generated/api/users";

const companyPlansQuery = () =>
  queryOptions({
    queryKey: ["company", "plans"],
    queryFn: ({ signal }) => getCompanyPlans(undefined, { signal }),
  });

export { companyPlansQuery };
