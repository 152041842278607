import { queryOptions, useMutation } from "@tanstack/react-query";

import {
  CompanyPutRequest,
  CountryToPopulatorFieldsItem,
  getCountries,
  getCurrentCompany,
  updateCompanyDetail,
} from "@/generated/api/users";

const useUpdateCompanyMutation = () =>
  useMutation({
    mutationKey: ["company", "update"],
    mutationFn: ({ id, ...payload }: CompanyPutRequest & { id: number }) =>
      updateCompanyDetail(id, payload),
  });

const companyQuery = () =>
  queryOptions({
    queryKey: ["company-info"],
    queryFn: async ({ signal }) => {
      const countries = await getCountries({ signal });
      const company = await getCurrentCompany({ signal });

      return [countries, company] as const;
    },
    select: ([countries, company]) => {
      const disabledFields =
        countries.find((country) => country.id === company.country)
          ?.populatorFields ?? [];

      return {
        disabledFields: mapPopulatorFields(disabledFields),
        company,
      } as const;
    },
  });

const mapPopulatorFields = (populatorFields: CountryToPopulatorFieldsItem[]) =>
  populatorFields.map((field) => {
    switch (field) {
      case "VAT":
        return "vat";
      case "FORM":
        return "legalForm";
      case "ADDRESS":
        return "address";
    }
  });

export { companyQuery, useUpdateCompanyMutation };
