import Cross from "@icons/cross.svg?react";
import TimesHexagon from "@icons/times-hexagon.svg?react";
import { useLocation } from "react-router-dom";

import { Button } from "@/common/components/button/button";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { Spacer } from "@/common/components/spacer/spacer";
import { FullscreenModalLayout } from "@/routes/layout/layout.fullscreen";

import { Errors } from "../../api/error-handler";

type Props = {
  title?: React.ReactNode;
  content?: React.ReactNode;
  action?: React.ReactNode | null;
  headerButton?: React.ReactNode;
};

const path = "failed";
const Failed = (props: Props) => {
  const { state } = useLocation() as { state?: { errors?: Errors } };
  return (
    <FullscreenModalLayout
      headerButton={
        props.headerButton ?? (
          <SearchParamsLink to=".." replace>
            <Cross />
          </SearchParamsLink>
        )
      }
    >
      <FullscreenModalLayout.Title>{props.title}</FullscreenModalLayout.Title>
      <div className="self-center rounded-full bg-can-silver-gray p-11">
        <TimesHexagon />
      </div>
      <Spacer className="h-6" />
      <p className="text-center">{props.content}</p>
      {state?.errors ? (
        <>
          <Spacer className="h-6" />
          <p className="text-center">{state.errors.map((e) => e.error)}</p>
        </>
      ) : null}
      <Button.Container>{props.action}</Button.Container>
    </FullscreenModalLayout>
  );
};

const Title = (props: React.PropsWithChildren) => (
  <h1 className="text-2xl font-black text-can-forest-teal" {...props} />
);

const Content = (props: React.PropsWithChildren) => (
  <p className="text-center" {...props} />
);

Failed.Content = Content;
Failed.Title = Title;

export { Failed, path };
