import { useAuth0 } from "@auth0/auth0-react";
import { Trans } from "@lingui/react/macro";
import { Link, Navigate, useSearchParams } from "react-router-dom";

import { primary } from "@/common/components/button/button";
import { Spinner } from "@/common/components/spinner/spinner";
import { logger } from "@/common/services/logger";

import { BlockedUser } from "../blocked-user/blocked-user";

const path = "callback";

const Callback = () => {
  const { isLoading, error } = useAuth0();

  if (isLoading) {
    return (
      <div className="flex h-full flex-col items-center justify-center gap-4 px-6 sm:flex-row">
        <Spinner />
        <h1 className="text-center text-can-forest-teal">
          <Trans>Přihlašujeme vás do Clever°Analytics'...</Trans>
        </h1>
      </div>
    );
  }

  if (error) {
    if (error.message.match(/user is blocked/gi)) {
      return <BlockedUser />;
    }

    return (
      <div className="flex h-full flex-col items-center justify-center gap-4 px-6 sm:flex-row">
        <div className="flex flex-col gap-y-4 rounded-2xl p-6 shadow-can-light-box">
          <h1>
            <Trans>Bohužel vás nemůžeme přihlásit.</Trans>
          </h1>
          <ErrorDetails />
          <Link to="/logout" className={primary}>
            <Trans>Přihlásit se</Trans>
          </Link>
        </div>
      </div>
    );
  }

  return <Navigate to="/" replace />;
};

const ErrorDetails = () => {
  const [params] = useSearchParams();

  if (params.get("error_description") === "can_companies_mismatch") {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    logger.error("User companies differ from the ones we keep track of");

    return (
      <p className="text-xs">
        <Trans>
          V nastavení vašich společností jsme nalezli nesrovnalosti. Kontaktujte
          prosím podporu.
        </Trans>
      </p>
    );
  }
};

export { Callback, path };
