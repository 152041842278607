import Barley from "./assets/icons/barley.svg?react";
import Corn from "./assets/icons/corn.svg?react";
import Pea from "./assets/icons/pea.svg?react";
import Rapeseed from "./assets/icons/rapeseed.svg?react";
import SoyaBean from "./assets/icons/soya-bean.svg?react";
import Sunflower from "./assets/icons/sunflower.svg?react";
import Tritikale from "./assets/icons/tritikale.svg?react";
import Wheat from "./assets/icons/wheat.svg?react";

const CropIcon = ({
  cropName,
  className,
}: {
  className?: string;
  cropName: string;
}) => {
  let Icon: null | React.FunctionComponent<React.SVGProps<SVGSVGElement>> =
    null;

  if (cropName.startsWith("Pšenice")) {
    Icon = Wheat;
  } else if (cropName.startsWith("Ječmen")) {
    Icon = Barley;
  } else if (cropName.startsWith("Kukuřice")) {
    Icon = Corn;
  } else if (cropName.startsWith("Řepka")) {
    Icon = Rapeseed;
  } else if (cropName.startsWith("Řepkový šrot")) {
    Icon = Rapeseed;
  } else if (cropName.startsWith("Tritikale")) {
    Icon = Tritikale;
  } else if (cropName.startsWith("Hrách")) {
    Icon = Pea;
  } else if (cropName.startsWith("Sojový bob")) {
    Icon = SoyaBean;
  } else if (cropName.startsWith("Sojový šrot")) {
    Icon = SoyaBean;
  } else if (cropName.startsWith("Slunečnice")) {
    Icon = Sunflower;
  }

  return Icon ? <Icon className={className} /> : null;
};

export { CropIcon };
