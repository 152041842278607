import { t } from "@lingui/core/macro";

import { PaymentToStatus } from "@/generated/api/users";

const getLocalizedPaymentStatus = (): Record<PaymentToStatus, string> => ({
  ERROR: t({ context: "payment status", id: "Selhalo" }),
  WAITING: t({ context: "payment status", id: "Neuhrazeno" }),
  REFUNDED: t({ context: "payment status", id: "Vráceno" }),
  COMPLETED: t({ context: "payment status", id: "Zaplaceno" }),
  CANCELLED: t({ context: "payment status", id: "Zrušeno" }),
  INITIALIZED: t({ context: "payment status", id: "Založeno" }),
});

export { getLocalizedPaymentStatus };
