import TotalSalesSvg from "@icons/total-sales.svg?react";
import { msg, t } from "@lingui/core/macro";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/react/macro";
import { useSuspenseQueries, useSuspenseQuery } from "@tanstack/react-query";
import intersectionWith from "lodash-es/intersectionWith";
import { DateTime } from "luxon";
import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  createSearchParams,
  generatePath,
  Navigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import invariant from "tiny-invariant";
import { z } from "zod";

import { ContractItem } from "@/common/components/contract-item/contract-item";
import { Failed, NoData } from "@/common/components/info/info";
import { Metadata } from "@/common/components/metadata/metadata";
import { OrderItem } from "@/common/components/order-item/order-item";
import { PageTitle } from "@/common/components/page-title/page-title";
import {
  itemsPerPage,
  Pagination,
} from "@/common/components/pagination/pagination";
import { QuickFilters } from "@/common/components/quick-filters/quick-filters";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { Spacer } from "@/common/components/spacer/spacer";
import { Spinner } from "@/common/components/spinner/spinner";
import { useSidebar } from "@/common/providers/sidebar-provider/sidebar-provider";
import { toggleItem } from "@/common/utils/array";

import { TwoColumnLayout } from "../layout/layout";
import { companiesQuery } from "../storages/api";
import { contractsQuery, totalSalesQuery } from "./contracts.api";
import { cropsInOrdersQuery, orderListQuery } from "./orders.api";
import { path as contractPath } from "./transactions-and-orders.contract";
import { path as orderPath } from "./transactions-and-orders.order";

const useIsDetailOpen = () => {
  const params = useParams();

  return !!(params.orderId ?? params.contractId);
};

const useIsCurrentContract = () => {
  const params = useParams();

  return (contractId: string) => params.contractId === contractId;
};

const useIsCurrentOrder = () => {
  const params = useParams();

  return (orderId: string) => params.orderId === orderId;
};

const path = "transactions-and-orders";
const TransactionsAndOrders = () => {
  const { _ } = useLingui();
  const isDetailOpen = useIsDetailOpen();
  const sidebar = useSidebar();

  return (
    <TwoColumnLayout
      left={{
        header: (
          <PageTitle>
            <Trans>Transakce a příkazy</Trans>
          </PageTitle>
        ),
        content: (
          <div className="flex flex-col gap-y-8">
            <Metadata title={_(msg`Transakce a příkazy`)} />
            <Overview />
            <div
              className={`grid grid-cols-1 gap-6 ${sidebar.state.screen || isDetailOpen ? "xl:grid-cols-2" : "md:grid-cols-2 lg:grid-cols-2"} `}
            >
              <ErrorBoundary
                fallbackRender={({ error }) => <Failed error={error} />}
              >
                <Suspense fallback={<Spinner />}>
                  <ContractList />
                </Suspense>
              </ErrorBoundary>
              <ErrorBoundary
                fallbackRender={({ error }) => <Failed error={error} />}
              >
                <Suspense fallback={<Spinner />}>
                  <OrderList />
                </Suspense>
              </ErrorBoundary>
            </div>
          </div>
        ),
      }}
      right={isDetailOpen}
    />
  );
};

const useContractsQuery = ({
  companies,
  page,
}: {
  page: number;
  companies: { id: string }[];
}) => {
  return useSuspenseQueries({
    queries: companies.map((company) => ({
      ...contractsQuery({ companyId: company.id }),
    })),
    combine: (results) => {
      const errored = results.find((result) => !!result.error);

      if (errored) {
        return {
          status: "error",
          error: errored.error as Error,
        } as const;
      }

      const data: (typeof results)[number]["data"] = [];
      results.forEach((r) => {
        if (r.data) {
          data.push(...r.data);
        }
      });

      const start = (page - 1) * 10;
      const end = (page - 1) * 10 + 10;

      return {
        data: data.slice(start, end),
        total: data.length,
        status: "success",
      } as const;
    },
  });
};

const ContractList = () => {
  const isCurrentContract = useIsCurrentContract();
  const { contractPage, setContractPage } = usePageParams();
  const companies = useSuspenseQuery(companiesQuery());
  const contracts = useContractsQuery({
    companies: companies.data,
    page: contractPage,
  });

  return (
    <div className="flex flex-col gap-y-6">
      <h2 className="text-can-forest-teal font-bold">
        <Trans>Transakce</Trans>
      </h2>
      {contracts.status === "success" ? (
        <>
          <ul className="flex flex-col gap-y-2 md:mt-12">
            {contracts.data.length === 0 ? (
              <NoData>
                <Trans>Neevidujeme žádné transakce.</Trans>
              </NoData>
            ) : null}
            {contracts.data.map((contract) => (
              <ContractItem
                {...contract}
                key={contract.id}
                status={<ContractItem.Status status={contract.status} />}
                className={
                  isCurrentContract(contract.id) ? "bg-can-silver-gray!" : ""
                }
              >
                <SearchParamsLink
                  to={generatePath(contractPath, {
                    contractId: contract.id,
                  })}
                ></SearchParamsLink>
                <p className="text-can-midnight-steel text-xs font-bold">
                  {contract.storage.name}: {contract.crop.name}
                </p>
              </ContractItem>
            ))}
          </ul>
          <Pagination
            pageSize={10}
            total={contracts.total}
            getter={() => contractPage}
            setter={setContractPage}
          />
          <Spacer className="h-6" />
        </>
      ) : null}
    </div>
  );
};

const OrderList = () => {
  const isCurrentOrder = useIsCurrentOrder();
  const crops = useSuspenseQuery(cropsInOrdersQuery());
  const { orderFilter, setOrderFilter, orderPage, setOrderPage } =
    usePageParams();
  const orders = useSuspenseQuery(
    orderListQuery({ cropIds: orderFilter, page: orderPage }),
  );

  const filters =
    crops.data.map((crop) => ({ value: crop.id, label: crop.name })) ?? [];
  const activeFilters = intersectionWith(
    filters,
    orderFilter,
    (a, b) => a.value === b,
  );
  return (
    <div className={`flex flex-col gap-y-6`}>
      <h2 className="text-can-forest-teal font-bold">
        <Trans>Aktivní příkazy</Trans>
      </h2>
      <div className="flex max-w-[calc(100vw-(--spacing(12)))] gap-3 overflow-x-auto">
        <SearchParamsLink
          replace
          to="."
          omit={["order_filter"]}
          className="border-can-silver-cloud text-can-forest-teal border-r pr-2"
        >
          <Trans context="meaning no filters are being applied">Vše</Trans>
        </SearchParamsLink>
        <QuickFilters
          activeFilter={activeFilters}
          filters={filters}
          onClick={(i) => setOrderFilter(filters[i].value)}
        />
      </div>
      <ul className="flex flex-col gap-y-2">
        {orders.data.orders.length === 0 ? (
          <NoData>
            <Trans>Neevidujeme žádné aktivní příkazy.</Trans>
          </NoData>
        ) : (
          orders.data.orders.map((order) => (
            <OrderItem
              {...order.order}
              key={order.order.id}
              className={
                isCurrentOrder(order.order.id) ? "bg-can-silver-gray!" : ""
              }
            >
              <SearchParamsLink
                to={generatePath(orderPath, {
                  offerId: order.offer.id,
                  orderId: order.order.id,
                })}
              ></SearchParamsLink>
              <p className="text-can-midnight-steel text-xs font-bold">
                {order.storage.name}: {order.crop.name}
              </p>
            </OrderItem>
          ))
        )}
      </ul>
      <Pagination
        pageSize={itemsPerPage.ten}
        total={orders.data.total}
        getter={() => orderPage}
        setter={setOrderPage}
      />
      <Spacer className="h-6" />
    </div>
  );
};

const Params = ({ children }: React.PropsWithChildren) => {
  const { params } = useTransactionsAndOrdersParams();
  const { pathname } = useLocation();

  if (!params) {
    return <Navigate replace to={`${pathname}?${initialParams}`} />;
  }

  return <>{children}</>;
};

const ranges = [
  "today",
  "last_week",
  "last_month",
  "three_months",
  "last_year",
  "all",
] as const;
const filters = () =>
  [
    {
      label: t`Dnes`,
      value: "today",
      dateFrom: DateTime.now().startOf("day"),
      dateTo: DateTime.now().startOf("day"),
    },
    {
      label: t`Týden`,
      value: "last_week",
      dateFrom: DateTime.now().startOf("day").minus({ week: 1 }),
      dateTo: DateTime.now().startOf("day"),
    },
    {
      label: t`Měsíc`,
      value: "last_month",
      dateFrom: DateTime.now().startOf("day").minus({ month: 1 }),
      dateTo: DateTime.now().startOf("day"),
    },
    {
      label: t`3 měsíce`,
      value: "three_months",
      dateFrom: DateTime.now().startOf("day").minus({ month: 3 }),
      dateTo: DateTime.now().startOf("day"),
    },
    {
      label: t`Rok`,
      value: "last_year",
      dateFrom: DateTime.now().startOf("day").minus({ year: 1 }),
      dateTo: DateTime.now().startOf("day"),
    },
    {
      label: t`Vše`,
      value: "all",
    },
  ] as const satisfies {
    label: string;
    value: Range;
    dateFrom?: DateTime;
    dateTo?: DateTime;
  }[];
type Range = (typeof ranges)[number];
const Overview = () => {
  const { overviewRange, setOverviewRange } = usePageParams();
  const isDetailOpen = useIsDetailOpen();
  const activeFilter = filters().find(({ value }) => value === overviewRange);

  return (
    <div className="flex max-w-[calc(100vw-(--spacing(12)))] flex-col gap-y-6">
      <QuickFilters
        onClick={(i) => {
          setOverviewRange(filters()[i].value);
        }}
        filters={filters()}
        activeFilter={activeFilter}
      />
      <ul
        className={`${isDetailOpen ? "pr-6" : ""} flex flex-col gap-y-2 sm:flex-row sm:justify-between sm:gap-6`}
      >
        <li className="shadow-can-light-box flex min-h-[84px] w-full max-w-[272px] items-center justify-between rounded-2xl p-4">
          <ErrorBoundary
            fallbackRender={({ error }) => <Failed error={error} />}
          >
            <Suspense fallback={<Spinner withHint={false} />}>
              <TotalSales />
            </Suspense>
          </ErrorBoundary>
        </li>
      </ul>
    </div>
  );
};

const TotalSales = () => {
  const companies = useSuspenseQuery(companiesQuery());
  const companyId = companies.data.at(0)?.id;
  const { overviewRange } = usePageParams();
  const activeFilter = filters().find(({ value }) => value === overviewRange);
  invariant(companyId);
  invariant(activeFilter);
  const sum = useSuspenseQuery(
    totalSalesQuery({
      companyId,
      dateFrom:
        activeFilter.value === "all" ? undefined : activeFilter.dateFrom,
      dateTo: activeFilter.value === "all" ? undefined : activeFilter.dateTo,
    }),
  );

  return (
    <>
      <div className="bg-can-silver-gray flex h-[50px] w-[50px] shrink-0 items-center justify-center rounded-full">
        <TotalSalesSvg />
      </div>
      <div className="flex flex-col gap-y-2 text-right text-xs">
        <h4>
          <Trans>Celkový objem prodejů</Trans>
        </h4>
        <span className="text-can-midnight-steel text-xl font-bold">
          {sum.data}
        </span>
      </div>
    </>
  );
};

const initialParams = createSearchParams({
  overview_range: "three_months",
  order_filter: "[]",
  order_page: "1",
  contract_page: "1",
});

const paramsSchema = z.object({
  overview_range: z.enum(ranges),
  order_filter: z.string(),
  order_page: z.coerce.number().min(1),
  contract_page: z.coerce.number().min(1),
});

const useTransactionsAndOrdersParams = () => {
  const [searchParams] = useSearchParams();
  const params = paramsSchema.safeParse(Object.fromEntries(searchParams));

  if (!params.success) {
    return { params: undefined };
  }

  const orderFilterInTact = z
    .string()
    .transform((v) => {
      try {
        return JSON.parse(v);
      } catch {
        return z.never;
      }
    })
    .pipe(z.array(z.string()))
    .safeParse(params.data.order_filter);

  if (!orderFilterInTact.success) {
    return { params: undefined };
  }

  return {
    params: {
      ...params.data,
    },
  };
};

const usePageParams = () => {
  const { params } = useTransactionsAndOrdersParams();
  const safeParams = paramsSchema
    .transform((p) => ({ ...p, order_filter: JSON.parse(p.order_filter) }))
    .parse(params);
  const [, setSearchParams] = useSearchParams();

  const setOverviewRange = (range: Range) => {
    setSearchParams((c) => {
      c.set("overview_range", range);
      return c;
    });
  };

  const setContractPage = (page: number) => {
    setSearchParams((c) => {
      c.set("contract_page", page.toString());
      return c;
    });
  };

  const setOrderFilter = (cropId: string) => {
    setSearchParams((c) => {
      const filters = JSON.parse(c.get("order_filter") ?? "[]");
      const nextFilters = toggleItem(filters, cropId);

      c.set("order_filter", JSON.stringify(nextFilters));
      return c;
    });
  };

  const setOrderPage = (page: number) => {
    setSearchParams((c) => {
      c.set("order_page", page.toString());
      return c;
    });
  };

  return {
    orderFilter: safeParams.order_filter,
    setOrderFilter,
    overviewRange: safeParams.overview_range,
    setOverviewRange,
    setOrderPage,
    orderPage: safeParams.order_page,
    setContractPage,
    contractPage: safeParams.contract_page,
  };
};

export { Params, path, TransactionsAndOrders };
