import Cross from "@icons/cross.svg?react";
import { Trans } from "@lingui/react/macro";
import { useQuery } from "@tanstack/react-query";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { createSearchParams, useSearchParams } from "react-router-dom";
import invariant from "tiny-invariant";
import { z } from "zod";

import { Button } from "@/common/components/button/button";
import { Spinner } from "@/common/components/spinner/spinner";
import { useClickAway } from "@/common/hooks/use-click-away";
import { postalCodesQuery } from "@/routes/shared/api/get-postal-codes";
import { PostalCodeField } from "@/routes/storages/components/storage-form/postal-code-field";

import { currencySchema } from "../../currency.params";

type Fields = {
  postalCode: string;
};

const PostalCodeDialog = () => {
  const { params, closeDialog, setPostalCode } = usePostalCodeDialogParams();
  const postalCodes = useQuery(postalCodesQuery());
  const clickAwayRef = useClickAway<HTMLDivElement>(closeDialog);
  const postalCodeObject = postalCodes.data?.find(
    (postalCode) => postalCode.code === params?.postalCode,
  );
  const form = useForm<Fields>({
    defaultValues: {
      postalCode: postalCodeObject?.label ?? "",
    },
  });
  const postalCodeValue = form.watch("postalCode");
  const handleSubmit: SubmitHandler<Fields> = () => {
    const postalCode = postalCodeValue.split(" ").at(0);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    invariant(postalCode, "postalCode is required");
    setPostalCode(postalCode);
  };

  return (
    <>
      <div className="absolute inset-0 z-10 ml-[72px] bg-[rgba(97,97,97,0.60)] backdrop-blur-sm" />
      {postalCodes.status === "pending" ? <Spinner /> : null}
      {postalCodes.status === "error" ? (
        <p>
          <Trans>Nemůžeme načíst seznam PSČ.</Trans>
        </p>
      ) : null}
      {postalCodes.status === "success" ? (
        <div className="fixed inset-0 z-100 sm:z-20 sm:m-auto sm:h-[350px] sm:w-[400px] sm:pl-10">
          <div className="sm:relative sm:h-fit sm:max-w-40 sm:self-start">
            <div className="overflow-hidden" ref={clickAwayRef}>
              <FormProvider {...form}>
                <PostalCodeField.Provider>
                  <form
                    onSubmit={form.handleSubmit(handleSubmit)}
                    className="flex h-[100dvh] shrink-0 flex-col gap-y-6 overflow-y-auto bg-white px-6 pb-8 sm:fixed sm:m-auto sm:h-fit sm:w-fit sm:max-w-[400px] sm:rounded-2xl"
                  >
                    <button
                      className="mt-6 self-start md:hidden"
                      onClick={closeDialog}
                    >
                      <Cross />
                    </button>
                    <h1 className="text-can-forest-teal text-2xl font-bold md:mt-6 md:text-center">
                      <Trans>Pro výpočet cen vyplňte PSČ</Trans>
                    </h1>
                    <p className="md:text-center">
                      <Trans>
                        Pro výpočet ceny vyplňte do pole níže poštovní směrovací
                        číslo umístění skladu (PSČ).
                      </Trans>
                    </p>
                    <PostalCodeField label={<Trans>Vaše PSČ</Trans>} />
                    <Button className="mt-auto" variant="primary" type="submit">
                      <Trans>Zobrazit ceny</Trans>
                    </Button>
                  </form>
                  <PostalCodeField.Suggestions className="top-[300px] right-6 left-6 w-[calc(100%-(--spacing(12)))] sm:top-[250px] sm:w-[352px]" />
                </PostalCodeField.Provider>
              </FormProvider>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const postalCodeSchema = z
  .object({
    postalCode: z.string().refine((value) => !isNaN(parseInt(value, 10))),
    enforceDialog: z
      .string()
      .refine((value) => value === "true" || value === "false")
      .transform((value) => (value === "true" ? true : false))
      .or(z.undefined()),
  })
  .merge(currencySchema)
  .or(
    z
      .object({
        postalCode: z
          .string()
          .refine((value) => !isNaN(parseInt(value, 10)))
          .or(z.undefined()),
        enforceDialog: z
          .string()
          .refine((value) => value === "true" || value === "false")
          .transform((value) => (value === "true" ? true : false)),
      })
      .merge(currencySchema),
  );

const usePostalCodeDialogParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const parsedParams = postalCodeSchema.safeParse(
    Object.fromEntries(searchParams),
  );

  const enforceDialog = () => {
    setSearchParams((cur) => {
      cur.set("enforceDialog", "true");
      return cur;
    });
  };

  const closeDialog = () => {
    setSearchParams(() => {
      // https://github.com/remix-run/react-router/issues/9757 what a nasty buggy behavior this is
      const params = createSearchParams(window.location.search);
      if (params.get("postalCode")) {
        params.delete("enforceDialog");
      }

      return params;
    });
  };

  const setPostalCode = (postalCode: string) => {
    if (postalCode) {
      setSearchParams((cur) => {
        cur.set("postalCode", postalCode);
        cur.delete("enforceDialog");
        return cur;
      });
    }
  };

  return {
    params: parsedParams.success ? parsedParams.data : undefined,
    enforceDialog,
    closeDialog,
    setPostalCode,
  };
};

export { PostalCodeDialog, usePostalCodeDialogParams };
