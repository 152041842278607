import AngleRight from "@icons/angle-right.svg?react";
import Cross from "@icons/cross.svg?react";
import Pencil from "@icons/pencil.svg?react";
import { Trans } from "@lingui/react/macro";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { generatePath, Link, useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { Button } from "@/common/components/button/button";
import { Dialog, useDialogProvider } from "@/common/components/dialog/dialog";
import { Metadata } from "@/common/components/metadata/metadata";
import { PriceList } from "@/common/components/price-list/price-list";
import { PropertiesList } from "@/common/components/properties-list/properties-list";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { Spacer } from "@/common/components/spacer/spacer";
import { useCurrency } from "@/common/hooks/use-currency";
import {
  currencyFormatter,
  numberFormatter,
} from "@/common/services/formatter";
import { getPricesView } from "@/common/utils/trading";

import { FullscreenModalLayout } from "../layout/layout.fullscreen";
import { offerQuery } from "../shared/api/get-offer";
import { cropLastPricesQuery } from "../trades/trades.api";
import { path as tradeDetailPath } from "../trades/trades.detail";
import { useArchiveOffer } from "./api";

const path = ":id/offers/:offerId" as const;

const Offer = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { offerId } = useParams<"offerId">();
  const [, currency] = useCurrency();
  invariant(offerId);
  const offer = useSuspenseQuery(offerQuery({ id: offerId }));
  const prices = useSuspenseQuery(
    cropLastPricesQuery({
      storageId: offer.data.storage.id,
      currency,
      cropId: offer.data.crop.id,
    }),
  );
  const archiveOffer = useArchiveOffer();
  const { closeDialog: closeDeleteDialog, openDialog: openDeleteDialog } =
    useDialogProvider();

  const handleDeleteOffer = () => {
    archiveOffer.mutate(offerId, {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
        closeDeleteDialog();
        await navigate("..");
      },
      onError: closeDeleteDialog,
    });
  };

  const DeleteDialog = (
    <Dialog>
      <Dialog.Title>
        <Trans>Opravdu chcete komoditu smazat?</Trans>
      </Dialog.Title>
      <Dialog.Actions>
        <Dialog.Confirm type="button" onClick={handleDeleteOffer}>
          <Trans>Ano</Trans>
        </Dialog.Confirm>
        <Dialog.Cancel type="button" onClick={closeDeleteDialog}>
          <Trans>Ne</Trans>
        </Dialog.Cancel>
      </Dialog.Actions>
    </Dialog>
  );

  return (
    <FullscreenModalLayout
      headerButton={
        <SearchParamsLink to=".." replace>
          {" "}
          <Cross />
        </SearchParamsLink>
      }
    >
      <>
        <Metadata title={offer.data.crop.name} />
        <FullscreenModalLayout.Title>
          {offer.data.crop.name}
        </FullscreenModalLayout.Title>
        <PropertiesList
          properties={[
            {
              label: <Trans>Adresa</Trans>,
              value: offer.data.storage.address,
            },
            {
              label: <Trans>Farma</Trans>,
              value: offer.data.storage.company.name,
            },
            {
              label: <Trans>Sklad</Trans>,
              value: offer.data.storage.name,
            },
            {
              label: <Trans>Cena za tunu (nyní)</Trans>,
              value: prices.data.at(0)
                ? `${currencyFormatter(currency).format(prices.data.at(0)!.price)}/t`
                : "-",
            },
            {
              label: <Trans>Hodnota (nyní)</Trans>,
              value: prices.data.at(0)
                ? currencyFormatter(currency).format(
                    prices.data.at(0)!.price * offer.data.storage.offer.amount,
                  )
                : "-",
            },
            {
              label: <Trans>Rok sklizně</Trans>,
              value: offer.data.harvestYear,
            },
            {
              label: <Trans>Skladem</Trans>,
              value: `${numberFormatter().format(offer.data.storage.offer.amount)} t`,
            },
            {
              label: <Trans>K dispozici volných</Trans>,
              value: `${numberFormatter().format(offer.data.storage.offer.amount)} t`,
            },
          ]}
        />
        <Spacer className="h-2" />
        <SearchParamsLink
          to="edit"
          className="flex items-center justify-center gap-2 text-can-forest-teal"
        >
          <Pencil />
          <Trans>Upravit množství</Trans>
        </SearchParamsLink>
        <Spacer className="h-2" />
        <h2 className="font-bold text-can-forest-teal">
          <Trans>Aktuální výkupní ceny</Trans>
        </h2>
        <Spacer className="h-4" />
        {getPricesView(
          prices.data.map((price) => ({
            id: price.marketPriceId,
            price: price.price,
            quarter: price.quarter,
          })),
        ).map((prices, i) => (
          <PriceList
            prices={prices.map((price) =>
              price?.type === "data"
                ? {
                    quarter: price.quarter.name,
                    currency,
                    value: price.price,
                    id: price.id,
                  }
                : null,
            )}
            key={i}
          />
        ))}
        <Spacer className="h-4" />
        <Link
          className="flex justify-center gap-1 self-center text-center text-can-forest-teal"
          to={generatePath("/trades/" + tradeDetailPath, {
            offerId,
            storageId: offer.data.storage.id,
          })}
        >
          <Trans>Přejít na prodej komodit</Trans>
          <AngleRight />
        </Link>
        <Spacer className="h-2" />
        <ul className="flex flex-col">
          {offer.data.parameters.map((parameter) => (
            <li
              className="flex flex-wrap justify-between border-b border-b-can-silver-cloud py-2 last-of-type:border-0"
              key={parameter.id}
            >
              <span>{parameter.title}</span>
              <b className="text-right">{parameter.value}</b>
            </li>
          ))}
        </ul>
        <Button.Container>
          {archiveOffer.error ? (
            <p role="alert" className="mt-4">
              <Trans>
                Komoditu se nepodařilo smazat. Zkuste to prosím znovu.
              </Trans>
            </p>
          ) : null}
          <Button.Delete
            disabled={archiveOffer.status === "pending"}
            type="button"
            onClick={() => openDeleteDialog(DeleteDialog)}
          >
            <Trans>Smazat komoditu</Trans>
          </Button.Delete>
        </Button.Container>
      </>
    </FullscreenModalLayout>
  );
};

export { Offer, path };
