import { Trans } from "@lingui/react/macro";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import { resetForm } from "@/api/form-reset";
import { Spinner } from "@/common/components/spinner/spinner";
import { ApprovalStatus } from "@/common/services/user";

import { useUserStateMutation } from "../../pending-users/pending-users.api";

type Fields = {
  status: ApprovalStatus | "";
};

const UserStateAction = ({
  initialStatus,
  userCompanyRelationId,
}: {
  initialStatus: ApprovalStatus;
  userCompanyRelationId: number;
}) => {
  const isStatusSet = initialStatus !== "WAITING";
  const form = useForm<Fields>({
    defaultValues: {
      status: isStatusSet ? initialStatus : "",
    },
  });
  const queryClient = useQueryClient();
  const userStateMutation = useUserStateMutation();

  const handleSubmit = (data: Fields) => {
    if (data.status === "") {
      return;
    }

    userStateMutation.mutate(
      { userCompanyRelationId, state: data.status },
      {
        onError: resetForm(form),
        onSuccess: () => {
          void queryClient.invalidateQueries();
        },
      },
    );
  };

  return (
    <form className="flex items-center gap-x-2">
      <select
        disabled={userStateMutation.status === "pending"}
        className="mt-2 w-[110px] rounded-lg border"
        {...form.register("status", {
          onChange: async () => {
            await form.handleSubmit(handleSubmit)();
          },
        })}
      >
        {!isStatusSet ? (
          <option value="">
            <Trans>Akce</Trans>
          </option>
        ) : null}
        <option value="APPROVED">
          <Trans>Schválit</Trans>
        </option>
        <option value="BLOCKED">
          <Trans>Neschválit</Trans>
        </option>
      </select>
      {userStateMutation.status === "pending" ? (
        <Spinner className="h-4! w-4!" withHint={false} />
      ) : null}
    </form>
  );
};

export { UserStateAction };
