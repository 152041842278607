import { GraphQLClient, RequestMiddleware } from "graphql-request";

import { getEnvironmentVariable } from "@/common/services/env";

import { fetchRetry } from "./fetch-retry";
import { token } from "./token";

const requestMiddleware: RequestMiddleware = async (request) => {
  const controller = new AbortController();
  return {
    ...request,
    signal: controller.signal,
    headers: {
      ...request.headers,
      // eslint-disable-next-line lingui/no-unlocalized-strings
      Authorization: `Bearer ${await token.read({ forced: false })}`,
      "Content-Type": "application/json",
    },
  };
};

const graphqlClient = new GraphQLClient(
  getEnvironmentVariable("DIGITALNI_SKLADY_API_URL"),
  {
    fetch: (input, init) =>
      fetchRetry({
        url: input.toString(),
        // @ts-expect-error err
        method: init?.method,
        // @ts-expect-error err
        data: JSON.parse(init?.body),
        // @ts-expect-error err
        headers: init?.headers,
        signal: init?.signal ?? undefined,
        retries: 1,
      }),
    requestMiddleware,
  },
);

export { graphqlClient };
