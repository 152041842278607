import { queryOptions } from "@tanstack/react-query";
import { DateTime } from "luxon";
import invariant from "tiny-invariant";

import { fifteenMinutes } from "@/common/providers/query-provider";
import { currencyFormatter } from "@/common/services/formatter";
import {
  CommodityRatesToCommodity,
  CurrencyRatesToCurrency1,
  getCommodityCurrent,
  getCommodityRates,
  GetCommodityRatesPeriod,
  getRate,
} from "@/generated/api/prices";

import { getFilters, Range } from "./stock-exchange.utils";

const currencyPairQuery = ({
  currency1,
  currency2,
  range,
}: {
  currency1: CurrencyRatesToCurrency1;
  currency2: CurrencyRatesToCurrency1;
  range: Range;
}) => {
  const options = getQueryOptions(range);
  return queryOptions({
    queryKey: [options, currency1, currency2],
    queryFn: async ({ signal }) => {
      return await getRate(currency1, currency2, options, { signal });
    },
    select: (data) => {
      const last = data.rates.at(-1);
      const current = last
        ? `${currencyFormatter(currency2).format(
            last.rate,
          )}/${currencyFormatter(currency1).format(1)}`
        : "";

      const chartData = getChartData({
        data: {
          ...data,
          rates: data.rates.map(({ rate, date }) => ({ rate, datetime: date })),
        },
        range,
      });

      return {
        ...chartData,
        trend: getTrend(data.rates),
        current,
        title: `${currency2} / ${currency1}`,
      };
    },
  });
};

const commodityOutlookQuery = ({
  commodity,
  currency,
  range,
  dateRange,
}: {
  commodity: Extract<CommodityRatesToCommodity, "WHEAT" | "RAPESEED">;
  currency: CurrencyRatesToCurrency1;
  range: Range;
  dateRange: {
    from?: DateTime;
    to?: DateTime;
  };
}) => {
  const type = range === "today" ? "BID" : "SETTLE";

  return queryOptions({
    queryKey: [commodity, currency, type, dateRange.from, dateRange.to],
    queryFn: async ({ signal }) => {
      return await getCommodityCurrent(
        commodity,
        {
          currency,
          type,
          "date-from": dateRange.from?.toISODate() ?? "",
          "date-to": dateRange.to?.toISODate() ?? "",
        },
        {
          signal,
        },
      );
    },
    select: (data) => {
      return data.map((d) => {
        return {
          ...d,
          trend: d.diff ?? "n/a",
        };
      });
    },
  });
};

type CommodityOutlookQueryResult = ReturnType<
  Exclude<ReturnType<typeof commodityOutlookQuery>["select"], undefined>
>;

const commodityQuery = ({
  commodity,
  currency,
  range,
  period,
}: {
  commodity: CommodityRatesToCommodity;
  currency: CurrencyRatesToCurrency1;
  period: GetCommodityRatesPeriod;
  range: Range;
}) => {
  const options = getQueryOptions(range);
  const type = range === "today" ? "BID" : "SETTLE";

  return queryOptions({
    staleTime: type === "BID" ? fifteenMinutes : Infinity,
    queryKey: [options, commodity, range, currency, period, type],
    queryFn: async ({ signal }) => {
      return await getCommodityRates(
        commodity,
        {
          currency,
          period,
          ...options,
          type,
        },
        { signal },
      );
    },
    select: (data) => {
      const last = data.rates.at(-1);
      const current = last?.rate
        ? currencyFormatter(currency).format(last.rate)
        : "";
      const chartData = getChartData({ data, range });

      return {
        ...chartData,
        current,
        trend: data.diff,
      };
    },
  });
};

const getQueryOptions = (range: Range) => {
  const filter = getFilters().find((filter) => filter.value === range);
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(filter, `Filter not found for ${range}`);

  return {
    "date-from": filter.dateFrom,
    "date-to": filter.dateTo,
  };
};

const getTrend = (rates: { rate: number }[]) => {
  const first = rates.at(0);
  const last = rates.at(-1);
  const trend = first && last ? last.rate - first.rate : 0;
  return trend;
};

const getFormattedDate = (date: string, range: Range) => {
  return DateTime.fromISO(date).toLocaleString(
    range === "today" ? DateTime.TIME_SIMPLE : DateTime.DATE_SHORT,
  );
};

const getChartData = ({
  data,
  range,
}: {
  data: { rates: { datetime: string; rate?: number }[] };
  range: Range;
}) => {
  const result = {
    ...data,
    rates: data.rates.map((rate) => {
      return {
        ...rate,
        datetime: getFormattedDate(rate.datetime, range),
      };
    }),
  };

  return result;
};

export { commodityOutlookQuery, commodityQuery, currencyPairQuery };
export type { CommodityOutlookQueryResult };
