import React from "react";

import { Strategy } from "../nav-bar.flow";

class FullStrategy implements Strategy {
  public wrapWith() {
    return React.Fragment;
  }
}

export { FullStrategy };
