import { useSearchParams } from "react-router-dom";
import invariant from "tiny-invariant";

const useIsSubscriptionPaidByCard = () => {
  const cardQueryParams = [
    "payment_intent",
    "payment_intent_client_secret",
    "redirect_status",
  ];
  const [searchParams] = useSearchParams();

  return cardQueryParams.every((p) => searchParams.get(p));
};

const useIsSubscriptionPaidByBankTransfer = () => {
  const bankTransferQueryParams = ["gateway_id"];
  const [searchParams] = useSearchParams();

  return bankTransferQueryParams.every((p) => searchParams.get(p));
};

const useSubscriptionId = () => {
  const [searchParams] = useSearchParams();
  const isPaidByCard = useIsSubscriptionPaidByCard();
  const isPaidByBankTransfer = useIsSubscriptionPaidByBankTransfer();
  invariant(
    isPaidByBankTransfer || isPaidByCard,
    // eslint-disable-next-line lingui/no-unlocalized-strings
    "Impossible to identify payment type",
  );

  if (isPaidByCard) {
    return searchParams.get("payment_intent");
  }

  if (isPaidByBankTransfer) {
    return searchParams.get("gateway_id");
  }
};

export { useSubscriptionId };
