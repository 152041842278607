import { Trans } from "@lingui/react/macro";
import { useSuspenseQuery } from "@tanstack/react-query";

import { useSidebar } from "@/common/providers/sidebar-provider/sidebar-provider";
import { FullscreenModalLayout } from "@/routes/layout/layout.fullscreen";

import { Button } from "../button/button";
import { PropertiesList } from "../properties-list/properties-list";
import { subscriptionQuery } from "./subscription.api";
import { useSubscriptionScreen } from "./use-subscription-screen";

const SubscriptionDetail = () => {
  const { subscriptionId } = useSubscriptionScreen();

  if (!subscriptionId) {
    return <BasicSubscriptionDetail />;
  }

  return <FullSubscriptionDetail id={subscriptionId} />;
};

const FullSubscriptionDetail = ({ id }: { id: number }) => {
  const sidebar = useSidebar();
  const subscription = useSuspenseQuery(subscriptionQuery({ id }));

  return (
    <>
      <FullscreenModalLayout.Title>
        <Trans>Detail předplatného</Trans>
      </FullscreenModalLayout.Title>
      <PropertiesList
        properties={[
          {
            label: <Trans>Typ předplatného</Trans>,
            value: subscription.data.planType,
          },
          {
            label: <Trans>Období</Trans>,
            value: subscription.data.period,
          },
          {
            label: <Trans>Předplatné od</Trans>,
            value: subscription.data.startedAt,
          },
          {
            label: <Trans>Platnost do</Trans>,
            value: subscription.data.expiresAt,
          },
          {
            label: <Trans>Příští platba proběhne</Trans>,
            value: subscription.data.nextPaymentAt,
          },
        ]}
      />
      <div className="mt-4 flex flex-col gap-y-4">
        {subscription.data.isCancellable ? (
          <Button.Delete
            onClick={() => sidebar.actions.openCancelSubscription()}
          >
            <Trans>Zrušit předplatné</Trans>
          </Button.Delete>
        ) : null}
      </div>
    </>
  );
};

const BasicSubscriptionDetail = () => {
  return (
    <>
      <FullscreenModalLayout.Title>
        <Trans>Detail předplatného</Trans>
      </FullscreenModalLayout.Title>
      <PropertiesList
        properties={[
          {
            label: <Trans>Typ předplatného</Trans>,
            value: "BASIC",
          },
        ]}
      />
    </>
  );
};

export { SubscriptionDetail };
