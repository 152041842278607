import CopySvg from "@icons/copy.svg?react";
import CrossSvg from "@icons/cross.svg?react";
import DocumentSvg from "@icons/document.svg?react";
import DownloadSvg from "@icons/download.svg?react";
import TimesHexagonSvg from "@icons/times-hexagon.svg?react";
import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useNavigate } from "react-router-dom";
import invariant from "tiny-invariant";

import { ClientError } from "@/api/errors";
import { useClipboard } from "@/common/hooks/use-clipboard";
import { useSidebar } from "@/common/providers/sidebar-provider/sidebar-provider";
import { FullscreenModalLayout } from "@/routes/layout/layout.fullscreen";
import { useDownloadInvoiceMutation } from "@/routes/shared/api/get-invoice";

import { Button } from "../button/button";
import { Failed } from "../info/info";
import { PropertiesList } from "../properties-list/properties-list";
import { Spacer } from "../spacer/spacer";
import { useQRCode } from "./use-qr-code";

const PaymentBankTransfer = () => {
  const sidebar = useSidebar();
  invariant(sidebar.state.screen === "payment-bank-transfer");
  const { state } = sidebar;
  const navigate = useNavigate();
  const copyToClipboard = useClipboard();
  const qrCodeRef = useQRCode(state.invoiceId);
  const downloadInvoice = useDownloadInvoiceMutation();

  const handleDownloadInvoice = (id: number) => {
    downloadInvoice.mutate(id);
  };

  const handleRedirect = async () => {
    await navigate(`/subscribed?gateway_id=${state.gatewayId}`);
  };

  if (!state.error) {
    return (
      <FullscreenModalLayout
        headerButton={
          <button onClick={sidebar.actions.executeCloseAction}>
            <CrossSvg />
          </button>
        }
      >
        <FullscreenModalLayout.Title>
          <Trans>Zaplatit převodem z účtu</Trans>
        </FullscreenModalLayout.Title>
        <p className="text-sm">
          <Trans>
            Vaše závazná objednávka byla potvrzena. Na e-mailovou adresu{" "}
            {state.email} jsme zaslali výzvu k úhradě.
          </Trans>
        </p>
        <Spacer className="h-4" />
        <PropertiesList
          properties={[
            {
              label: t`Majitel účtu`,
              // eslint-disable-next-line lingui/no-unlocalized-strings
              value: "CleverFarm, a.s.",
            },
            {
              label: t`Banka`,
              // eslint-disable-next-line lingui/no-unlocalized-strings
              value: "Komerční banka, a.s.",
            },
            {
              label: t`IBAN`,
              value: (
                <button
                  title={t`Kopírovat`}
                  onClick={() => copyToClipboard.mutate(state.iban)}
                  className="flex gap-x-3"
                >
                  <span>{state.iban}</span>
                  <CopySvg />
                </button>
              ),
            },
            {
              label: t`SWIFT`,
              value: (
                <button
                  title={t`Kopírovat`}
                  onClick={() => copyToClipboard.mutate(state.swift)}
                  className="flex gap-x-3"
                >
                  <span>{state.swift}</span>
                  <CopySvg />
                </button>
              ),
            },
            {
              label: t`Bankovní účet`,
              value: (
                <button
                  title={t`Kopírovat`}
                  onClick={() => copyToClipboard.mutate(state.accountNumber)}
                  className="flex gap-x-3"
                >
                  <span>{state.accountNumber}</span>
                  <CopySvg />
                </button>
              ),
            },
            {
              label: t`Variabilní symbol`,
              value: (
                <button
                  title={t`Kopírovat`}
                  onClick={() => copyToClipboard.mutate(state.variableSymbol)}
                  className="flex gap-x-3"
                >
                  <span>{state.variableSymbol}</span>
                  <CopySvg />
                </button>
              ),
            },
            {
              label: t`Cena (vč. DPH)`,
              value: (
                <button
                  title={t`Kopírovat`}
                  onClick={() => copyToClipboard.mutate(state.priceWithVAT)}
                  className="flex gap-x-3"
                >
                  <span>{state.priceWithVAT}</span>
                  <CopySvg />
                </button>
              ),
            },
            {
              label: t`Zpráva pro příjemce (nepovinné)`,
              value: (
                <button
                  title={t`Kopírovat`}
                  onClick={() => copyToClipboard.mutate(state.plan)}
                  className="flex gap-x-3"
                >
                  <span>{state.plan}</span>
                  <CopySvg />
                </button>
              ),
            },
          ]}
        />
        <Spacer className="h-4" />
        <button
          onClick={() => handleDownloadInvoice(state.invoiceId)}
          className="flex items-center gap-2 rounded-2xl bg-can-silver-gray p-3 text-left font-bold text-can-forest-teal"
        >
          <DocumentSvg className="h-4 w-4 shrink-0" />
          <Trans>Výzva k úhradě</Trans>
          <DownloadSvg className="ml-auto shrink-0" />
        </button>
        <img className="mx-auto mt-4 h-[250px] w-[250px]" ref={qrCodeRef} />
        <Button.Container>
          <Button onClick={handleRedirect} variant="primary">
            <Trans>Mám zaplaceno</Trans>
          </Button>
        </Button.Container>
      </FullscreenModalLayout>
    );
  }

  return (
    <FullscreenModalLayout
      headerButton={
        <button onClick={sidebar.actions.executeCloseAction}>
          <CrossSvg />
        </button>
      }
    >
      <FullscreenModalLayout.Title>
        <Trans>Nepodařilo se načíst platební údaje</Trans>
      </FullscreenModalLayout.Title>
      <div className="flex h-[160px] w-[160px] content-center self-center rounded-full bg-can-silver-gray p-11">
        <TimesHexagonSvg className="h-[72px] w-[72px] self-center" />
      </div>

      <Failed
        error={
          state.error instanceof ClientError && state.error.getStatus() === 409
            ? new ClientError(t`Společnost má již nastavené předplatné.`)
            : state.error
        }
      />
    </FullscreenModalLayout>
  );
};

export { PaymentBankTransfer };
