import { useAuth0 } from "@auth0/auth0-react";
import { Trans } from "@lingui/react/macro";
import React from "react";
import invariant from "tiny-invariant";

import { Dialog } from "@/common/components/dialog/dialog";
import { useToggle } from "@/common/hooks/use-toggle";
import { Company, getUser, Grant } from "@/common/services/user";

type Props = React.PropsWithChildren<{
  grant: Grant;
  guard: {
    title: React.ReactNode;
    content: React.ReactNode;
    action: React.ReactNode;
  };
}>;

type CheckFn = (company: Company) => boolean;

const ActionGuardContext = React.createContext<
  { open: () => void; close: () => void; grant: Grant } | undefined
>(undefined);

const useActionGuardProvider = () => {
  const ctx = React.useContext(ActionGuardContext);

  invariant(
    ctx,
    // eslint-disable-next-line lingui/no-unlocalized-strings
    "ActionGuardContext only available within <ActionGuard />",
  );

  return ctx;
};

const useGuard = <T = () => void,>({
  action,
  checkFn,
}: {
  action: T;
  checkFn?: CheckFn;
}): T | (() => void) => {
  const { open: raiseIssue, grant } = useActionGuardProvider();
  const { user: _user } = useAuth0();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(_user, "User should be defined");
  const user = getUser(_user);
  const company = user["can/app_metadata"]?.companies?.at(0);

  if (!company) {
    return raiseIssue;
  }

  const can = checkFn
    ? checkFn(company)
    : company.grants.includes(grant) && company.approvalState === "APPROVED";

  if (can) {
    return action;
  }

  return raiseIssue;
};

const canSignCheckFn: CheckFn = (company) =>
  company.users.some(
    (user) =>
      user.grants.includes("CONTRACT_SIGN") &&
      user.approvalState === "APPROVED",
  );

const ActionGuard = ({ children, grant, guard }: Props) => {
  const dialog = useToggle();

  return (
    <ActionGuardContext.Provider
      value={{ open: dialog.setOn, close: dialog.setOff, grant }}
    >
      {children}
      {dialog.on ? (
        <Dialog>
          <Dialog.Title>{guard.title}</Dialog.Title>
          {Array.isArray(guard.content) ? (
            guard.content.map((c, i) => (
              <Dialog.Content key={i}>{c}</Dialog.Content>
            ))
          ) : (
            <Dialog.Content>{guard.content}</Dialog.Content>
          )}
          <Dialog.Actions>
            <Dialog.Confirm onClick={dialog.setOff}>
              {guard.action}
            </Dialog.Confirm>
          </Dialog.Actions>
        </Dialog>
      ) : null}
    </ActionGuardContext.Provider>
  );
};

const contractSignGuard: Props["guard"] = {
  title: <Trans>Akci nelze dokončit</Trans>,
  content: [
    <Trans key="no-signature-right">
      Ve vaší společnosti nefiguruje uživatel s podpisovým právem.
    </Trans>,
    <Trans key="support">
      Kontaktujte podporu{" "}
      <a href="mailto:help@cleverfarm.ag">help@cleverfarm.ag</a>.
    </Trans>,
  ],
  action: <Trans>Zavřít</Trans>,
};

export { ActionGuard, canSignCheckFn, contractSignGuard, useGuard };
