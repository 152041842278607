import { Trans } from "@lingui/react/macro";
import { queryOptions, useMutation } from "@tanstack/react-query";
import { DateTime } from "luxon";

import { getDateFromISOString } from "@/common/utils/date";
import { getLocalizedPeriods } from "@/common/utils/period";
import {
  cancelSubscription,
  createPaid,
  getCountry,
  getSubscription,
} from "@/generated/api/users";

const subscriptionQuery = ({ id }: { id: number }) =>
  queryOptions({
    queryKey: ["subscription", id],
    queryFn: ({ signal }) => getSubscription(id, { signal }),
    select: ({ isCancellable, ...subscription }) => {
      const period = subscription.period
        ? getLocalizedPeriods()[subscription.period.type]
        : "-";
      const planType = subscription.plan.type;
      const startedAt = getDateFromISOString(
        subscription.createdAt,
      )?.toLocaleString(DateTime.DATETIME_MED);
      const expiresAt = getDateFromISOString(subscription.expiresAt)
        ?.endOf("day")
        .toLocaleString(DateTime.DATETIME_MED);
      const nextPaymentAt =
        subscription.status !== "CANCELLED" ? (
          getDateFromISOString(subscription.nexPaymentAt)?.toLocaleString(
            DateTime.DATE_MED,
          )
        ) : (
          <Trans>Předplatné bylo zrušeno</Trans>
        );

      return {
        period,
        planType,
        startedAt,
        expiresAt,
        nextPaymentAt,
        isCancellable,
      };
    },
  });

const useCancelSubscriptionMutation = () =>
  useMutation({
    mutationFn: ({ id }: { id: number }) => cancelSubscription(id),
  });

const useCreateCardSubscriptionMutation = () =>
  useMutation({
    mutationFn: (
      payload: Omit<Parameters<typeof createPaid>[0], "paymentType">,
    ) =>
      createPaid({
        paymentType: "CARD",
        ...payload,
      }),
  });

const useCreateBankTransferSubscriptionMutation = () =>
  useMutation({
    mutationFn: (
      payload: Omit<Parameters<typeof createPaid>[0], "paymentType">,
    ) =>
      createPaid({
        paymentType: "BANK_TRANSFER",
        ...payload,
      }),
  });

const paymentMethodsQuery = ({ countryId }: { countryId: string }) =>
  queryOptions({
    queryKey: ["country", countryId, "payment-methods"],
    queryFn: ({ signal }) => getCountry(countryId, { signal }),
    select: (data) => data.paymentMethods,
  });

export {
  paymentMethodsQuery,
  subscriptionQuery,
  useCancelSubscriptionMutation,
  useCreateBankTransferSubscriptionMutation,
  useCreateCardSubscriptionMutation,
};
