import { queryOptions, useMutation } from "@tanstack/react-query";
import sortBy from "lodash-es/sortBy";
import { DateTime } from "luxon";
import invariant from "tiny-invariant";
import { z } from "zod";

import { graphqlClient } from "@/api/graphql";
import { entitySchema } from "@/common/schema/entity";
import { sortString } from "@/common/utils/array";
import { hasMatch, isMatchingSearch } from "@/common/utils/search";
import { graphql } from "@/generated/digitalnisklady.cz";
import { Currency } from "@/generated/digitalnisklady.cz/graphql";

// eslint-disable-next-line lingui/no-unlocalized-strings
const ordersDocument = graphql(`
  query OrdersForOffer($id: ID!) {
    offer(id: $id) {
      orders {
        edges {
          node {
            active
            id
            amount
            currency
            quarter {
              id
              name
            }
            price
            lowPrice
            currency
          }
        }
      }
    }
  }
`);

const orderSchema = z.object({
  id: z.string(),
  amount: z.number(),
  currency: z.enum(["CZK", "EUR"]),
  quarter: z.object({
    id: z.string(),
    name: z.string(),
  }),
  price: z.number(),
  lowPrice: z
    .number()
    .nullish()
    .transform((v) => v ?? null),
});

type Order = z.infer<typeof orderSchema>;

const ordersQuery = ({ offerId }: { offerId: string }) =>
  queryOptions({
    queryKey: ["orders", offerId],
    queryFn: () =>
      graphqlClient.request(ordersDocument, {
        id: offerId,
      }),
    select: (data) =>
      z
        .array(orderSchema)
        .parse(
          data.offer?.orders?.edges
            ?.filter((edge) => edge?.node?.price)
            .map((edge) => edge?.node),
        ),
  });

// eslint-disable-next-line lingui/no-unlocalized-strings
const addOrderDocument = graphql(`
  mutation AddOrder($order: OrderInput!) {
    addOrder(order: $order) {
      id
    }
  }
`);

const useAddOrderMutation = () =>
  useMutation({
    mutationKey: ["add-order"],
    mutationFn: (payload: {
      offerId: string;
      quarterId: string;
      amount: number;
      lowPrice: number | null;
      price: number;
      currency: "EUR" | "CZK";
      customTransport: boolean;
      transportNote?: string;
      note?: string;
    }) =>
      graphqlClient.request(addOrderDocument, {
        order: {
          quarter: payload.quarterId,
          amount: payload.amount,
          note: payload.note,
          currency: payload.currency,
          customTransport: payload.customTransport,
          transportNote: payload.transportNote,
          offer: payload.offerId,
          lowPrice: payload.lowPrice,
          price: payload.price,
          active: true,
        },
      }),
  });

// eslint-disable-next-line lingui/no-unlocalized-strings
const addContractDocument = graphql(`
  mutation AddContract($contract: ContractInput!) {
    addContract(contract: $contract) {
      id
    }
  }
`);

const useAddContractMutation = () =>
  useMutation({
    mutationKey: ["add-contract"],
    mutationFn: (payload: {
      offerId: string;
      marketPriceId: string;
      amount: number;
      note: string;
      currency: "EUR" | "CZK";
      customTransport: boolean;
      transportNote?: string;
    }) =>
      graphqlClient.request(addContractDocument, {
        contract: {
          amount: payload.amount,
          marketPrice: payload.marketPriceId,
          offer: payload.offerId,
          transportNote: payload.transportNote,
          customTransport: payload.customTransport,
          currency: payload.currency,
          note: payload.note,
        },
      }),
  });

// eslint-disable-next-line lingui/no-unlocalized-strings
const cropLastPricesDocument = graphql(`
  query CropLastPrices($id: ID!, $currency: Currency!, $cropIds: [ID!]) {
    storage(id: $id) {
      lastPrices(cropIds: $cropIds) {
        edges {
          node {
            id
            crop {
              id
              name
            }
            quarter {
              id
              name
            }
            price(currency: $currency)
          }
        }
      }
    }
  }
`);

const cropLastPricesSchema = z.array(
  z.object({
    marketPriceId: z.string(),
    price: z.number(),
    quarter: entitySchema,
  }),
);

const cropLastPricesQuery = ({
  currency,
  storageId,
  cropId,
}: {
  currency: "CZK" | "EUR";
  storageId: string;
  cropId: string;
}) =>
  queryOptions({
    queryKey: ["last-prices", currency, storageId, cropId],
    queryFn: () =>
      graphqlClient.request(cropLastPricesDocument, {
        currency: currency as Currency,
        id: storageId,
        cropIds: [cropId],
      }),
    select: (data) => {
      const result = cropLastPricesSchema.parse(
        data.storage?.lastPrices?.edges?.map((edge) => ({
          marketPriceId: edge?.node?.id,
          price: edge?.node?.price,
          quarter: edge?.node?.quarter,
        })),
      );
      return sortBy(result, (item) => sortQuarter(item.quarter.name));
    },
  });

// eslint-disable-next-line lingui/no-unlocalized-strings
const futureQuartersDocument = graphql(`
  query FutureQuarters {
    quarters(upcoming: true) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`);

const futureQuartersQuery = () =>
  queryOptions({
    queryKey: ["future-quarters"],
    queryFn: () => graphqlClient.request(futureQuartersDocument),
    select: (data) =>
      z
        .array(entitySchema)
        .parse(data.quarters?.edges?.map((edge) => edge?.node)),
  });

// eslint-disable-next-line lingui/no-unlocalized-strings
const cropAggregatedPricesDocument = graphql(`
  query StorageAggregatedPrices(
    $id: ID!
    $from: DateTime!
    $to: DateTime!
    $cropIds: [ID!]
    $currency: Currency!
  ) {
    storage(id: $id) {
      aggregatedPrices: prices(
        from: $from
        to: $to
        aggregated: true
        cropIds: $cropIds
      ) {
        edges {
          node {
            id
            date
            quarter {
              id
              name
              dateFrom
            }
            region {
              id
              name
            }
            price(currency: $currency)
          }
        }
      }
    }
  }
`);

const cropAggregatedPricesSchema = z.array(
  z.object({
    datetime: z.string(),
    rate: z.number(),
    quarter: z.object({
      name: z.string(),
    }),
  }),
);

const cropAggregatedPricesQuery = ({
  storageId,
  cropId,
  from,
  to,
  currency,
}: {
  storageId: string;
  cropId: string;
  from: DateTime;
  to: DateTime;
  currency: "CZK" | "EUR";
}) =>
  queryOptions({
    queryKey: ["crop", "price-chart", storageId, cropId, from, to, currency],
    queryFn: () =>
      graphqlClient.request(cropAggregatedPricesDocument, {
        id: storageId,
        cropIds: [cropId],
        from: from.toSeconds(),
        to: to.toSeconds(),
        currency: currency as Currency,
      }),
    select: (data) => {
      const result = data.storage?.aggregatedPrices?.edges?.map((edge) => ({
        datetime: DateTime.fromSeconds(edge?.node?.date).toLocaleString(
          DateTime.DATE_SHORT,
        ),
        rate: edge?.node?.price,
        quarter: {
          name: edge?.node?.quarter?.name?.replace(" ", "/"),
        },
      }));
      return cropAggregatedPricesSchema.parse(result);
    },
  });

// eslint-disable-next-line lingui/no-unlocalized-strings
const storagesQueryDocument = graphql(`
  query StoragesForTrading($currency: Currency!, $archived: Boolean) {
    user {
      companies {
        edges {
          node {
            id
            name
            storages(archived: $archived) {
              edges {
                node {
                  archived
                  id
                  label
                  offers {
                    edges {
                      node {
                        yearHarvested
                        id
                        archived
                        amount
                        totalAmount
                        crop {
                          id
                          name
                        }
                      }
                    }
                  }
                  lastPrices {
                    edges {
                      node {
                        price(currency: $currency)
                        crop {
                          id
                          name
                        }
                        quarter {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

const storagesSchema = z.array(
  z.object({
    company: entitySchema.merge(
      z.object({
        storages: z.array(
          entitySchema.merge(
            z.object({
              mark: z.boolean(),
              offers: z.array(
                z.object({
                  harvestYear: z.number(),
                  amount: z.number(),
                  id: z.string(),
                  crop: entitySchema.merge(z.object({ mark: z.boolean() })),
                  prices: z.array(
                    z.object({
                      id: z.string(),
                      value: z.number(),
                      quarter: entitySchema,
                      currency: z.string(),
                      price: z.number(),
                    }),
                  ),
                }),
              ),
            }),
          ),
        ),
      }),
    ),
  }),
);

const storagesQuery = ({
  currency,
  search,
}: {
  currency: "CZK" | "EUR";
  search?: string;
}) =>
  queryOptions({
    queryKey: ["user", currency],
    queryFn: () =>
      graphqlClient.request(storagesQueryDocument, {
        currency: currency as Currency,
        archived: false,
      }),
    select: (data) => {
      const result: unknown[] = [];

      data.user?.companies?.edges?.forEach((apiCompany) => {
        const company = {
          company: {
            id: apiCompany?.node?.id,
            name: apiCompany?.node?.name,
            storages: apiCompany?.node?.storages?.edges
              ?.filter((storage) => !storage?.node?.archived)
              .map((storage) => ({
                mark: hasMatch(storage?.node?.label, search),
                id: storage?.node?.id,
                name: storage?.node?.label,
                offers: storage?.node?.offers?.edges
                  ?.filter((offer) => !offer?.node?.archived)
                  .reduce<Record<string, unknown>[]>((allOffers, offer) => {
                    const currentOffer = offer?.node;
                    let index = allOffers.findIndex(
                      (o) => o.id === currentOffer?.id,
                    );

                    if (index === -1) {
                      index =
                        allOffers.push({
                          id: currentOffer?.id,
                          harvestYear: currentOffer?.yearHarvested,
                          crop: {
                            ...currentOffer?.crop,
                            mark: hasMatch(currentOffer?.crop?.name, search),
                          },
                          amount: currentOffer?.amount,
                          prices: [],
                        }) - 1;
                    }

                    const prices = storage.node?.lastPrices?.edges?.filter(
                      (price) =>
                        price?.node?.crop?.id === offer?.node?.crop?.id,
                    );

                    allOffers[index].prices = prices?.map((price) => ({
                      id: price?.node?.quarter?.id,
                      quarter: price?.node?.quarter,
                      value: price?.node?.price,
                      currency,
                      price: price?.node?.price,
                    }));

                    return allOffers;
                  }, []),
              })),
          },
        };
        result.push(company);
      });

      const companies = storagesSchema.parse(result);

      companies.forEach(({ company }) => {
        company.storages = company.storages.filter((storage) =>
          isMatchingSearch(storage, search),
        );
        company.storages.sort(sortString);
        company.storages.forEach((storage) => {
          storage.offers = sortBy(storage.offers, (o) => [
            o.crop.name,
            o.harvestYear,
          ]).map(({ prices, ...offer }) => {
            return {
              ...offer,
              prices: sortBy(prices, (p) => sortQuarter(p.quarter.name)),
            };
          });
        });
      });

      return companies;
    },
  });

const sortQuarter = (name: string) => {
  const [quarter, year] = name.split(" ");
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(quarter, "quarter extraction failed");
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(year, "year extraction failed");

  return [year, quarter];
};

export {
  cropAggregatedPricesQuery,
  cropLastPricesQuery,
  futureQuartersQuery,
  ordersQuery,
  storagesQuery,
  useAddContractMutation,
  useAddOrderMutation,
};
export type { Order };
