import { i18n as defaultI18n } from "@lingui/core";
import intersection from "lodash-es/intersection";
import invariant from "tiny-invariant";

import { Language, languageIds, languages } from "@/generated/languages";

const fallbackLanguage = languages.find((language) => language.isDefault)?.id;
// eslint-disable-next-line lingui/no-unlocalized-strings
invariant(fallbackLanguage, "Fallback language should be defined");
const invariantFallbackLanguage = fallbackLanguage;
const getLanguage = (language: string) => language.split("-").at(0);

const supportedBrowserLanguages = intersection(
  navigator.languages.map(getLanguage),
  languageIds,
);

const defaultLanguage = (supportedBrowserLanguages.at(0) ??
  invariantFallbackLanguage) as Language;

const getSupportedLanguage = (desiredLanguage: string) =>
  ((languageIds as string[]).includes(desiredLanguage)
    ? desiredLanguage
    : fallbackLanguage) as Language;

const dynamicActivate =
  (i18n: typeof defaultI18n) => async (locale: Language) => {
    const { messages } = await import(`../../locales/${locale}/messages.po`);
    i18n.load(locale, messages);
    i18n.activate(locale);
  };

export {
  defaultLanguage,
  dynamicActivate,
  invariantFallbackLanguage as fallbackLanguage,
  getLanguage,
  getSupportedLanguage,
};
