import angleSmallDownSvg from "@icons/angle-small-down.svg?url";
import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import React from "react";
import { FormProvider, type UseFormReturn } from "react-hook-form";

import { Button } from "@/common/components/button/button";
import { InputField } from "@/common/components/form/input-field/input-field";

import type { Companies } from "../../api";
import { PostalCodeField } from "./postal-code-field";

type Fields = {
  postalCode: string;
  name: string;
  address: string;
  companyId: string;
};

const StorageForm = ({
  form,
  onSubmit,
  storage,
  children,
  buttons,
}: React.PropsWithChildren<{
  form: UseFormReturn<Fields>;
  onSubmit: (params: Fields) => void;
  storage: { companies: Companies };
  buttons: React.ReactNode;
}>) => {
  const transformData = (data: Fields): Fields => {
    return {
      ...data,
      postalCode: data.postalCode.split(" ")[0],
    };
  };

  return (
    <FormProvider {...form}>
      <form
        id="storage-form"
        onSubmit={form.handleSubmit((data) => onSubmit(transformData(data)))}
        className="flex grow flex-col gap-y-4"
      >
        <fieldset className="flex flex-col gap-y-6">
          <InputField<Fields>
            name="name"
            autoComplete="off"
            label={<Trans>Název skladu</Trans>}
            options={{
              required: t`Vyplňte prosím název skladu`,
            }}
          />

          <InputField<Fields>
            name="address"
            autoComplete="off"
            label={<Trans>Adresa skladu</Trans>}
            options={{
              required: t`Vyplňte prosím adresu skladu`,
            }}
          />

          <div className="relative">
            <PostalCodeField.Provider>
              <PostalCodeField label={<Trans>PSČ skladu</Trans>} />
              <PostalCodeField.Suggestions />
            </PostalCodeField.Provider>
          </div>

          <label htmlFor="companyId">
            <Trans>Farma</Trans>
            <select
              style={{ backgroundImage: `url(${angleSmallDownSvg})` }}
              {...form.register("companyId")}
              autoComplete="off"
              required
              className="mt-2 block w-full appearance-none rounded-lg border bg-white bg-[right_16px_center] bg-no-repeat p-4 pr-12 font-bold"
              id="companyId"
            >
              {storage.companies?.map((company) => (
                <option value={company.id} key={company.id}>
                  {company.name}
                </option>
              ))}
            </select>
          </label>
        </fieldset>
        {children}
      </form>
      <Button.Container>{buttons}</Button.Container>
    </FormProvider>
  );
};

export { StorageForm };
export type { Fields };
