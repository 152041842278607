import SubscribedSvg from "@icons/subscribed.svg?react";
import { Trans } from "@lingui/react/macro";
import { queryOptions, useQuery } from "@tanstack/react-query";
import React from "react";
import invariant from "tiny-invariant";

import { Button } from "@/common/components/button/button";
import { Failed, Info } from "@/common/components/info/info";
import { Spinner } from "@/common/components/spinner/spinner";
import { useToggle } from "@/common/hooks/use-toggle";
import { status } from "@/generated/api/users";
import { FullscreenModalLayout } from "@/routes/layout/layout.fullscreen";
import { Failed as FailedScreen } from "@/routes/shared/routes/failed/failed";
import { Success } from "@/routes/shared/routes/success/success";

import { useGoBackToApp } from "../hooks/use-go-back-to-app";
import { useSubscriptionId } from "../hooks/use-subscription-result";

const Subscribed = () => {
  const subscriptionId = useSubscriptionId();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(subscriptionId, "Subscription id is not defined");
  const isRefetching = useToggle(false);
  const status = useQuery({
    ...paymentStatusQuery({ paymentId: subscriptionId }),
    refetchInterval: isRefetching.on ? 1_000 : false,
  });
  const { isUpdatingToken, handleRedirectClick } = useGoBackToApp();
  const animate = useToggle();

  React.useEffect(() => {
    setTimeout(animate.setOn, 500);
  }, [animate]);

  React.useEffect(() => {
    if (!status.data?.active) {
      isRefetching.setOn();
    } else {
      isRefetching.setOff();
    }
  }, [isRefetching, status.data?.active]);

  if (status.status === "pending") {
    return <Spinner />;
  }

  if (status.status === "error") {
    return <Failed error={status.error} />;
  }

  if (status.data.paymentStatus === "ERROR") {
    return (
      <FailedScreen
        title={
          <FailedScreen.Title>
            <Trans>Něco se pokazilo</Trans>
          </FailedScreen.Title>
        }
        content={
          <FailedScreen.Content>
            <Trans>
              Přechod na {status.data.planType} předplatné bohužel selhal.
              <br />
              Zkuste to prosím znovu později.
            </Trans>
          </FailedScreen.Content>
        }
      />
    );
  }

  if (status.data.active) {
    const content =
      status.data.planType === "PLUS" ? (
        <>
          <p>
            <Trans>
              Vaše PLUS předplatné je úspěšně odemčeno. Nyní můžete využívat
              nové funkce aplikace Clever˚Analytics'!
            </Trans>
          </p>
          <p className="mt-4">
            <Trans>Tlačítkem níže přejdete do aplikace s PLUS funkcemi.</Trans>
          </p>
        </>
      ) : (
        <>
          <p>
            <Trans>
              Vaše PREMIUM předplatné je úspěšně odemčeno. Nyní můžete aplikaci
              Clever˚Analytics' využívat naplno!
            </Trans>
          </p>
          <p className="mt-4">
            <Trans>
              Tlačítkem níže přejdete do aplikace s již plným přístupem.
            </Trans>
          </p>
        </>
      );
    return (
      <div className="mx-auto mt-[120px] flex flex-col gap-y-4 text-center">
        <Success.Title>
          <Trans>{status.data.planType} úspěšně odemčeno</Trans>
        </Success.Title>
        <div className="self-center rounded-full bg-can-silver-gray p-11">
          <SubscribedSvg
            className={`transform ${
              animate.on ? "rotate-0 scale-100" : "-rotate-[1080deg] scale-0"
            } transition-all duration-[1500ms] ease-out`}
          />
        </div>
        <Success.Content>
          <Info>{content}</Info>
          <Button
            disabled={isUpdatingToken}
            onClick={handleRedirectClick}
            variant="primary"
            className="mt-8 w-[342px]"
          >
            <Trans>Přejít do aplikace</Trans>
          </Button>
        </Success.Content>
      </div>
    );
  }

  return (
    <FullscreenModalLayout headerButton={<></>}>
      <Info>
        <Trans>Platba se zpracovává.</Trans>
      </Info>
    </FullscreenModalLayout>
  );
};

const paymentStatusQuery = ({ paymentId }: { paymentId: string }) =>
  queryOptions({
    queryKey: ["payment", "status", paymentId],
    queryFn: async ({ signal }) => {
      const paymentStatus = await status(paymentId, { signal });

      return {
        planType: paymentStatus.subscription.planType,
        active: paymentStatus.subscription.isActive,
        paymentStatus: paymentStatus.status,
      };
    },
  });

export { Subscribed };
