import AngleRight from "@icons/angle-right.svg?react";
import AngleSmallDownSvg from "@icons/angle-small-down.svg?react";
import CheckboxCheckedSvg from "@icons/checkbox.checked.svg?react";
import Cross from "@icons/cross.svg?react";
import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import React from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import {
  createSearchParams,
  generatePath,
  Link,
  Navigate,
  Outlet,
  resolvePath,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import invariant from "tiny-invariant";
import { z } from "zod";

import { Button, primary } from "@/common/components/button/button";
import { Failed, Info } from "@/common/components/info/info";
import { Metadata } from "@/common/components/metadata/metadata";
import { OrderItem } from "@/common/components/order-item/order-item";
import { PropertiesList } from "@/common/components/properties-list/properties-list";
import { getInitialDateFilter } from "@/common/components/quick-filters/quick-filters";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { Spacer } from "@/common/components/spacer/spacer";
import { Spinner } from "@/common/components/spinner/spinner";
import { useCurrency } from "@/common/hooks/use-currency";
import {
  currencyFormatter,
  numberFormatter,
} from "@/common/services/formatter";
import { getSlashedQuarterName } from "@/common/utils/trading";

import { FullscreenModalLayout } from "../layout/layout.fullscreen";
import { getAbsolutePath } from "../routes.utils";
import { offerQuery } from "../shared/api/get-offer";
import { storageQuery } from "../shared/api/get-storage";
import { path as transactionsAndOrdersPath } from "../transactions-and-orders/transactions-and-orders";
import { path as orderPath } from "../transactions-and-orders/transactions-and-orders.order";
import { PriceChart } from "./components/price-chart/price-chart";
import { path as tradesPath } from "./trades";
import { cropLastPricesQuery, ordersQuery } from "./trades.api";
import { path as sellPath } from "./trades.detail.sell";

type Fields = {
  marketPriceId: string;
};

const TradeDetail = () => {
  const { storageId, offerId } = useParams();
  const [, currency] = useCurrency();
  invariant(storageId);
  invariant(offerId);
  const storage = useSuspenseQuery(storageQuery(storageId));
  const offer = useSuspenseQuery(
    offerQuery({
      id: offerId,
    }),
  );
  const prices = useSuspenseQuery({
    ...cropLastPricesQuery({
      storageId,
      currency,
      cropId: offer.data.crop.id,
    }),
  });
  const firstPrice = prices.data.at(0)?.marketPriceId;
  const form = useForm<Fields>({
    defaultValues: {
      marketPriceId: firstPrice,
    },
  });
  const currentPriceId = form.watch("marketPriceId");
  const currentPrice = prices.data.find(
    (p) => p.marketPriceId === currentPriceId,
  );

  React.useEffect(() => {
    form.reset({
      marketPriceId: firstPrice,
    });
  }, [form, firstPrice]);

  return (
    <>
      <FullscreenModalLayout
        headerButton={
          <SearchParamsLink
            omit={["date-from", "date-to"]}
            to={getAbsolutePath(tradesPath)}
            replace
          >
            <Cross />
          </SearchParamsLink>
        }
      >
        <>
          <Metadata title={offer.data.crop.name} />
          <FullscreenModalLayout.Title>
            {offer.data.crop.name}
          </FullscreenModalLayout.Title>
          <PropertiesList
            properties={[
              {
                label: <Trans>Farma</Trans>,
                value: storage.data.company.name,
              },
              {
                label: <Trans>Sklad</Trans>,
                value: storage.data.name,
              },
              {
                label: t`Rok sklizně`,
                value: offer.data.harvestYear,
              },
              {
                label: <Trans>Množství skladem</Trans>,
                value: numberFormatter().format(
                  offer.data.storage.offer.amount,
                ),
              },
              {
                label: <Trans>Celková hodnota</Trans>,
                value: prices.data.at(0)?.price
                  ? currencyFormatter(currency).format(
                      prices.data.at(0)!.price *
                        offer.data.storage.offer.amount,
                    )
                  : "-",
              },
            ]}
          />
          <Spacer className="h-6" />
          <Link
            className="text-can-forest-teal flex justify-center gap-1 self-center text-center"
            to={`../../storages/management/${storageId}`}
          >
            <Trans>Přejít do správy skladu</Trans>
            <AngleRight />
          </Link>
          <Spacer className="h-6" />
          {currentPrice ? (
            <>
              <h2 className="text-can-forest-teal font-bold">
                <Trans>Vývoj aktuální ceny</Trans>
              </h2>
              <Spacer className="h-4" />
              <PriceChart
                quarterName={currentPrice.quarter.name}
                storageId={offer.data.storage.id}
                cropId={offer.data.crop.id}
              />
              <Spacer className="h-6" />
              <form>
                <h2 className="text-can-forest-teal font-bold">
                  <Trans>Prodejní ceny</Trans>
                </h2>
                <Spacer className="h-6" />
                <FormProvider {...form}>
                  <PriceList
                    prices={prices.data.map(({ marketPriceId, ...d }) => ({
                      ...d,
                      id: marketPriceId,
                      quarter: d.quarter,
                      price: currencyFormatter(currency).format(d.price),
                    }))}
                  />
                </FormProvider>
              </form>
              <Spacer className="h-6" />
              <OrderList />
              <Spacer className="h-6" />
              <Button.Container>
                <SearchParamsLink to={sellPath} className={primary}>
                  <Trans>Prodat</Trans>
                </SearchParamsLink>
              </Button.Container>
            </>
          ) : (
            <Info>
              <Trans>Ceny pro komoditu nejsou k dispozici</Trans>
            </Info>
          )}
        </>
      </FullscreenModalLayout>
      <Outlet
        context={{
          marketPrice: {
            id: currentPrice?.marketPriceId,
            price: currentPrice?.price,
          },
          crop: offer.data.crop,
          company: storage.data.company,
          storage: { id: storage.data.id, name: storage.data.name },
          offer: {
            amount: offer.data.storage.offer.amount,
          },
        }}
      />
    </>
  );
};

const OrderList = () => {
  const { offerId } = useParams();
  invariant(offerId);
  const orders = useQuery(ordersQuery({ offerId }));

  let Jsx = (
    <div className="mx-auto">
      <Spinner />
    </div>
  );

  if (orders.status === "error") {
    Jsx = <Failed error={orders.error} />;
  }

  if (orders.status === "success") {
    Jsx = (
      <div className="flex flex-col gap-y-6">
        <ul className="isolate flex flex-col gap-y-2">
          {orders.data.map((order) => (
            <OrderItem key={order.id} {...order}>
              <Link
                to={resolvePath(
                  generatePath(orderPath, {
                    offerId,
                    orderId: order.id,
                  }),
                  getAbsolutePath(transactionsAndOrdersPath),
                )}
              ></Link>
            </OrderItem>
          ))}
        </ul>
        <Link
          className="text-can-forest-teal flex items-center justify-center"
          to={getAbsolutePath(transactionsAndOrdersPath)}
        >
          <Trans>Ukázat všechny příkazy</Trans>
          <AngleSmallDownSvg className="[&_path]:fill-can-forest-teal -rotate-90" />
        </Link>
      </div>
    );
  }

  if (orders.status === "success" && orders.data.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-4">
      <h2 className="text-can-forest-teal font-bold">
        <Trans>Aktivní příkazy</Trans>
      </h2>
      {Jsx}
    </div>
  );
};

type PriceListProps = {
  prices: {
    id: string;
    quarter: {
      id: string;
      name: string;
    };
    price: string;
  }[];
};

const PriceList = ({ prices }: PriceListProps) => {
  const form = useFormContext<Fields>();
  return (
    <ul className="flex w-full flex-col">
      {prices.map((price) => (
        <li
          className="border-can-silver-cloud border-t-can-silver-cloud first:border-l-can-silver-cloud first:border-r-can-silver-cloud last:border-l-can-silver-cloud last:border-r-can-silver-cloud has-[input:checked]:bg-can-silver-gray border border-t border-b-0 first:rounded-tl-2xl first:rounded-tr-2xl first:border-r first:border-l last:rounded-br-2xl last:rounded-bl-2xl last:border-r last:border-b last:border-l"
          key={price.quarter.id}
        >
          <label
            htmlFor={price.quarter.id}
            className="flex flex-wrap justify-between p-3 text-xs sm:text-base"
          >
            <span className="relative flex items-center gap-x-2">
              <input
                {...form.register("marketPriceId", {
                  required: t`Vyberte prosím kvartál`,
                })}
                value={price.id}
                id={price.quarter.id}
                className="peer absolute top-0 left-0 h-0 w-0 opacity-0"
                type="radio"
              />
              <span className="bg-can-silver-gray h-5 w-5 shrink-0 rounded-full peer-checked:invisible!" />
              <CheckboxCheckedSvg className="invisible -ml-7 peer-checked:visible!" />
              <span>{getSlashedQuarterName(price.quarter.name)}</span>
            </span>
            <span>
              <Trans>TOP EKOS</Trans>
            </span>
            <b>{price.price}</b>
          </label>
        </li>
      ))}
    </ul>
  );
};

const getParams = () =>
  createSearchParams({
    ...getInitialDateFilter(),
  });
const Params = ({ children }: React.PropsWithChildren) => {
  const searchParams = useTradeDetailParams();
  const { pathname, search } = useLocation();

  if (!searchParams) {
    return (
      <Navigate replace to={`${pathname}${search}&${getParams().toString()}`} />
    );
  }

  return <>{children}</>;
};

const useTradeDetailParams = () => {
  const [searchParams] = useSearchParams();
  const parsedParams = getSearchParamsSchema().safeParse(
    Object.fromEntries(searchParams),
  );

  if (parsedParams.success) {
    return parsedParams.data;
  }
};

const getSearchParamsSchema = () =>
  z.object({
    "date-from": z
      .string()
      .regex(/\d{4}-\d{2}-\d{2}/)
      .nullish()
      .transform((v) => v ?? undefined),
    "date-to": z
      .string()
      .regex(/\d{4}-\d{2}-\d{2}/)
      .refine((date) => DateTime.now() > DateTime.fromISO(date), {
        message: t`Datum ukončení musí nastat před dnešním dnem.`,
      }),
  });

const path = ":storageId/offers/:offerId";

type Params = z.infer<ReturnType<typeof getSearchParamsSchema>>;

export { Params, path, TradeDetail, useTradeDetailParams };
