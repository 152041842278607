import ArrowSmallRight from "@icons/arrow-small-right.svg?react";
import { msg } from "@lingui/core/macro";
import { useLingui } from "@lingui/react";
import clamp from "lodash-es/clamp";
import min from "lodash-es/min";
import React from "react";
import { z } from "zod";

import { WrapMeWith } from "@/common/components/wrap-me-with/wrap-me-with";

const itemsPerPage = {
  ten: 10,
  oneHundred: 100,
};
const maxVisiblePages = 5;
const minPageNumber = z.number().min(1);

/**
 * 1-based pagination
 */
const Pagination = ({
  getter,
  setter,
  pageSize,
  total,
}: {
  pageSize: number;
  total: number;
  getter: () => number;
  setter: (page: number) => void;
}) => {
  const getLastPage = (items: number) => Math.ceil(items / pageSize);

  const getPageRange = (page: number) => {
    const rangeIndex = Math.ceil(page / maxVisiblePages);
    const end = rangeIndex * maxVisiblePages;
    let _start = end;
    for (let index = maxVisiblePages; index > 1; index -= 1) {
      _start -= 1;
    }
    const start = clamp(_start, 1, Infinity);

    return [start, end];
  };

  const { _ } = useLingui();
  const currentPage = minPageNumber.parse(getter());
  const lastPage = getLastPage(total);
  const visiblePages = min([lastPage, maxVisiblePages])!;
  const [start, _end] = getPageRange(currentPage);
  const end = _end > lastPage ? lastPage : _end;
  const hasPreviousPages = currentPage > visiblePages;
  const hasNextPages = end < lastPage;

  const BackArrow = (
    <WrapMeWith
      when={hasPreviousPages}
      wrapper={(children) => (
        <button
          aria-label={_(msg`předchozí stránka`)}
          onClick={() => setter(start - 1)}
        >
          {children}
        </button>
      )}
    >
      <ArrowSmallRight
        className={`rotate-180 ${hasPreviousPages ? "" : "[&>path]:fill-can-silver-cloud"}`}
      />
    </WrapMeWith>
  );

  const NextArrow = (
    <WrapMeWith
      when={hasNextPages}
      wrapper={(children) => (
        <button onClick={() => setter(end + 1)}>{children}</button>
      )}
    >
      <ArrowSmallRight
        className={hasNextPages ? "" : "[&>path]:fill-can-silver-cloud"}
      />
    </WrapMeWith>
  );
  const getHumanPageNumber = (page: number) => page + 1;

  React.useEffect(() => {
    if (currentPage === 1) {
      return;
    }

    if (currentPage > lastPage) {
      setter(1);
    }
  }, [currentPage, lastPage, setter]);

  if (total <= pageSize) {
    return null;
  }

  if (visiblePages === 0) {
    return null;
  }

  return (
    <ul className="flex items-center justify-center gap-2">
      {BackArrow}
      {Array.from({ length: visiblePages })
        .map((_, i) => i + start - 1)
        .filter((i) => i < end)
        .map((i) => (
          <li key={i} aria-current={currentPage === getHumanPageNumber(i)}>
            <button
              onClick={() => setter(getHumanPageNumber(i))}
              className={`border-can-forest-teal text-can-forest-teal rounded-sm border px-2 py-1 text-xs ${currentPage === getHumanPageNumber(i) ? "from-can-tranquil-azure to-can-mystic-aqua border-transparent bg-linear-to-r text-white" : ""}`}
            >
              {getHumanPageNumber(i)}
            </button>
          </li>
        ))}
      {NextArrow}
    </ul>
  );
};

export { itemsPerPage, Pagination };
