import invariant from "tiny-invariant";

import { useTokenStore } from "@/common/providers/token-provider";

class Token {
  constructor(private store: typeof useTokenStore) {}

  public async read({ forced }: { forced: boolean }) {
    const updateFn = this.store.getState().updateFn;
    const getToken = () => this.store.getState().token;
    // eslint-disable-next-line lingui/no-unlocalized-strings
    invariant(updateFn, "Token store has not been initialized");

    if (forced) {
      await updateFn({ cacheMode: "off" });
      return getToken();
    }

    if (getToken()) {
      return getToken();
    }

    await updateFn();

    return getToken();
  }
}

const token = new Token(useTokenStore);

export { Token, token };
