import TrashSvg from "@icons/trash.svg?react";
import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DateTime } from "luxon";
import React from "react";

import { useHasPermission } from "@/common/acl/guard/guard";
import { Spinner } from "@/common/components/spinner/spinner";
import { getLocalizedPeriods } from "@/common/utils/period";
import { deleteSubscription, PlanPeriodToType } from "@/generated/api/users";

import { Table } from "../../components/table/table";
import { companyQuery } from "../company.api";
import { useCompanyId } from "../use-company-id";

const columnHelper = createColumnHelper<{
  id: number;
  plan: {
    type: string;
  };
  period?: {
    type: PlanPeriodToType;
  };
  source: string;
  expiresAt?: DateTime;
  createdAt?: DateTime;
}>();

const Subscriptions = () => {
  const hasComplanyPlanPermission = useHasPermission("users:company:plan");
  const id = useCompanyId();
  const {
    data: { subscriptions },
  } = useSuspenseQuery(companyQuery({ id: parseInt(id) }));
  const deleteManualSubscription = useDeleteManualSubscriptionMutation();
  const queryClient = useQueryClient();

  const handleDeleteManualSubscriptionClick = React.useCallback(
    (id: number) => {
      deleteManualSubscription.mutate(id, {
        onSuccess: async () => {
          await queryClient.invalidateQueries();
        },
      });
    },
    [deleteManualSubscription, queryClient],
  );

  const columns = React.useMemo(
    () => [
      columnHelper.accessor("id", {}),
      columnHelper.accessor("source", {
        header: () => t`Zdroj`,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("plan.type", {
        header: () => t`Typ předplatného`,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("period.type", {
        header: () => t`Frekvence plateb`,
        cell: (info) => {
          const value = info.getValue();
          if (!value) {
            return "-";
          }
          return getLocalizedPeriods()[value];
        },
      }),
      columnHelper.accessor("createdAt", {
        header: () => t`Předplatné od`,
        cell: (info) => info.getValue()?.toLocaleString(DateTime.DATE_SHORT),
      }),
      columnHelper.accessor("expiresAt", {
        header: () => t`Platné do`,
        cell: (info) => info.getValue()?.toLocaleString(DateTime.DATE_SHORT),
      }),
      columnHelper.display({
        id: "state-action",
        header: () => t`Smazat`,
        cell: ({ row }) => {
          if (row.getValue("source") === "MANUAL") {
            return deleteManualSubscription.status === "pending" ? (
              <Spinner className="h-4!" withHint={false} />
            ) : (
              <button
                onClick={() =>
                  handleDeleteManualSubscriptionClick(row.getValue("id"))
                }
              >
                <TrashSvg />
              </button>
            );
          }
          return null;
        },
      }),
    ],
    [deleteManualSubscription.status, handleDeleteManualSubscriptionClick],
  );
  const subscriptionsTable = useReactTable({
    data: subscriptions,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        id: false,
        "state-action": hasComplanyPlanPermission ?? false,
      },
    },
  });

  return (
    <details>
      <summary>
        <h2 className="inline text-lg font-bold">
          <Trans>Seznam předplatných</Trans>
        </h2>
      </summary>
      {subscriptions.length > 0 ? (
        <Table>
          <Table.Head table={subscriptionsTable} />
          <Table.Body table={subscriptionsTable} />
        </Table>
      ) : null}
    </details>
  );
};

const useDeleteManualSubscriptionMutation = () =>
  useMutation({
    mutationFn: (payload: Parameters<typeof deleteSubscription>[0]) =>
      deleteSubscription(payload),
  });

export { Subscriptions };
