import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useSuspenseQuery } from "@tanstack/react-query";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";
import { Link } from "react-router-dom";

import { useHasPermission } from "@/common/acl/guard/guard";
import { UserIdentity } from "@/common/components/user-identity/user-identity";

import { Table } from "../../components/table/table";
import { UserCompanyAction } from "../../components/user-actions/user-company-action";
import { UserRoleAction } from "../../components/user-actions/user-role-action";
import { UserStateAction } from "../../components/user-actions/user-state-action";
import { CompanyUser, companyUsersQuery } from "../company.api";
import { useCompanyId } from "../use-company-id";

const columnHelper = createColumnHelper<CompanyUser>();
const Users = () => {
  const id = useCompanyId();
  const hasCompanyApprovePermission = useHasPermission("users:company:approve");
  const hasUserCompanyDeletePermission = useHasPermission(
    "users:company:delete",
  );
  const hasCompanyRolePermission = useHasPermission("users:company:role");
  const users = useSuspenseQuery(companyUsersQuery({ id: parseInt(id) }));
  const columns = React.useMemo(
    () => [
      columnHelper.accessor("id", {}),
      columnHelper.accessor("userId", {}),
      columnHelper.accessor("role", {}),
      columnHelper.accessor("userEmail", {
        header: () => t`E-mail`,
        cell: (info) => (
          <Link
            className="flex gap-2 text-can-forest-teal"
            to={`../../users/${info.row.getValue("userId")}`}
          >
            <UserIdentity identity={info.row.getValue("userId")} />
            {info.getValue()}
          </Link>
        ),
        meta: {
          label: () => t`E-mail`,
        },
      }),
      columnHelper.accessor("approvalState", {
        header: () => t`Stav`,
        meta: {
          label: () => t`Stav`,
        },
      }),

      columnHelper.display({
        id: "state-action",
        header: () => t`Stav`,
        cell: hasCompanyApprovePermission
          ? ({ row }) => (
              <UserStateAction
                initialStatus={row.getValue("approvalState")}
                userCompanyRelationId={row.getValue("id")}
              />
            )
          : ({ row }) => row.getValue("approvalState"),
      }),
      columnHelper.display({
        id: "role-action",
        header: () => t`Role`,
        cell: hasCompanyRolePermission
          ? ({ row }) => (
              <UserRoleAction
                userCompanyRelationId={row.getValue("id")}
                initialRole={row.getValue("role")}
              />
            )
          : ({ row }) => row.getValue("role"),
      }),
      ...(hasUserCompanyDeletePermission
        ? [
            columnHelper.display({
              id: "user-company-action",
              header: () => t`Odebrat`,
              cell: ({ row }) => (
                <UserCompanyAction userCompanyRelationId={row.getValue("id")} />
              ),
            }),
          ]
        : []),
    ],

    [
      hasCompanyApprovePermission,
      hasCompanyRolePermission,
      hasUserCompanyDeletePermission,
    ],
  );
  const userTable = useReactTable({
    data: users.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        id: false,
        userId: false,
        approvalState: false,
        role: false,
      },
    },
  });
  return (
    <details>
      <summary>
        <h2 className="inline text-lg font-bold">
          <Trans>Uživatelé</Trans> ({users.data.length})
        </h2>
      </summary>
      {users.data.length > 0 ? (
        <Table>
          <Table.Head table={userTable} />
          <Table.Body table={userTable} />
        </Table>
      ) : null}
    </details>
  );
};

export { Users };
