import TrashSvg from "@icons/trash.svg?react";
import { Trans } from "@lingui/react/macro";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Spinner } from "@/common/components/spinner/spinner";
import { toaster } from "@/common/components/toast/toast";
import { deleteUser } from "@/generated/api/users";

const UserCompanyAction = ({
  userCompanyRelationId,
}: {
  userCompanyRelationId: number;
}) => {
  const queryClient = useQueryClient();
  const userCompanyMutation = useUserCompanyMutation();

  const handleSubmit = () => {
    userCompanyMutation.mutate(
      {
        userCompanyRelationId,
      },
      {
        onError: () => {
          toaster.error(
            {
              title: <Trans>Akce se nezdařila</Trans>,
            },
            {
              autoClose: 2000,
            },
          );
        },
        onSuccess: () => {
          void queryClient.invalidateQueries();
        },
      },
    );
  };

  return (
    <button onClick={handleSubmit}>
      {userCompanyMutation.status === "pending" ? (
        <Spinner withHint={false} />
      ) : (
        <TrashSvg />
      )}
    </button>
  );
};

const useUserCompanyMutation = () =>
  useMutation({
    mutationFn: ({
      userCompanyRelationId,
    }: {
      userCompanyRelationId: number;
    }) => deleteUser(userCompanyRelationId),
  });

export { UserCompanyAction };
