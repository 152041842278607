import Cross from "@icons/cross.svg?react";
import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import isFinite from "lodash-es/isFinite";
import { FormProvider, useForm } from "react-hook-form";
import {
  Navigate,
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import invariant from "tiny-invariant";

import {
  canSignCheckFn,
  useGuard,
} from "@/common/acl/action-guard/action-guard";
import { Button, secondary } from "@/common/components/button/button";
import { InputField } from "@/common/components/form/input-field/input-field";
import { Metadata } from "@/common/components/metadata/metadata";
import { PropertiesList } from "@/common/components/properties-list/properties-list";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { Spacer } from "@/common/components/spacer/spacer";
import { useCurrency } from "@/common/hooks/use-currency";
import { currencyFormatter } from "@/common/services/formatter";
import { getHarvestYearFromQuarter } from "@/common/utils/trading";
import { Currency } from "@/generated/digitalnisklady.cz/graphql";

import { FullscreenModalLayout } from "../layout/layout.fullscreen";
import { getAbsolutePath } from "../routes.utils";
import { postalCodesQuery } from "../shared/api/get-postal-codes";
import { usePostalCodeDialogParams } from "../shared/components/postal-code-dialog/postal-code-dialog";
import { path as failedPath } from "../shared/routes/failed/failed";
import { path as successPath } from "../shared/routes/success/success";
import { companiesQuery } from "../storages/api";
import { path as purchasePricesPath } from "./quick-trading";
import { cropPricesQuery, CropPricesSchema } from "./quick-trading.api";
import { useAddFastContractMutation } from "./quick-trading.detail.api";

const path = ":marketPriceId";

type Fields = {
  note: string;
  amount: number;
  company: string;
};

const QuickTradingDetail = () => {
  const form = useForm<Fields>();
  const addFastContract = useAddFastContractMutation();
  const [, currency] = useCurrency();
  const { marketPriceId } = useParams();
  const companies = useSuspenseQuery(companiesQuery());
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(marketPriceId, ":marketPriceId query param is not set");
  const { params } = usePostalCodeDialogParams();
  const postalCodes = useQuery(postalCodesQuery());
  const [searchParams] = useSearchParams();
  const postalCodeObject = postalCodes.data?.find(
    (postalCode) => postalCode.code === params?.postalCode,
  );
  const navigate = useNavigate();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(postalCodeObject, "Postal code is not set");
  const cropPrices = useSuspenseQuery({
    ...cropPricesQuery({
      longitude: postalCodeObject.longitude,
      latitude: postalCodeObject.latitude,
      currency: currency as Currency,
    }),
  });
  const matchingPrice = getMatchingPrice(cropPrices.data, marketPriceId);
  const handleSubmit = (data: Fields) => {
    invariant(matchingPrice);
    addFastContract.mutate(
      {
        harvestYear: getHarvestYearFromQuarter(
          matchingPrice.price.quarter.name,
        ),
        note: data.note,
        currency: currency as Currency,
        gps: {
          latitude: postalCodeObject.latitude,
          longitude: postalCodeObject.longitude,
        },
        companyId: data.company,
        amount: data.amount,
        marketPriceId,
        cropId: matchingPrice.crop.id,
        zip: postalCodeObject.code,
      },
      {
        onError: async () => {
          await navigate("sell/" + failedPath + "?" + searchParams.toString());
        },
        onSuccess: async (data) => {
          await navigate(
            "sell/" +
              `${successPath}/${data.addFastContract?.id}?searchParams.toString()`,
          );
        },
      },
    );
  };
  const guardedSubmit = useGuard({
    action: handleSubmit,
    checkFn: canSignCheckFn,
  });

  if (!matchingPrice) {
    return <Navigate to=".." />;
  }

  const { price, crop } = matchingPrice;
  const amount = form.watch("amount");
  const totalPrice = isFinite(amount)
    ? currencyFormatter(currency).format(price.price * amount)
    : undefined;

  return (
    <>
      <FullscreenModalLayout
        headerButton={
          <SearchParamsLink to={getAbsolutePath(purchasePricesPath)} replace>
            <Cross />
          </SearchParamsLink>
        }
      >
        <Metadata title={t`Prodat komoditu`} />
        <FullscreenModalLayout.Title>
          <Trans>Prodat komoditu</Trans>
        </FullscreenModalLayout.Title>
        <PropertiesList
          properties={[
            {
              label: <Trans>Komodita</Trans>,
              value: crop.name,
            },
            {
              label: <Trans>PSČ</Trans>,
              value: postalCodeObject.label,
            },
            {
              label: <Trans>Kvartál</Trans>,
              value: price.quarter.name,
            },
            {
              label: <Trans>Celková cena</Trans>,
              value: totalPrice ?? "-",
            },
            {
              label: <Trans>Obchodník</Trans>,
              value: "TOP EKOS",
            },
          ]}
        />
        <Spacer className="h-6" />
        <FormProvider {...form}>
          <form id="sell-form" onSubmit={form.handleSubmit(guardedSubmit)}>
            <fieldset disabled={false}>
              <InputField<Fields>
                autoFocus
                autoComplete="off"
                step="0.1"
                type="number"
                id="amount"
                label={<Trans>Množství v tunách</Trans>}
                options={{
                  required: t`Vyplňte prosím množství`,
                  min: {
                    value: 1,
                    message: t`Množství musí být vyšší než 1 tuna`,
                  },
                  valueAsNumber: true,
                }}
                name="amount"
              />

              <Spacer className="h-6" />
              <label
                className={companies.data.length === 1 ? "hidden" : ""}
                htmlFor="company"
              >
                <Trans>Společnost</Trans>
                <select
                  className="mt-2 w-full rounded-lg p-4"
                  {...form.register("company")}
                >
                  {companies.data.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </select>
              </label>

              <Spacer className="h-6" />

              <label htmlFor="note">
                <Trans>Poznámka</Trans>
                <textarea
                  className="mt-2 w-full rounded-lg"
                  {...form.register("note")}
                  id="note"
                />
              </label>
            </fieldset>
          </form>
        </FormProvider>
        <Button.Container>
          <SearchParamsLink to="order" className={secondary}>
            <Trans>Nastavit příkaz k prodeji</Trans>
          </SearchParamsLink>
          <Button
            disabled={addFastContract.status === "pending"}
            form="sell-form"
            type="submit"
            variant="primary"
          >
            <Trans>
              Prodat za{" "}
              {currencyFormatter(currency).format(matchingPrice.price.price)}
            </Trans>
          </Button>
        </Button.Container>
      </FullscreenModalLayout>
      {/* TODO the context is only needed for /order subroute */}
      <Outlet
        context={{
          quarter: matchingPrice.price.quarter,
          amount: form.watch("amount"),
          crop: matchingPrice.crop,
          postalCodeObject: postalCodeObject,
          pricePerTon: matchingPrice.price.price,
        }}
      />
    </>
  );
};

const getMatchingPrice = (prices: CropPricesSchema, marketPriceId: string) => {
  const price = prices.find((p) =>
    p.prices.find((marketPrice) => marketPrice.id === marketPriceId),
  );

  // seems the price can be canceled any time
  if (!price) {
    return;
  }
  const match = price.prices.find(
    (marketPrice) => marketPrice.id === marketPriceId,
  );

  if (!match) {
    return;
  }

  return {
    crop: price.crop,
    price: match,
  };
};

export { path, QuickTradingDetail };
