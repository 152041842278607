import { Settings } from "luxon";

let locale: string | undefined = undefined;

const setLocale = (nextLocale: string) => {
  locale = nextLocale;
  Settings.defaultLocale = nextLocale;
};

const currencyFormatter = (
  currency: string,
  options: Intl.NumberFormatOptions = {},
) =>
  new Intl.NumberFormat(locale, {
    currency,
    style: "currency",
    ...options,
  });

const numberFormatter = (options: Intl.NumberFormatOptions = {}) =>
  new Intl.NumberFormat(locale, options);

const percentageFormatter = new Intl.NumberFormat(locale, {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  style: "percent",
});

const getPrice = (cents: number, precision: number) =>
  cents / Math.pow(10, precision);

export {
  currencyFormatter,
  getPrice,
  numberFormatter,
  percentageFormatter,
  setLocale,
};
