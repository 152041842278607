import { Trans } from "@lingui/react/macro";
import {
  isRouteErrorResponse,
  Link,
  Navigate,
  useRouteError,
} from "react-router-dom";

import { logger } from "@/common/services/logger";

import { Logo } from "../logo/logo";

const ErrorPage = () => {
  const error = useRouteError();

  if (error) {
    let jsx = (
      <Trans>
        Je nám líto, ale nedaří se nám získat data.
        <br />
        Zkuste to prosím za chvíli.
      </Trans>
    );

    if (isRouteErrorResponse(error) && error.status === 404) {
      // FIXME drop this once old tokens are hopefully gone
      if (error.data.includes("/registration/complete")) {
        return <Navigate to="/logout" />;
      }
      jsx = (
        <Trans>
          Vámi hledaná stránka neexistuje.
          <br />
          <Link className="text-can-forest-teal underline" to="/">
            Chcete se vrátit zpět do aplikace?
          </Link>
        </Trans>
      );
    }

    logger.error(error);
    return (
      <Container>
        <Logo />
        <span className="self-start">{jsx}</span>
      </Container>
    );
  }

  if (isRouteErrorResponse(error) && error.status === 400) {
    return <Navigate to="/" />;
  }

  return (
    <Container>
      <Logo />
      <Trans
        render={({ children }) => (
          <span className="self-start">{children}</span>
        )}
      >
        Je nám líto, ale tady vám pšenka nepokvete.
      </Trans>
    </Container>
  );
};

const Container = ({ children }: React.PropsWithChildren) => {
  return (
    <div className="text-can-stormy-sky flex min-h-full items-center justify-center">
      <div className="flex gap-x-8 rounded-sm border p-8 shadow-sm">
        {children}
      </div>
    </div>
  );
};

export { ErrorPage };
