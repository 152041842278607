import { useParams } from "react-router-dom";
import invariant from "tiny-invariant";

const useCompanyId = () => {
  const { id } = useParams();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(id, "company id is missing");
  return id;
};

export { useCompanyId };
