import { Trans } from "@lingui/react/macro";
import { generatePath, Link, resolvePath, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { primary } from "@/common/components/button/button";
import { getAbsolutePath } from "@/routes/routes.utils";

import { path as transactionsAndOrdersPath } from "../../../transactions-and-orders/transactions-and-orders";
import { path as contractPath } from "../../../transactions-and-orders/transactions-and-orders.contract";
import { path as orderPath } from "../../../transactions-and-orders/transactions-and-orders.order";

const ContractLink = () => {
  const { contractId } = useParams();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(contractId, ":contractId param is expected");

  return (
    <Link
      to={resolvePath(
        generatePath(contractPath, { contractId }),
        getAbsolutePath(transactionsAndOrdersPath),
      )}
      replace
      className={primary}
    >
      <Trans>Přejít na detail transakce</Trans>
    </Link>
  );
};

const OrderLink = () => {
  const { offerId, orderId } = useParams();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(offerId, ":offerId param is expected");
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(orderId, ":orderId param is expected");

  return (
    <Link
      to={resolvePath(
        generatePath(orderPath, { offerId, orderId }),
        getAbsolutePath(transactionsAndOrdersPath),
      )}
      replace
      className={primary}
    >
      <Trans>Přejít na detail příkazu</Trans>
    </Link>
  );
};

export { ContractLink, OrderLink };
