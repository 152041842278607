import * as Storages from "./storages";
import * as NewCrop from "./storages.crop.new";
import * as StoragesManagement from "./storages.management";
import * as Offer from "./storages.offer";
import * as OfferEdit from "./storages.offer.edit";
import * as Storage from "./storages.storage";

const routes = {
  path: Storages.path,
  element: (
    <Storages.StoragesParams>
      <Storages.Storages />
    </Storages.StoragesParams>
  ),
  children: [
    {
      path: StoragesManagement.path,
      element: <StoragesManagement.Management />,
      children: [
        {
          path: ":id",
          element: <Storage.ExistingStorage />,
        },
        {
          path: Storage.path,
          element: <Storage.NewStorage />,
        },
        {
          path: `:id/${NewCrop.path}`,
          element: <NewCrop.NewCrop />,
        },
        {
          path: Offer.path,
          element: <Offer.Offer />,
        },
        {
          path: OfferEdit.path,
          element: <OfferEdit.OfferEdit />,
        },
      ],
    },
    {
      path: Offer.path,
      element: <Offer.Offer />,
    },
    {
      path: OfferEdit.path,
      element: <OfferEdit.OfferEdit />,
    },
  ],
};

export { routes };
