import { t } from "@lingui/core/macro";

import { SubscriptionToStatus } from "@/generated/api/users";

const getLocalizedSubscriptionStatus = (): Record<
  SubscriptionToStatus,
  string
> => ({
  ACTIVE: t({ context: "subscription status", id: "Aktivní" }),
  CANCELLED: t({ context: "subscription status", id: "Zrušeno" }),
  EXPIRED: t({ context: "subscription status", id: "Vypršela platnost" }),
  AWAITING_PAYMENT: t({ context: "subscription status", id: "Čeká na platbu" }),
  DELETED: t({ context: "subscription status", id: "Smazáno" }),
  INCOMPLETE: t({ context: "subscription status", id: "Neúplné" }),
  PAST_DUE: t({ context: "subscription status", id: "Po splatnosti" }),
});

export { getLocalizedSubscriptionStatus };
