import { queryOptions, useQuery } from "@tanstack/react-query";
import React from "react";
// @ts-expect-error how does one type virtual module for typescript?
import commitHash from "virtual:commit-hash";
import { z } from "zod";

import { useToggle } from "@/common/hooks/use-toggle";
import { oneMinute } from "@/common/providers/query-provider";

// `-dirty` is appended to the commit hash if there are uncommitted changes.
const cleanCommitHash = commitHash.split("-")[0];

const VersionCheck = () => {
  const isLatestVersion = useToggle();
  const version = useQuery({
    ...versionQuery(),
    queryKey: ["app-version"],
    refetchOnWindowFocus: true,
    refetchInterval: oneMinute,
  });

  React.useEffect(() => {
    if (cleanCommitHash === version.data) {
      isLatestVersion.setOn();
    } else {
      isLatestVersion.setOff();
    }
  }, [isLatestVersion, version.data]);

  if (version.status !== "success") {
    return;
  }

  if (!version.data) {
    return;
  }

  if (cleanCommitHash !== version.data) {
    window.location.reload();
  }

  return null;
};

const versionQuery = () =>
  queryOptions({
    queryKey: ["app-version"],
    queryFn: async ({ signal }): Promise<string | null> => {
      const commitHashSchema = z.object({ commitHash: z.string() });
      if (window.location.hostname === "localhost") {
        return commitHashSchema.parse({ commitHash: cleanCommitHash })
          .commitHash;
      }
      const version = await fetch(`/version.json?${Math.random()}`, {
        signal,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!version.ok) {
        return null;
      }

      const response = await version.json();

      const safeVersion = commitHashSchema.safeParse(response);

      return safeVersion.data?.commitHash ?? null;
    },
  });

export { VersionCheck };
