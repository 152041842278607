import { useMutation, useQueryClient } from "@tanstack/react-query";

import { graphqlClient } from "@/api/graphql";
import { graphql } from "@/generated/digitalnisklady.cz";
import { OfferInput } from "@/generated/digitalnisklady.cz/graphql";

// eslint-disable-next-line lingui/no-unlocalized-strings
const editOfferDocument = graphql(`
  mutation EditOffer($id: ID!, $offer: OfferInput!) {
    editOffer(id: $id, offer: $offer) {
      id
    }
  }
`);

const useEditOffer = () => {
  const client = useQueryClient();
  return useMutation({
    onSuccess: () => {
      void client.invalidateQueries({
        queryKey: ["user"],
      });
    },
    mutationFn: ({ offer, id }: { offer: OfferInput; id: string }) =>
      graphqlClient.request(editOfferDocument, {
        id,
        offer,
      }),
  });
};

export { editOfferDocument, useEditOffer };
