import { Logo } from "@/common/components/logo/logo";
import { Spacer } from "@/common/components/spacer/spacer";
import { TopBar } from "@/common/components/top-bar/top-bar";
import { UserProfile } from "@/common/components/user-profile/user-profile";

const Layout = ({ children }: React.PropsWithChildren) => {
  const Jsx = (
    <>
      <Logo className="absolute top-0 left-6 z-10" />
      <TopBar />
      <UserProfile />
    </>
  );

  return (
    <>
      {Jsx}
      <Spacer className="h-20" />
      <div className="mx-auto flex w-full flex-col">{children}</div>
    </>
  );
};

export { Layout };
