import Cross from "@icons/cross.svg?react";
import { Id, toast, ToastOptions } from "react-toastify";

type MessageProps = {
  title: React.ReactNode;
  text?: React.ReactNode;
};

type _ToasterFn = (
  messageProps: Omit<MessageProps, "severity">,
  toastProps: ToastOptions,
) => Id;

type ToasterFn = _ToasterFn & {
  error: _ToasterFn;
  success: _ToasterFn;
};

// @ts-expect-error `closeToast` is passed by react-toastify
// eslint-disable-next-line react/prop-types
const Msg = ({ title, text, closeToast }: MessageProps) => {
  return (
    <div className="flex w-full rounded-2xl bg-white p-4 font-sans text-sm">
      <div className="flex flex-col">
        <p className="msg-title">{title}</p>
        <p className="msg-description">{text}</p>
      </div>
      <button onClick={closeToast} className="ml-auto">
        <Cross className="w-4- h-4" />
      </button>
    </div>
  );
};

const toaster: ToasterFn = (messageProps, toastProps) =>
  toast(<Msg {...messageProps} />, { ...toastProps });

toaster.success = (messageProps, toastProps): Id =>
  toast.success(<Msg {...messageProps} />, {
    ...toastProps,
  });

toaster.error = (messageProps, toastProps): Id =>
  toast.error(<Msg {...messageProps} />, { ...toastProps });

export { toaster };
