import { useMutation } from "@tanstack/react-query";

import { graphqlClient } from "@/api/graphql";
import { graphql } from "@/generated/digitalnisklady.cz";

// eslint-disable-next-line lingui/no-unlocalized-strings
const archiveOrderDocument = graphql(`
  mutation RemoveOrder($id: ID!) {
    removeOrder(id: $id)
  }
`);

const useRemoveOrderMutation = () =>
  useMutation({
    mutationKey: ["remove-order"],
    mutationFn: ({ orderId }: { orderId: string }) =>
      graphqlClient.request(archiveOrderDocument, {
        id: orderId,
      }),
  });

export { useRemoveOrderMutation };
