import { queryOptions } from "@tanstack/react-query";

import { graphqlClient } from "@/api/graphql";
import { graphql } from "@/generated/digitalnisklady.cz";
import { Currency } from "@/generated/digitalnisklady.cz/graphql";

// eslint-disable-next-line lingui/no-unlocalized-strings
const storagesQueryDocument = graphql(`
  query User($currency: Currency!, $archived: Boolean) {
    user {
      companies {
        edges {
          node {
            id
            name
            storages(archived: $archived) {
              edges {
                node {
                  archived
                  id
                  address
                  label
                  lastPrices {
                    edges {
                      node {
                        crop {
                          id
                          name
                        }
                        date
                        price(currency: $currency)
                      }
                    }
                  }
                  offers {
                    edges {
                      node {
                        yearHarvested
                        orders {
                          edges {
                            node {
                              active
                              amount
                            }
                          }
                        }
                        id
                        amount
                        totalAmount
                        crop {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

const userQuery = ({ currency }: { currency: "CZK" | "EUR" }) =>
  queryOptions({
    queryKey: ["user", currency],
    queryFn: () =>
      graphqlClient.request(storagesQueryDocument, {
        currency: currency as Currency,
        archived: false,
      }),
  });

export { userQuery };
