const toggleItem = <const T extends readonly unknown[]>(
  array: T,
  item: T[number],
) => {
  return (array.includes(item)
    ? array.filter((v) => v !== item)
    : [...array, item]) as unknown as T;
};

const sortString = <T extends { name: string } | string>(
  a: T,
  b: T,
  callback?: (item: T) => string,
) => {
  const compA = typeof a === "string" ? a : callback ? callback(a) : a.name;
  const compB = typeof b === "string" ? b : callback ? callback(b) : b.name;

  if (compA < compB) {
    return -1;
  }

  if (compA > compB) {
    return 1;
  }

  return 0;
};

const getUniqueItems = <T extends unknown[]>(
  arr: T,
  uniqueFn: (item: T[number]) => string,
): T => {
  const result: Record<string, number> = {};

  arr.forEach((item) => {
    const id = uniqueFn(item);

    if (result[id] === undefined) {
      result[id] = 1;
    } else {
      result[id] += 1;
    }
  });

  const unique = Object.entries(result)
    .filter(([, value]) => value === 1)
    .map(([key]) => key);

  // @ts-expect-error err
  return arr.filter((item) => unique.includes(uniqueFn(item)));
};

const sum = (total: number, next: number) => total + next;

export { getUniqueItems, sortString, sum, toggleItem };
