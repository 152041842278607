import { useAuth0 } from "@auth0/auth0-react";
import { useFlag } from "@unleash/proxy-client-react";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { RouterProvider } from "react-router-dom";

import { DialogProvider } from "@/common/components/dialog/dialog";
import { Failed } from "@/common/components/info/info";
import { Spinner } from "@/common/components/spinner/spinner";
import {
  getFirstCompany,
  getFirstIncompleteCompany,
} from "@/common/hooks/use-first-company";
import { useIncompleteUserRefresh } from "@/common/hooks/use-incomplete-user-refresh";
import { FeatureFlagProvider } from "@/common/providers/feature-flag-provider";
import {
  FeatureProvider,
  useFeatureProvider,
} from "@/common/providers/feature-provider";
import { I18nProvider } from "@/common/providers/i18n-provider";
import { ProductFruitsProvider } from "@/common/providers/product-fruits-provider";
import { ToastProvider } from "@/common/providers/toast-provider";
import { ToggleProvider } from "@/common/providers/toggle-provider";
import { useTokenStore } from "@/common/providers/token-provider";
import { getFlags } from "@/common/services/feature-flag";
import { setLocale } from "@/common/services/formatter";
import { defaultLanguage, fallbackLanguage } from "@/common/services/i18n";
import { logger } from "@/common/services/logger";
import {
  getMaybeUser,
  getUser,
  getUserState,
  isBlocked,
  PlanSchema,
} from "@/common/services/user";
import { Nullable } from "@/types";

import {
  createAnonymousRouter,
  createBlockedUserRouter,
  createIncompleteUserRouter,
  createRouter,
} from "./routes";
import { createOldRouter } from "./routes.old";

const Setup = ({ children }: React.PropsWithChildren) => {
  const { getAccessTokenSilently } = useAuth0();
  const tokenUpdateFn = useTokenStore((state) => state.updateFn);
  const setUpdateTokenFn = useTokenStore((state) => state.setUpdateFn);

  React.useEffect(() => {
    if (tokenUpdateFn) {
      return;
    }
    setUpdateTokenFn(getAccessTokenSilently);
  }, [getAccessTokenSilently, setUpdateTokenFn, tokenUpdateFn]);

  if (tokenUpdateFn) {
    return children;
  }

  return (
    <I18nProvider language={defaultLanguage}>
      <div className="flex h-full w-full items-center justify-center">
        <Spinner />
      </div>
    </I18nProvider>
  );
};

const App = () => {
  const {
    refreshable,
    error,
    user: _user,
  } = useIncompleteUserRefresh(useAuth0());

  React.useEffect(() => {
    if (_user) {
      logger.setUser({
        id: _user.email,
        email: _user.email,
        username: _user.name,
      });
      logger.setContext(_user);
    } else {
      logger.setUser(null);
    }
  }, [_user]);

  if (!_user || !refreshable || error) {
    return (
      <I18nProvider language={defaultLanguage}>
        <DialogProvider>
          <RouterProvider router={createAnonymousRouter()} />
        </DialogProvider>
      </I18nProvider>
    );
  }

  const user = getMaybeUser(_user);
  const language =
    user.user_metadata?.can?.settings?.language ?? defaultLanguage;

  setLocale(language);

  if (isBlocked(user)) {
    return (
      <I18nProvider language={language}>
        <DialogProvider>
          <RouterProvider router={createBlockedUserRouter()} />
        </DialogProvider>
      </I18nProvider>
    );
  }

  if (getUserState(_user) === "complete") {
    const user = getUser(_user);
    const flags = getFlags(user);
    const company = getFirstCompany(user);
    const language =
      user.user_metadata.can.settings?.language ?? fallbackLanguage;

    return (
      <ToggleProvider userId={company?.companyNumber ?? "-1"}>
        <__DropMe>
          {(packagesEnabled) =>
            packagesEnabled ? (
              <I18nProvider language={language}>
                <ProductFruitsProvider
                  language={language}
                  user={{
                    email: user.email,
                    username: user.name,
                    language,
                    companyCountryCode: company?.countryCode ?? "unknown",
                    phoneCountryCode:
                      user.user_metadata.common.phone.countryCode,
                  }}
                />
                <ErrorBoundary
                  fallbackRender={({ error }) => <Failed error={error} />}
                >
                  <FeatureProvider company={company}>
                    <DialogProvider>
                      <CompanyRouter planId={company?.plan.planId ?? null} />
                    </DialogProvider>
                  </FeatureProvider>
                </ErrorBoundary>
                <ToastProvider />
              </I18nProvider>
            ) : (
              <I18nProvider language={language}>
                <ProductFruitsProvider
                  language={language}
                  user={{
                    email: user.email,
                    username: user.name,
                    language,
                    companyCountryCode: company?.countryCode ?? "unknown",
                    phoneCountryCode:
                      user.user_metadata.common.phone.countryCode,
                  }}
                />
                <FeatureFlagProvider flags={flags}>
                  <ErrorBoundary
                    fallbackRender={({ error }) => <Failed error={error} />}
                  >
                    <FeatureProvider company={company}>
                      <DialogProvider>
                        <RouterProvider router={createOldRouter(flags)} />
                      </DialogProvider>
                    </FeatureProvider>
                  </ErrorBoundary>
                </FeatureFlagProvider>
                <ToastProvider />
              </I18nProvider>
            )
          }
        </__DropMe>
      </ToggleProvider>
    );
  }

  if (getUserState(_user) === "incomplete") {
    const user = getMaybeUser(_user);
    const company = getFirstIncompleteCompany(user);
    const language =
      user.user_metadata?.can?.settings?.language ?? fallbackLanguage;
    return (
      <ToggleProvider userId={company?.companyNumber ?? "-1"}>
        <I18nProvider language={language}>
          <DialogProvider>
            <RouterProvider router={createIncompleteUserRouter()} />
          </DialogProvider>
          <ToastProvider />
        </I18nProvider>
      </ToggleProvider>
    );
  }
};

const CompanyRouter = ({
  planId,
}: {
  planId: Nullable<PlanSchema["planId"]>;
}) => {
  const features = useFeatureProvider();

  return <RouterProvider router={createRouter(planId, features)} />;
};

const __DropMe = ({
  children,
}: {
  children: (packagesEnabled: boolean) => React.ReactNode;
}) => {
  const hasPackages = useFlag("packages");
  return children(hasPackages);
};

export { App, Setup };
