import { useDownloadInvoiceMutation } from "@/routes/shared/api/get-invoice";

const Invoice = ({ children, id }: React.PropsWithChildren<{ id: number }>) => {
  const downloadInvoice = useDownloadInvoiceMutation();
  const handleDownloadInvoice = () => {
    downloadInvoice.mutate(id);
  };
  return (
    <button className="text-can-forest-teal" onClick={handleDownloadInvoice}>
      {children}
    </button>
  );
};

export { Invoice };
