const permissions = [
  "country:*",
  "news:report:manage",
  "users:*:list",
  "users:company:approve",
  "users:company:delete",
  "users:company:list",
  "users:company:plan",
  "users:company:role",
  "users:payment:confirm",
  "users:payment:list",
  "users:subscription:list",
  "users:user:block",
  "users:user:delete",
  "users:user:reset",
] as const;

// ugly hack to support dynamic country: permissions
// TODO reasonable to get these from elsewhere?
type Permission =
  | Exclude<(typeof permissions)[number], "country:*">
  | `country:${string}`;

export { permissions };
export type { Permission };
