import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useToggle } from "@/common/hooks/use-toggle";
import { useTokenUpdate } from "@/common/providers/token-provider";

const useGoBackToApp = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isUpdatingToken, updateToken] = useTokenUpdate();
  const readyToNavigate = useToggle();

  const handleRedirectClick = async () => {
    await updateToken();
    await queryClient.invalidateQueries({
      queryKey: ["features"],
    });
    readyToNavigate.setOn();
  };

  React.useEffect(() => {
    if (isUpdatingToken || !readyToNavigate.on) {
      return;
    }
    void navigate("/");
  }, [navigate, isUpdatingToken, readyToNavigate.on]);

  return { isUpdatingToken, handleRedirectClick };
};

export { useGoBackToApp };
