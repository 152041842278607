import { CompanyFeatureToPlanType } from "@/generated/api/users";

import { UserSchema } from "./user";

type Flag = "premium" | "trading";
type Flags = Map<Flag, boolean>;

/**
 * Premium gets enabled when the user's company pays a monthly fee.
 * TODO use user profile to derive the data.
 */
const isPremiumEnabled = (user: UserSchema) => {
  return (
    user["can/app_metadata"]?.companies?.some(
      (company) => company.plan.type === CompanyFeatureToPlanType.PREMIUM,
    ) ?? false
  );
};

/**
 * We only want trading to be available for a user with at least one czech company.
 */
const isTradingEnabled = (user: UserSchema) => {
  return (
    (user.user_metadata.can.companies ?? []).findIndex(
      (company) => company.countryCode === "CZ",
    ) > -1
  );
};

const getFlags = (user: UserSchema): Flags => {
  const flags: Flags = new Map();
  flags.set("premium", isPremiumEnabled(user));
  flags.set("trading", isTradingEnabled(user));

  return flags;
};

export { getFlags };
export type { Flag, Flags };
