import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import invariant from "tiny-invariant";

import { getMaybeUser, getUserState } from "@/common/services/user";

import { useTokenUpdate } from "../providers/token-provider";
import { useToggle } from "./use-toggle";

/**
 * Whenever the access token structure is changed, we might want to get a fresh token before
 * actually telling the user is incomplete.
 */
const useIncompleteUserRefresh = (params: ReturnType<typeof useAuth0>) => {
  const [, updateToken] = useTokenUpdate();
  const [retries, setRetries] = React.useState(1);
  const refreshable = useToggle(true);
  const [error, setError] = React.useState<Error>();

  React.useEffect(() => {
    if (!params.user) {
      return;
    }

    if (!refreshable.on) {
      return;
    }

    if (retries === 0) {
      return;
    }

    try {
      getMaybeUser(params.user);
      const incomplete = getUserState(params.user) === "incomplete";
      // eslint-disable-next-line lingui/no-unlocalized-strings
      invariant(!incomplete, "Force token refresh when user is incomplete");
    } catch {
      if (retries === 0) {
        refreshable.setOff();
      }
      updateToken().catch((e) => {
        if (retries === 0) {
          setError(e);
        }
      });
    }
    setRetries((c) => {
      return c > 0 ? c - 1 : 0;
    });
  }, [params.user, refreshable, retries, updateToken]);

  return {
    ...params,
    refreshable: refreshable.on,
    error,
  };
};

export { useIncompleteUserRefresh };
