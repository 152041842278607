import { queryOptions } from "@tanstack/react-query";
import sortBy from "lodash-es/sortBy";
import { z } from "zod";

import { hasMatch, isMatchingSearch } from "@/common/utils/search";

import { userQuery } from "./get-user";

type Storage = z.infer<typeof storagesSchema>;
const storagesSchema = z.object({
  mark: z.boolean().default(false),
  id: z.string(),
  name: z.string(),
  address: z.string(),
  offers: z.array(
    z.object({
      id: z.string(),
      harvestYear: z.number(),
      amount: z.number(),
      orderAmount: z.number(),
      totalAmount: z.number(),
      crop: z.object({
        id: z.string(),
        name: z.string(),
        mark: z.boolean().default(false),
      }),
      lastPrice: z.number().or(z.undefined()),
    }),
  ),
});

const storagesQuery = ({
  currency,
  search,
}: {
  currency: "CZK" | "EUR";
  search?: string;
}) =>
  queryOptions({
    ...userQuery({ currency }),
    queryKey: ["storages", currency],
    select: (data) => {
      const result = new Map<{ id: string; name: string }, Storage[]>();

      data.user?.companies?.edges?.forEach((_company) => {
        const company = _company?.node;

        if (!(company && company.name && company.id)) {
          return;
        }

        const key = { id: company.id, name: company.name };
        result.set(key, []);

        if (company.storages) {
          company.storages.edges = sortBy(
            company.storages?.edges,
            (e) => e?.node?.label,
          );
        }

        company.storages?.edges
          ?.filter((_storage) => !_storage?.node?.archived)
          .forEach((_storage) => {
            const storage = {
              mark: hasMatch(_storage?.node?.label, search),
              id: _storage?.node?.id,
              name: _storage?.node?.label,
              address: _storage?.node?.address,
              offers: _storage?.node?.offers?.edges?.map((node) => ({
                id: node?.node?.id,
                harvestYear: node?.node?.yearHarvested,
                crop: {
                  ...node?.node?.crop,
                  mark: hasMatch(node?.node?.crop?.name, search),
                },
                amount: node?.node?.amount,
                orderAmount:
                  node?.node?.orders?.edges
                    ?.filter((order) => order?.node?.active)
                    .reduce(
                      (acc, order) => acc + (order?.node?.amount ?? 0),
                      0,
                    ) ?? 0,
                totalAmount: node?.node?.totalAmount,
                lastPrice: _storage.node?.lastPrices?.edges?.find(
                  (price) => price?.node?.crop?.id === node?.node?.crop?.id,
                )?.node?.price,
              })),
            };

            const safeStorage = storagesSchema.parse(storage);

            safeStorage.offers = sortBy(safeStorage.offers, (a) => [
              a.crop.name,
              a.harvestYear,
            ]);
            if (isMatchingSearch(safeStorage, search)) {
              result.set(key, [...(result.get(key) ?? []), safeStorage]);
            }
          });
      });

      return result.size > 0 ? result : undefined;
    },
  });

export { storagesQuery };
export type { Storage };
