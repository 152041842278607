import { createBrowserRouter, Navigate } from "react-router-dom";

import { ErrorPage } from "@/common/components/error-page/error-page";
import { Flags } from "@/common/services/feature-flag";

import { Anonymous } from "./anonymous/anonymous";
import { BlockedUser } from "./blocked-user/blocked-user";
import { Callback, path as callbackPath } from "./callback/callback";
import * as IncompleteUser from "./incomplete-user/incomplete-user";
import { Layout } from "./layout/layout";
import { Logout } from "./logout/logout";
import * as PurchasePrices from "./purchase-prices/purchase-prices";
import { NavigateToInitialPage, Root } from "./root/root";
import { routes as adminRoutes } from "./routes.admin";
import { routes as basicRoutes } from "./routes.basic.old";
import { routes as tradingRoutes } from "./routes.trading.old";
import { LockedScreen } from "./shared/components/locked-screen/locked-screen";
import { path as stockExchangePath } from "./stock-exchange/stock-exchange";
import { Layout as SubscriptionLayout } from "./subscriptions/layout";
import { Subscribed } from "./subscriptions/subscribed/subscribed";
import { Unsubscribed } from "./subscriptions/unsubscribed/unsubscribed";

const createIncompleteUserRouter = () =>
  createBrowserRouter([
    {
      path: IncompleteUser.path,
      element: <IncompleteUser.IncompleteUser />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
    {
      path: "*",
      element: <Navigate to={IncompleteUser.path} />,
    },
  ]);

const createBlockedUserRouter = () =>
  createBrowserRouter([
    {
      path: "*",
      element: <BlockedUser />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
  ]);

const createAnonymousRouter = () =>
  createBrowserRouter([
    {
      path: "*",
      element: <Anonymous />,
    },
    {
      path: callbackPath,
      element: <Callback />,
    },
  ]);

const getRoutes = (flags: Flags) => [
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "registration/complete",
    element: <Navigate to="/" />,
  },
  {
    path: "/subscribed",
    element: (
      <Root>
        <SubscriptionLayout>
          <Subscribed />
        </SubscriptionLayout>
      </Root>
    ),
  },
  {
    path: "/unsubscribed",
    element: (
      <Root>
        <SubscriptionLayout>
          <Unsubscribed />
        </SubscriptionLayout>
      </Root>
    ),
  },
  {
    path: "/",
    element: (
      <Root>
        <NavigateToInitialPage
          page={flags.get("trading") ? PurchasePrices.path : stockExchangePath}
        />
      </Root>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        element: <Callback />,
        path: callbackPath,
      },
      {
        element: <Layout />,
        children: [
          {
            element: <LockedScreen />,
            path: "pricing",
          },
          ...(flags.get("trading") ? tradingRoutes : []),
          ...basicRoutes(),
        ],
      },
    ],
  },
  ...adminRoutes(),
];

const createRouter = (flags: Flags) => createBrowserRouter(getRoutes(flags));

export {
  createAnonymousRouter,
  createBlockedUserRouter,
  createIncompleteUserRouter,
  createRouter as createOldRouter,
  getRoutes,
};
